import { createReducer } from "@reduxjs/toolkit";
const initialState = {};

export const schoolReducer = createReducer(initialState, {
    GetSchoolRequest: (state) => {
        state.loading = true;
    },
    GetSchoolSuccess: (state, action) => {
        state.loading = false;
        state.schoolDetails = action.payload;
        state.isAuthenticated = true;
    },
    GetSchoolFailure: (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.isAuthenticated = false;
    },



    clearErrors: (state) => {
        state.error = null;
    },
});