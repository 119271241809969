import React from "react";
import ReactDOM from "react-dom/client";
import axios from "axios";
import App from "./App";
import { store } from "./store";
import { Provider } from "react-redux";

const setupAxios = () => {
  axios.defaults.baseURL = "https://api.reinventuniforms.com/";
  // axios.defaults.baseURL = "https://api.reinventuniforms.in/";
  // axios.defaults.baseURL = "http://localhost:5000/";
  axios.defaults.headers = {
    "Cache-Control": "no-cache,no-store",
    Pragma: "no-cache",
    Expires: "0",
  };
};

setupAxios();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
