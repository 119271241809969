import React, { useEffect, useState } from 'react'
import IdleTimer from 'react-idle-timer'
import { useIdleTimer, workerTimers } from 'react-idle-timer'
import swal from 'sweetalert'


const IdleTimerCont = () => {



    // Set timeout values
    const timeout = 1000 * 60 * 10
    const promptTimeout = 1000 * 30

    // Modal open state
    const [open, setOpen] = useState(false)

    // Time before idle
    const [remaining, setRemaining] = useState(0)

    const onPrompt = () => {
        // onPrompt will be called after the timeout value is reached
        // In this case 30 minutes. Here you can open your prompt. 
        // All events are disabled while the prompt is active. 
        // If the user wishes to stay active, call the `reset()` method.
        // You can get the remaining prompt time with the `getRemainingTime()` method,
        setOpen(true)
        setRemaining(promptTimeout)
    }

    const onIdle = () => {
        // onIdle will be called after the promptTimeout is reached.
        // In this case 30 seconds. Here you can close your prompt and 
        // perform what ever idle action you want such as log out your user.
        // Events will be rebound as long as `stopOnMount` is not set.
        setOpen(false)
        setRemaining(0)
        if (localStorage.getItem('reinventToken')) {
            localStorage.removeItem("reinventToken");
            const Surl = localStorage.getItem('SchoolUrl')

            if (Surl) return window.location.replace(`/${Surl}/login`)
            window.location.replace(`/:url/login`)
        }

    }

    const onActive = () => {
        // onActive will only be called if `reset()` is called while `isPrompted()` 
        // is true. Here you will also want to close your modal and perform
        // any active actions. 
        setOpen(false)
        setRemaining(0)
    }

    const { getRemainingTime, isPrompted, activate } = useIdleTimer({
        timeout,
        promptTimeout,
        onPrompt,
        onIdle,
        onActive
    })

    const handleStillHere = () => {
        if (localStorage.getItem("reinventToken")) {
            setOpen(false)
            activate()
        }

    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (isPrompted()) {
                setRemaining(Math.ceil(getRemainingTime() / 1000))
            }
        }, 1000)
        return () => {
            clearInterval(interval)
        }
    }, [getRemainingTime, isPrompted])



    return (<>
        {
            localStorage.getItem("reinventToken") && <div className='modal' style={{ display: open ? 'block' : 'none' }}>


                <div class="d-flex align-items-center justify-content-center vh-100">
                    <div class="text-center bg-info " >
                        {/* style={{ background: '#F2E9E4' }} */}
                        <div className="p-3"><p class="fs-3 mt-3"> <span class="text-danger p-2">Opps!</span> Logging you out in {remaining} seconds</p>

                            <button class="btn btn-dark mb-3" onClick={() => handleStillHere()}>Im Still Here</button></div>

                    </div>
                </div>

            </div>
        }</>

    )
}

export default IdleTimerCont