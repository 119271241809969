import React, { useState, useEffect } from "react";
import axios from "axios";
import { useRef } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";

const OrderReport = () => {
  const { url } = useParams();
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState();
  const [data, setData] = useState([]);

  const usernameRef = useRef();
  const passwordRef = useRef();

  const fromDateRef = useRef();
  const toDateRef = useRef();
  const enrollmentRef = useRef();

  const [currentPage, setCurrentPage] = useState(1);
  const itemPerPage = 100;
  const [totalData, setTotalData] = useState(0);

  const handleLogin = () => {
    setLoading(true);
    axios
      .post("/api/school-report", {
        username: usernameRef.current?.value,
        password: passwordRef.current?.value,
        url,
      })
      .then((res) => setToken(res?.data?.token))
      .catch((err) =>
        toast.error(err?.response?.data?.message || "Something went wrong!")
      )
      .finally(() => setLoading(false));
  };

  function getData() {
    if (!token) return;
    setLoading(true);
    axios
      .get("/api/school-report", {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          page: currentPage,
          show: itemPerPage,
          from_date: fromDateRef.current?.value,
          to_date: toDateRef.current?.value,
          enrollment_no: enrollmentRef.current?.value,
        },
      })
      .then((res) => {
        setData([...res.data.data]);
        setTotalData(res.data.total_data);
      })
      .catch(() => setData([]))
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    (() => getData())();
  }, [currentPage, itemPerPage]);

  return (
    <div className='container' style={{ minHeight: "80vh" }}>
      {token ? (
        <>
          <div>
            <div className='d-flex justify-content-between'>
              <div style={{ fontSize: "22px" }} className='fw-bold'>
                Orders Report
              </div>
            </div>
          </div>
          <div className='card'>
            <div className='card-body'>
              <div className='row'>
                <div className='col-lg-3'>
                  <label>From Date</label>
                  <input
                    type='date'
                    className='form-control form-control-sm'
                    ref={fromDateRef}
                  />
                </div>
                <div className='col-lg-3'>
                  <label>To Date</label>
                  <input
                    type='date'
                    className='form-control form-control-sm'
                    ref={toDateRef}
                  />
                </div>
                <div className='col-lg-3'>
                  <label>Enrollment No.</label>
                  <input
                    type='text'
                    className='form-control form-control-sm'
                    ref={enrollmentRef}
                  />
                </div>
              </div>
              <div>
                <button
                  className='btn btn-primary btn-sm mt-2 me-2'
                  onClick={() => {
                    getData();
                    setCurrentPage(1);
                  }}
                  disabled={loading}>
                  Filter
                </button>
                <div
                  style={{
                    overflowX: "auto",
                    overflowY: "auto",
                    height: "425px",
                  }}
                  className='mt-3'>
                  <table
                    id='table-to-xls'
                    className='table table-bordered'
                    style={{ minWidth: "70vw" }}>
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>Order ID</th>
                        <th>Order Date</th>
                        <th>Billing Contact No.</th>
                        <th>Enrollment No.</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr>
                          <td className='fw-bold' colSpan='20'>
                            Loading...
                          </td>
                        </tr>
                      ) : data?.length === 0 ? (
                        <tr>
                          <td className='fw-bold text-center' colSpan='20'>
                            No Data Available
                          </td>
                        </tr>
                      ) : null}
                      {data?.map((order, i) => (
                        <tr key={i + 1}>
                          <td>{i + 1}</td>
                          <td>
                            {order?.order_id
                              ? " " + order?.order_id?.toString()
                              : "-"}
                          </td>
                          <td>
                            {order?.createdAt
                              ? new Date(order?.createdAt).toLocaleString(
                                  "en-IN",
                                  {
                                    month: "2-digit",
                                    day: "2-digit",
                                    year: "2-digit",
                                  }
                                )
                              : "-"}
                          </td>
                          <td>{order?.mobile_number || "-"}</td>
                          <td>{order?.enrollment_numbers?.join(", ")}</td>
                          <td>{order?.status}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className='row mt-20'>
                  <div className='col-sm-12 col-md-6 mb-20'>
                    <div
                      className='dataTables_info'
                      id='datatable_info'
                      role='status'
                      aria-live='polite'>
                      Showing {currentPage * itemPerPage - itemPerPage + 1} to{" "}
                      {Math.min(currentPage * itemPerPage, totalData)} of{" "}
                      {totalData} entries
                    </div>
                  </div>

                  <div className='col-sm-12 col-md-6'>
                    <div className='d-flex'>
                      <ul className='pagination ms-auto'>
                        <li
                          className={
                            currentPage === 1
                              ? "paginate_button page-item previous disabled"
                              : "paginate_button page-item previous"
                          }>
                          <span
                            className='page-link'
                            style={{ cursor: "pointer" }}
                            onClick={() => setCurrentPage((prev) => prev - 1)}>
                            Previous
                          </span>
                        </li>

                        {!(currentPage - 1 < 1) && (
                          <li className='paginate_button page-item'>
                            <span
                              className='page-link'
                              style={{ cursor: "pointer" }}
                              onClick={(e) =>
                                setCurrentPage((prev) => prev - 1)
                              }>
                              {currentPage - 1}
                            </span>
                          </li>
                        )}

                        <li className='paginate_button page-item active'>
                          <span
                            className='page-link'
                            style={{ cursor: "pointer" }}>
                            {currentPage}
                          </span>
                        </li>

                        {!(
                          (currentPage + 1) * itemPerPage - itemPerPage >
                          totalData - 1
                        ) && (
                          <li className='paginate_button page-item '>
                            <span
                              className='page-link'
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setCurrentPage((prev) => prev + 1);
                              }}>
                              {currentPage + 1}
                            </span>
                          </li>
                        )}

                        <li
                          className={
                            !(
                              (currentPage + 1) * itemPerPage - itemPerPage >
                              totalData - 1
                            )
                              ? "paginate_button page-item next"
                              : "paginate_button page-item next disabled"
                          }>
                          <span
                            className='page-link'
                            style={{ cursor: "pointer" }}
                            onClick={() => setCurrentPage((prev) => prev + 1)}>
                            Next
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div
          style={{
            minHeight: "80vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <div className='col-lg-4 card'>
            <div className='card-body p-4'>
              <div className='text-center'>
                <h3>Enter Credentials</h3>
              </div>
              <div>
                <label className='form-label'>Username:</label>
                <input type='text' className='form-control' ref={usernameRef} />
              </div>
              <div>
                <label className='form-label mt-1'>Password:</label>
                <input
                  type='password'
                  className='form-control'
                  ref={passwordRef}
                />
              </div>
              <div className='text-center mt-1'>
                <button
                  className='btn btn-sm btn-info'
                  onClick={handleLogin}
                  disabled={loading}>
                  Login
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderReport;
