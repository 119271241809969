import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Navbar from "./Components/Layouts/Navbar";
import Footer from "./Components/Layouts/Footer";
import Home from "./Components/Home/Home";
import Protected from "./Components/Route/Protected.js";

import OrderReport from "./Components/school/OrderReport.js";

import Login from "./Components/User/Login";
import Signup from "./Components/User/Signup";
import ForgotPassword from "./Components/User/ForgotPass";
import Product from "./Components/Products/Product";
import SelectChild from "./Components/Products/SelectChild";

import Profile from "./Components/User/Profile";
import EditProfile from "./Components/User/EditProfile";
import Dashboard from "./Components/User/Dashboard";

import Cart from "./Components/Products/Cart";
import Checkout from "./Components/Products/Checkout";
import Address from "./Components/Products/Address";
import EditAddress from "./Components/Products/EditAddress";

import OrderConfirmation from "./Components/order/OrderConfirmation";
import OrderHistory from "./Components/order/OrderHistory";
import OrderDetails from "./Components/order/OrderDetails";

import Child from "./Components/Child/Child";
import EditChild from "./Components/Child/EditChild";

import SupportSystem from "./Components/Support/SupportSystem";
import IndividualFAQ from "./Components/Support/IndividualFAQ";
import NewSupportTicket from "./Components/Support/NewSupportTicket";

import NotFound from "./Components/NotFound";
import MyWishlist from "./Components/User/MyWishlist";
import TemsAndConditions from "./Components/Privacy Policy/TemsAndConditions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { loadUser } from "./Actions/User.js";
import ViewTicket from "./Components/Support/ViewTicket";
import AfterSubmitNewSupport from "./Components/Support/AfterSubmitNewSupport";
import AllSupportTickets from "./Components/Support/AllSupportTickets";
import IdleTimerCont from "./Components/IdleTimerCont";

function App() {
  const { user, isAuthenticated } = useSelector((state) => state.LoadUser);
  // console.log(user)

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadUser());
  }, [dispatch, isAuthenticated]);

  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/login' element={<Home />} />
          <Route path='/:url/order-report' element={<OrderReport />} />
          <Route path='/:url/login' element={<Login />} />
          <Route path='/:url/signup' element={<Signup />} />
          <Route path='/terms_and_conditions' element={<TemsAndConditions />} />

          <Route path='/:url/password/forgot' element={<ForgotPassword />} />
          <Route path='/profile' element={<Protected Component={Profile} />} />
          <Route
            path='/profile/edit'
            element={<Protected Component={EditProfile} />}
          />
          <Route
            path='/product/:id'
            element={<Protected Component={Product} />}
          />
          <Route
            path='/select_child'
            element={<Protected Component={SelectChild} />}
          />

          <Route
            path='/dashboard'
            element={<Protected user={user} Component={Dashboard} />}
          />
          <Route
            path='/wishlist/me'
            element={<Protected Component={MyWishlist} />}
          />

          <Route path='/cart' element={<Protected Component={Cart} />} />
          <Route
            path='/address/new'
            element={<Protected Component={Address} />}
          />
          <Route
            path='/address/edit/:id'
            element={<Protected Component={EditAddress} />}
          />

          <Route
            path='/cart/checkout'
            element={<Protected Component={Checkout} />}
          />

          <Route
            path='/order/confirmation/:id'
            element={<Protected Component={OrderConfirmation} />}
          />
          <Route
            path='/order/history'
            element={<Protected Component={OrderHistory} />}
          />
          <Route
            path='/order/details/:id'
            element={<Protected Component={OrderDetails} />}
          />

          <Route path='/child/add' element={<Protected Component={Child} />} />
          <Route
            path='/child/edit/:id'
            element={<Protected Component={EditChild} />}
          />
          {/* setting */}
          <Route
            path='/support_system'
            element={<Protected Component={SupportSystem} />}
          />
          <Route
            path='/individual_faq/:id'
            element={<Protected Component={IndividualFAQ} />}
          />
          <Route
            path='/support_ticket/all'
            element={<Protected Component={AllSupportTickets} />}
          />

          <Route
            path='/support_ticket/new'
            element={<Protected Component={NewSupportTicket} />}
          />
          <Route
            path='/support_ticket/new/confirmation/:id'
            element={<Protected Component={AfterSubmitNewSupport} />}
          />

          <Route
            path='/ticket/view/:id'
            element={<Protected Component={ViewTicket} />}
          />

          <Route path='*' element={<NotFound />} />
        </Routes>
        <Footer />
        <Toaster />
        <IdleTimerCont />
      </Router>
    </>
  );
}

export default App;
