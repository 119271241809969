import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { isAutheticated, signout } from "../../Auth";
import Hamburger from "../../Hamburger";
import SideNavbar from "../../SideNavbar";
import "./EditProfile.css";
import { Button, Modal } from "react-bootstrap";
import swal from "sweetalert";
import ClipLoader from "react-spinners/ClipLoader";

const Profile = () => {
  const token = isAutheticated();
  const [hamburgerOpen, setHamburgerOpen] = useState(false);

  const toggleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen);
  };
  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {
      const resp = await axios.get(
        `/api/auth/parent/profile`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log(resp.data.data)
      setData(resp.data.data);
    };
    getData();
  }, [token]);

  const onSubmit = () => {
    navigate("/profile/edit");
  };

  ////////-------------------change password functionality------------////////////
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [data, setData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  //for view models
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
  };

  ///////////
  // const handleChange = (e) => {
  //     setData((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  // }

  const [errors, setErrors] = useState({
    confirmPasswordError: "",
    newPasswordError: "",
    oldPasswordError: "",
  });

  const validPasswordRegex = RegExp(
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{7,}$/
  );
  const handleChange = (e) => {
    const { name, value } = e.target;

    switch (name) {
      case "oldPassword":
        setErrors({
          ...errors,
          oldPasswordError: validPasswordRegex.test(value)
            ? ""
            : "Password Shoud Be 8 Characters Long, Atleast One Uppercase, Atleast One Lowercase, Atleast One Digit, Atleast One Special Character",
        });

        break;
      case "newPassword":
        setErrors({
          ...errors,
          newPasswordError: validPasswordRegex.test(value)
            ? ""
            : "Password Shoud Be 8 Characters Long, Atleast One Uppercase, Atleast One Lowercase, Atleast One Digit, Atleast One Special Character",
        });

        break;
      case "confirmPassword":
        setErrors((errors) => ({
          ...errors,
          confirmPasswordError: validPasswordRegex.test(value)
            ? ""
            : "Password Shoud Be 8 Characters Long, Atleast One Uppercase, Atleast One Lowercase, Atleast One Digit, Atleast One Special Character",
        }));
        break;
      default:
        break;
    }

    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      data.oldPassword === "" ||
      data.newPassword === "" ||
      data.confirmPassword === ""
    ) {
      swal({
        title: "Warning",
        text: "Fill all fields are mandatory",
        icon: "warning",
        button: "cancel",
        dangerMode: true,
      });
      return;
    }

    if (data.confirmPassword === data.newPassword) {
      const formData = new FormData();
      formData.append("current_password", data.oldPassword);
      formData.append("new_password", data.newPassword);
      formData.append("confirm_new_password", data.confirmPassword);
      setLoading(true);
      axios
        .patch(`/api/auth/parent/changepassword`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          // console.log(res.data)
          setLoading(false);

          // const msg = res.data.message ? res.data.message : ;
          swal({
            title: "success",
            text: "Password Change successfully!",
            icon: "success",
            button: "ok",
          });

          setShow(false);
        })
        .catch((err) => {
          setLoading(false);

          const msg = err.response.data.message
            ? err.response.data.message
            : "Something went wrong!";
          swal({
            title: "Warning",
            text: msg,
            icon: "error",
            button: "ok",
            dangerMode: true,
          });
        });
    } else {
      swal({
        title: "Warning",
        text: "New Password and Confirm Password are Not Match",
        icon: "warning",
        button: "cancel",

        dangerMode: true,
      });
    }
  };

  return (
    <>
      <div className='maiprofile' style={{ minHeight: "100vh" }}>
        <div className='profilemenu '>
          <div className='submain mt-4'>
            <Link
              to='/dashboard'
              style={{
                textDecoration: "none",
                color: "black",
                fontSize: "1.6rem",
              }}>
              Dashboard
            </Link>

            <p className=' mt-3 '>
              {" "}
              <Link
                to='/select_child'
                style={{ textDecoration: "none", color: "black" }}>
                Products
              </Link>
            </p>
            <p>
              <Link
                to='/order/history'
                style={{ textDecoration: "none", color: "black" }}>
                Order History
              </Link>
            </p>
            <p>
              <Link
                to='/child/add'
                style={{ textDecoration: "none", color: "black" }}>
                Children
              </Link>
            </p>
            <p>
              <Link
                to='/support_system'
                style={{ textDecoration: "none", color: "black" }}>
                Support
              </Link>
            </p>
            {/* <p><Link to='/wishlist/me' style={{ textDecoration: 'none', color: 'black', }}>Wishlist</Link></p> */}

            <p className=' mt-3 '>
              {" "}
              <Link
                to='/profile'
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "1.1rem",
                  color: "blue",
                }}>
                My Profile
              </Link>
            </p>

            <p onClick={() => signout()}>
              <Link style={{ textDecoration: "none", color: "black" }}>
                Logout
              </Link>
            </p>
          </div>
        </div>
        {/* ----------------- */}
        <div className='hamburger' onClick={toggleHamburger}>
          <Hamburger isOpen={hamburgerOpen} />
        </div>
        {/* --------------- */}

        <SideNavbar hamburgerOpen={hamburgerOpen} />

        <div className='container '>
          <div className='row align-items-center justify-content-center '>
            <div
              className='col-16 col-md-10 col-lg-6 cententDiv mb-4 '
              style={{ marginTop: "5rem" }}>
              <div className='card w-100 shadow-sm'>
                <div
                  className='card-body '
                  style={{ borderRadius: "4px solid black" }}>
                  <div className='mb-4 d-flex justify-content-center'>
                    <h3>Profile Details</h3>
                  </div>
                  <form className='my-2 mx-3'>
                    <div className='mb-3 row'>
                      <label htmlFor='email' className='form-label col-sm-5'>
                        Name :
                      </label>
                      <span className='col-sm-7 text-center'>{data?.name}</span>
                    </div>
                    <div className='mb-3 row'>
                      <label htmlFor='email' className='form-label col-sm-5'>
                        Email :
                      </label>
                      <span className='col-sm-7 text-center'>
                        {data?.email}
                      </span>
                    </div>
                    <div className='mb-3 row'>
                      <label htmlFor='email' className='form-label col-sm-5'>
                        Mobile Number :
                      </label>
                      <span className='col-sm-7 text-center'>
                        {data.mobile_number
                          ? data.mobile_number
                          : "--Not Added--"}
                      </span>
                    </div>
                    <div className='mb-3 row'>
                      <label htmlFor='email' className='form-label col-sm-5'>
                        Gender :
                      </label>
                      <span className='col-sm-7 text-center text-capitalize'>
                        {data?.gender ? data.gender : "--Not Added--"}
                      </span>
                    </div>
                    <div className='mb-3 row'>
                      <label htmlFor='email' className='form-label col-sm-5'>
                        Location :
                      </label>
                      <span className='col-sm-7 text-center'>
                        {data?.location ? data.location : "--Not Added--"}
                      </span>
                    </div>
                    <div className='mb-3 row'>
                      <label htmlFor='email' className='form-label col-sm-5'>
                        Alternate Mobile Number :
                      </label>
                      <span className='col-sm-7 text-center'>
                        {data?.alternate_mobile_number
                          ? data.alternate_mobile_number
                          : "--Not Added--"}
                      </span>
                    </div>

                    <div className='mb-3 d-grid'>
                      <button
                        type='submit'
                        className='btn btn-dark mt-4 '
                        onClick={onSubmit}>
                        Edit
                      </button>

                      <p
                        className='btn btn-primary mt-4 '
                        onClick={() => handleShow()}>
                        Change Password
                      </p>
                    </div>
                  </form>
                  {/* model for change password */}
                  <Modal show={show} onHide={handleClose} className='p-5'>
                    <Modal.Header closeButton>
                      <Modal.Title>
                        <h4 className='d-flex justify-content-center'>
                          Change Password
                        </h4>
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className='mb-3'>
                        <label htmlFor='password' className='form-label'>
                          Old Password :
                        </label>
                        <input
                          type='password'
                          className='form-control'
                          id='password'
                          minLength={8}
                          name='oldPassword'
                          value={data.oldPassword}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      {errors.oldPasswordError && (
                        <p className='text-center py-2 text-danger'>
                          {errors.oldPasswordError}
                        </p>
                      )}
                      <div className='mb-3'>
                        <label htmlFor='password' className='form-label'>
                          New Password:
                        </label>
                        <input
                          type='password'
                          className='form-control'
                          id='password'
                          minLength={8}
                          name='newPassword'
                          value={data.newPassword}
                          onChange={handleChange}
                          required
                        />
                        {errors.newPasswordError && (
                          <p className='text-center py-2 text-danger'>
                            {errors.newPasswordError}
                          </p>
                        )}
                      </div>
                      <div className='mb-3'>
                        <label htmlFor='password' className='form-label'>
                          Confirm Password:
                        </label>
                        <input
                          type='password'
                          className='form-control'
                          id='password'
                          minLength={8}
                          name='confirmPassword'
                          value={data.confirmPassword}
                          onChange={handleChange}
                          required
                        />

                        {errors.confirmPasswordError && (
                          <p className='text-center py-2 text-danger'>
                            {errors.confirmPasswordError}
                          </p>
                        )}
                      </div>
                      <div className='mb-3 d-grid'>
                        <button
                          type='submit'
                          className='btn btn-primary mt-1'
                          onClick={handleSubmit}>
                          <ClipLoader
                            loading={loading}
                            size={21}
                            color={"black"}
                            aria-label='Loading Spinner'
                            data-testid='loader'
                          />
                          {!loading && "Update"}
                        </button>

                        <button
                          type='submit'
                          onClick={handleClose}
                          className='btn btn-dark mt-2'>
                          Cancel
                        </button>
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
