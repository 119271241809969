import axios from "axios";
import { isAutheticated } from "../Auth";
const token = isAutheticated();


export const loginUser = (formData) => async (dispatch) => {
    try {
        dispatch({
            type: "LoginRequest",
        });

        const { data } = await axios.post(
            "/api/auth/parent/login",
            formData,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        dispatch({
            type: "LoginSuccess",
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: "LoginFailure",
            payload: error.response.data.message,
        });
    }
};

export const loadUser = () => async (dispatch) => {
    try {
        dispatch({
            type: "LoadUserRequest",
        });

        const { data } = await axios.get("/api/auth/parent/profile",
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

        dispatch({
            type: "LoadUserSuccess",
            payload: data.data,
        });

    } catch (error) {
        dispatch({
            type: "LoadUserFailure",
            payload: error.response.data.message,
        });
    }
};

