import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
const Footer = () => {
  return (
    <>
      <footer className='main-container  text-center text-lg-start  '>
        {/* <div className=' footer-link d-flex  flex-wrap w-75'>
                    <div className=' mx-5'>
                        <p className=' fw-bold mt-2'>ONLINE SHOPPING</p>
                        <p className='mt-1'>Link here</p>
                        <p className='mt-1'>Link here</p>
                        <p className='mt-1'>Link here</p>
                        <p className='mt-1'>Link here</p>
                        <p className='mt-1'>Link here</p>

                    </div>
                    <div className='mx-5'>
                        <p className='fw-bold mt-2'>CUSTOMER POLICY</p>
                        <p className='mt-1'>Link here</p>
                        <p className='mt-1'>Link here</p>
                        <p className='mt-1'>Link here</p>
                        <p className='mt-1'>Link here</p>
                        <p className='mt-1'>Link here</p>

                    </div>
                    <div className='mx-5'>
                        <p className='fw-bold mt-2'>FOLLOW US</p>
                        <p className='mt-1'>Link here</p>
                        <p className='mt-1'>Link here</p>
                        <p className='mt-1'>Link here</p>


                    </div>


                </div> */}

        {/* <div className=' d-flex  justify-content-center align-items-center'>
                    <p className='  fs-6'>© {new Date().getFullYear()} Brand Name. All Rights reserved</p>
                </div> */}

        <div class='text-center p-3' style={{}}>
          © {new Date().getFullYear()} Copyright:
          <span class='text-reset fw-bold'>
            {" "}
            Reinvent Uniforms Pvt Ltd. All Rights reserved
          </span>
        </div>
      </footer>
    </>
  );
};

export default Footer;
