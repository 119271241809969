import React, { useState } from "react";
import "./SelectChild.css";
import Hamburger from "../../Hamburger";
import SideNavbar from "../../SideNavbar";
import { Link } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import { isAutheticated, signout } from "../../Auth";
import swal from "sweetalert";
const SelectChild = () => {
  const token = isAutheticated();
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const [totalChild, setTotalChild] = useState([]);
  const toggleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen);
  };
  // console.log(`Bearer ${localStorage.getItem('reinventToken')}`)
  const [data, setData] = useState({
    child_Id: "",
  });

  useEffect(() => {
    const getData = async () => {
      try {
        const resp = await axios.get(
          `/api/client/child`,

          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setTotalChild(resp.data.data);
      } catch (error) {
        swal({
          title: "Warning",
          text: error?.response?.data?.message,
          icon: "error",
          button: "Close",
          dangerMode: true,
        });
      }
    };
    getData();
  }, [token]);

  const handleChange = (e) => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  return (
    <>
      <div className='product-'>
        {localStorage.getItem("schoolBanner") && (
          <img
            src={`${localStorage.getItem("schoolBanner")}`}
            alt=''
            width='100%'
            height='100%'
            srcset=''
          />
        )}
      </div>

      <div className='mainproducts ' style={{ minHeight: "50vh" }}>
        <div className='Productmenus  '>
          <div className='submain mt-4 ms-2 '>
            <Link
              to='/dashboard'
              style={{
                textDecoration: "none",
                color: "black",
                fontSize: "1.6rem",
              }}>
              Dashboard
            </Link>

            {/* <p className=' mt-3 '> <Link to='/profile' style={{ textDecoration: 'none', color: 'black', }}>My Profile</Link></p> */}

            <p className=' mt-3 '>
              {" "}
              <Link
                to='/select_child'
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "1.1rem",
                  fontWeight: "600",
                  color: "blue",
                }}>
                Products
              </Link>
            </p>
            <p>
              <Link
                to='/order/history'
                style={{ textDecoration: "none", color: "black" }}>
                Order History
              </Link>
            </p>
            <p>
              <Link
                to='/child/add'
                style={{ textDecoration: "none", color: "black" }}>
                Children
              </Link>
            </p>
            <p>
              <Link
                to='/support_system'
                style={{ textDecoration: "none", color: "black" }}>
                Support
              </Link>
            </p>
            {/* <p><Link to='/wishlist/me' style={{ textDecoration: 'none', color: 'black', }}>Wishlist</Link></p> */}
            <p className=' mt-3 '>
              {" "}
              <Link
                to='/profile'
                style={{ textDecoration: "none", color: "black" }}>
                My Profile
              </Link>
            </p>

            <p onClick={() => signout()}>
              <Link style={{ textDecoration: "none", color: "black" }}>
                Logout
              </Link>
            </p>
          </div>
        </div>
        {/* ----------------- */}
        <div className='hamburger' onClick={toggleHamburger}>
          <Hamburger isOpen={hamburgerOpen} />
        </div>
        {/* --------------- */}

        <SideNavbar hamburgerOpen={hamburgerOpen} />

        <div className='content ' style={{ paddingBottom: "8vh" }}>
          <div
            className='d-flex justify-content-center pt-3 '
            style={{
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "48px",
              lineHeight: "56px",
            }}>
            Select Child
          </div>

          {/* ---------------------------------------------------------------- */}
          <div className=' productOrder  mt-5 mainBords '>
            {/* -------------------- */}

            <div className=' mb-3 p-2 d-flex'>
              <div className='mb-1 ms-1 mt-4 d-flex'>
                <div className=' ms-5 d-flex'>
                  <h4 htmlFor='name ' className=' mt-2'>
                    Select Child :
                  </h4>

                  <select
                    className='ms-3'
                    name='child_Id'
                    style={{ width: "10rem", height: "2.5rem" }}
                    onChange={handleChange}>
                    <option value='1'>--select--</option>
                    {totalChild &&
                      totalChild.map((item, index) => (
                        <option key={index} value={item?._id}>
                          {item?.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>

            {data && (
              <Link
                className='outline-none'
                style={{ textDecoration: "none" }}
                to={
                  data.child_Id ? `/product/${data.child_Id}` : "/select_child"
                }>
                <div className='mb-3 d-grid'>
                  <button
                    disabled={!data.child_Id}
                    type='submit'
                    className='btn btn-dark mt-4 mx-5 waves-effect waves-light'>
                    Get Products
                  </button>
                </div>
              </Link>
            )}

            {/*  */}
          </div>
        </div>
      </div>
    </>
  );
};
export default SelectChild;
