

import axios from 'axios'
import React, { useState } from 'react'
import delete4 from '../../AllImage/delete4.png'
import wishlist2 from '../../AllImage/wishlist2.png'
import wishlist3 from '../../AllImage/wishlist3.png'
import toast from "react-hot-toast";
import swal from 'sweetalert';
import { isAutheticated } from '../../Auth';



function WishlistItem({ item_data, option, option_id, bundleQuantity, quantity, wishlish_id, childID, setSuccess }) {

    // console.log(option_id)
    const [data, setData] = useState({
        price: item_data?.master_price,
        variant: '',
        product: item_data?._id,
        quantity: option === 'bundle' ? bundleQuantity : quantity || 1,
        option_id,
    })
    const [loading, setLoading] = useState(false)
    // console.log(data.product)
    function setVariant(id, price) {
        setData((prev) => ({ ...prev, variant: id, price }))
    }

    function handleQuantity(incre = false) {
        if (incre) {
            setData((prev) => ({ ...prev, quantity: prev.quantity + 1 }))
        } else {
            setData((prev) => ({ ...prev, quantity: prev.quantity === 1 ? 1 : prev.quantity - 1 }))
        }
    }



    const token = isAutheticated();

    function handleSubmit(e) {
        e.preventDefault()
        if (data.variant === '') {
            toast.error('Please select Variant')
            return
        }


        setLoading(true)

        const formData = new FormData()
        formData.append('option_id', data.option_id)
        formData.append('product', data.product)
        formData.append('variant', data.variant)
        formData.append('quantity', data.quantity)
        formData.append('child', childID)


        // console.log(data)
        axios
            .post('/api/client/cart', formData,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            )
            .then((res) => {
                toast.success("Added To Cart");
                // console.log('Successfully added to cart')
                setLoading(false)
            })
            .catch((err) => {
                // console.log('Unable to add to cart')
                // console.log(err)
                setLoading(false)
                const msg = err.response.data.message ? err.response.data.message : 'Unable to add to cart';
                toast.error(msg);



            })
    }

    function handleRemoveFromWishlist(e) {
        e.preventDefault()
        if (data.product === '') {
            toast.error('Please select item')
            return
        }
        axios
            .delete(`/api/client/wishlist/${wishlish_id}`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            )
            .then((res) => {
                toast.success("Removed from Wishlish");
                setSuccess((prev) => !prev)

            })
            .catch((err) => {

                const msg = err.response.data.message ? err.response.data.message : 'Unable to Remove from Wishlish';
                toast.error(msg);



            })
    }





    return (
        <div>
            <div className=" d-flex  mt-4">

                <div className="card productCard" >

                    <div className="row g-0" >
                        <div className="col-md-3 " >
                            {item_data.images && <img src={item_data.images[0].url} className=" responsive" alt="..." />
                            }                                            </div>
                        <div className="col-md-8 " >
                            <div className="card-body">


                                <div className="card-body" >
                                    <div className="productName d-flex  ">
                                        {item_data && <h3 className="card-title  ms-3 w-75 d-flex justify-content-Start">{item_data.name}</h3>
                                        }
                                        <div className='d-flex d-flex  w-50 justify-content-end ' >
                                            <img className=' d-flex float-end justify-content-end ' onClick={handleRemoveFromWishlist} src={delete4} alt="" width='50' height='50' />
                                            {/* <img className=' d-flex float-end justify-content-end' src={wishlist3} alt="" /> */}

                                            {/* <span class="material-symbols-outlined">
                                                favorite
                                            </span> */}
                                        </div>


                                    </div>

                                    {item_data && <h5 className=' ms-3 mt-1'>price: ₹{data.price}</h5>
                                    }
                                    <h5 className='mt-4 ms-3'>Size:</h5>
                                    <div>
                                        {item_data.variants && item_data.variants.map((v) => (
                                            <button
                                                key={v._id}
                                                className="btn btn mt-1 me-2"
                                                disabled={v._id === data.variant}
                                                onClick={() => setVariant(v._id, v.price)}
                                                style={{
                                                    border: `${v._id === data.variant ? '2px solid #0080FF' : '1px solid #000000'}`, width: "4rem", hight: '4rem'
                                                    , fontWeight: "500",
                                                    fontSize: "2rem",
                                                    color: `${v._id === data.variant ? ' #0080FF' : ''}`

                                                }}
                                            >
                                                {v.size}
                                            </button>
                                        ))}
                                    </div>



                                </div>




                                <div className="d-flex flex-wrap">

                                    <div className="mt-3 ms-3">
                                        <button onClick={handleSubmit} style={{
                                            width: "12rem",
                                            height: '2.5rem',
                                            background: '#22223B',
                                            color: 'white'

                                        }}
                                        >Add To Cart</button>
                                    </div>

                                    {option && option === 'bundle' ?

                                        <>                                    <h4 className='mt-3 ms-4'>Qty: {data.quantity}</h4>
                                        </> :
                                        <div className="mt-3 ms-3 d-flex  "
                                            style={{
                                                width: "7rem",
                                                height: '2.5rem',
                                                alignItems: 'center',
                                                margin: 'auto',

                                                background: '#22223B',
                                                color: 'white',
                                                border: '1px solid black'

                                            }}
                                        >

                                            <div className="input-group d-flex justify-content-center ">
                                                <div className="input-group-prepend">
                                                    <button className="btn btn-outline-dark" type="button"
                                                        // onClick={decNum}
                                                        onClick={() => handleQuantity(false)}
                                                        style={{

                                                            height: '2.5rem',
                                                            alignItems: 'center',
                                                            border: "none",
                                                            color: 'white'

                                                        }}>-</button>
                                                </div>
                                                <input type="text" className="form-control "
                                                    //  value={num} onChange={handleChange}
                                                    value={data.quantity}
                                                    //  disabled={true}
                                                    style={{

                                                        height: '2.5rem',
                                                        justifyContent: 'center',

                                                        border: "none",

                                                        border: '1px solid black'

                                                    }} />
                                                <div className="input-group-prepend">
                                                    <button className="btn btn-outline-dark " style={{

                                                        height: '2.5rem',
                                                        alignItems: 'center',
                                                        border: "none",
                                                        color: 'white'

                                                    }} type="button" onClick={() => handleQuantity(true)}>+</button>
                                                </div>
                                            </div>

                                        </div>
                                    }
                                </div>


                                {item_data && <div className="descrption  ms-3 mt-4">{item_data.description}</div>
                                }                                                </div>
                        </div>
                    </div>
                </div>


            </div>


            {/* <button className="btn" onClick={handleSubmit} disabled={loading}>
                Add to Cart
            </button> */}
        </div>
    )
}

export default WishlistItem
