import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { isAutheticated, signout } from "../../Auth";
import Hamburger from "../../Hamburger";
import SideNavbar from "../../SideNavbar";

import "./SupportSystem.css";

const SupportSystem = () => {
  const navigate = useNavigate();

  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const [faqData, setFaqData] = useState([]);
  const [loading, setLoading] = useState(true);

  const toggleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen);
  };

  const token = isAutheticated();
  const geFaqData = async () => {
    setLoading(true);
    axios
      .get(`/api/client/faqs`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log()
        setFaqData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    geFaqData();
  }, []);

  const handleNewTicket = () => {
    navigate("/support_ticket/new");
  };
  const handleAllTicket = () => {
    navigate("/support_ticket/all");
  };

  return (
    <div>
      <>
        <div className='' style={{ minHeight: "100vh" }}>
          <div className='Productmenu  '>
            <div className='submain mt-4'>
              <Link
                to='/dashboard'
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "1.6rem",
                }}>
                Dashboard
              </Link>

              <p className=' mt-3 '>
                {" "}
                <Link
                  to='/select_child'
                  style={{ textDecoration: "none", color: "black" }}>
                  Products
                </Link>
              </p>
              <p>
                <Link
                  to='/order/history'
                  style={{ textDecoration: "none", color: "black" }}>
                  Order History
                </Link>
              </p>
              <p>
                <Link
                  to='/child/add'
                  style={{ textDecoration: "none", color: "black" }}>
                  Children
                </Link>
              </p>

              <p>
                <Link
                  to='/support_system'
                  style={{
                    textDecoration: "none",
                    color: "black",
                    fontSize: "1.1rem",
                    color: "blue",
                  }}>
                  Support
                </Link>
              </p>

              {/* <p><Link to='/wishlist/me' style={{ textDecoration: 'none', color: 'black', }}>Wishlist</Link></p> */}

              <p className=' mt-3 '>
                {" "}
                <Link
                  to='/profile'
                  style={{ textDecoration: "none", color: "black" }}>
                  My Profile
                </Link>
              </p>

              <p onClick={() => signout()}>
                <Link style={{ textDecoration: "none", color: "black" }}>
                  Logout
                </Link>
              </p>
            </div>
          </div>
          {/* ----------------- */}
          <div className='hamburger' onClick={toggleHamburger}>
            <Hamburger isOpen={hamburgerOpen} />
          </div>
          {/* --------------- */}

          <SideNavbar hamburgerOpen={hamburgerOpen} />

          <div className='contentDivs'>
            <div className='cont pb-5'>
              <div>
                <h1
                  className='pt-5 '
                  style={{
                    // marginTop: '5%',
                    paddingBottom: "1%",
                  }}>
                  FAQs
                </h1>
              </div>
              {faqData.length > 0 ? (
                <>
                  {faqData.map((i) => (
                    <div className='d-flex align-items-center mb-4 contWidth'>
                      <div className='w-75'>
                        <h5 className='py-2'>
                          {" "}
                          <Link
                            to={`/individual_faq/${i._id}`}
                            className='ms-3 '
                            style={{ textDecoration: "none", color: "black" }}>
                            {" "}
                            {i.question}
                          </Link>
                        </h5>
                      </div>
                      {/* <div className="w-25 float-end d-flex justify-content-end me-2 "> <h3>+</h3></div> */}
                    </div>
                  ))}
                </>
              ) : loading ? (
                <div className='text-center'>
                  <div className='spinner-grow' role='status'>
                    <span className='visually-hidden'>Loading...</span>
                  </div>
                </div>
              ) : (
                <>
                  <div className='d-flex align-items-center mb-4 contWidth'>
                    <div className='w-75'>
                      <h5 className='ms-3 py-2'> No Question till Now...</h5>
                    </div>
                  </div>
                </>
              )}

              {/* <div className="d-flex align-items-center mb-4 contWidth">

                                <div className="w-75">
                                    <h5 className='ms-3'>Long questions that may streach to 2
                                        lines or more will be here jsnjacuefru fjewfrgj</h5>
                                </div>
                                <div className="w-25 float-end d-flex justify-content-end me-2 "> <h3>+</h3></div>

                            </div>
                            <div className="d-flex align-items-center mb-4 contWidth">

                                <div className="w-75">
                                    <h5 className='ms-3'>Question will be here</h5>
                                </div>
                                <div className="w-25 float-end d-flex justify-content-end me-2 "> <h3>+</h3></div>

                            </div>
                            <div className="d-flex align-items-center mb-4 contWidth">

                                <div className="w-75">
                                    <h5 className='ms-3'>Question will be here</h5>
                                </div>
                                <div className="w-25 float-end d-flex justify-content-end me-2 "> <h3>+</h3></div>

                            </div>
                            <div className="d-flex align-items-center mb-4 contWidth">

                                <div className="w-75">
                                    <h5 className='ms-3'>Question will be here</h5>
                                </div>
                                <div className="w-25 float-end d-flex justify-content-end me-2 "> <h3>+</h3></div>

                            </div>
                            <div className="d-flex align-items-center mb-4 contWidth">

                                <div className="w-75">
                                    <h5 className='ms-3'>Long questions that may streach to 2
                                        lines or more will be here jsnjacuefru fjewfrgj</h5>
                                </div>
                                <div className="w-25 float-end d-flex justify-content-end me-2 "> <h3>+</h3></div>

                            </div>
                            <div className="d-flex align-items-center mb-4 contWidth">

                                <div className="w-75">
                                    <h5 className='ms-3'>Question will be here</h5>
                                </div>
                                <div className="w-25 float-end d-flex justify-content-end me-2 "> <h3>+</h3></div>

                            </div> */}

              <p className='mt-5 ms-3'>Didn’t find what you are looking for?</p>
              <div className='d-flex flex-wrap'>
                <div className='mt-2 me-3 '>
                  <button
                    onClick={handleNewTicket}
                    className='btn btn-outline-dark'
                    style={{
                      width: "13rem",
                      height: "2.5rem",
                      background: "#ffff",
                      color: "#22223B",
                      fontSize: "1.1rem",
                      fontWeight: "500",
                    }}>
                    New Support Ticket
                  </button>
                </div>
                <div className='mt-2 me-3'>
                  <button
                    onClick={handleAllTicket}
                    className='btn btn-outline-dark'
                    style={{
                      width: "14rem",
                      height: "2.5rem",
                      background: "#ffff",
                      color: "#22223B",
                      fontSize: "1.1rem",
                      fontWeight: "500",
                    }}>
                    View All Support Ticket
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default SupportSystem;
