import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import ClipLoader from "react-spinners/ClipLoader";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
const Signup = () => {
  const navigate = useNavigate();
  const url = useParams().url;
  const [schoolName, setSchoolName] = useState(null);
  const [recapcha, setRecapcha] = useState(false);
  const recaptchaRef = React.createRef();

  const [data, setData] = useState({
    name: "",
    email: "",
    password: "",
    recaptcha: "",
  });
  const [loading, setLoading] = useState(false);
  const [agreeMent, setagreeMent] = useState(false);

  useEffect(() => {
    const getData = async () => {
      //check if already logged in
      const isAuthenticated = localStorage.getItem("reinventToken");
      if (isAuthenticated) {
        navigate("/dashboard");
      }

      const res = await axios.get(
        `/api/client/school/byurl/${url}`,

        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      localStorage.setItem("schoolID", res.data.data._id);
      setSchoolName(res.data.data.name);
      if (res?.data?.inactive_message) {
        swal({
          title: "Warning",
          text: res?.data?.inactive_message,
          icon: "error",
          button: "Close",
          dangerMode: true,
        });
      }
    };
    getData();
  }, [url]);
  const handleChange = (e) => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      data.name.trim() === "" ||
      data.email.trim() === "" ||
      data.password === ""
    ) {
      swal({
        title: "Warning",
        text: "Fill all mandatory fields",
        icon: "error",
        button: "Close",
        dangerMode: true,
      });
      return;
    }

    const formData = new FormData();
    formData.append("school_id", localStorage.getItem("schoolID"));
    formData.append("name", data.name);
    formData.append("email", data.email);
    formData.append("password", data.password);
    formData.append("recaptcha", recaptchaRef.current.getValue());

    setLoading(true);
    axios
      .post(`/api/auth/parent/register`, formData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        const msg = res.data.message
          ? res.data.message
          : "Register successfully!";

        swal({
          title: "success",
          text: msg,
          icon: "success",
          button: "ok",
        });
        setLoading(false);
        const URL = localStorage.getItem("SchoolUrl");
        navigate(URL ? `/${URL}/login` : `/:url/login`, { replace: true });
      })
      .catch((err) => {
        setLoading(false);

        const msg = err.response.data.message
          ? err.response.data.message
          : "Something went wrong!";
        swal({
          title: "Warning",
          text: msg,
          icon: "error",
          button: "Retry",
          dangerMode: true,
        });
      });
  };

  const setAgreement = () => {
    agreeMent === false ? setagreeMent(true) : setagreeMent(false);
  };

  const handleLoginClick = () => {
    const Surl = localStorage.getItem("SchoolUrl");
    if (Surl) return navigate(`/${Surl}/login`);
    navigate("/:url/login");
  };
  function onChange(value) {
    // console.log("Captcha value:", value);
    setRecapcha(true);
  }
  return (
    <>
      <div className=''>
        {localStorage.getItem("schoolBanner") && (
          <img
            src={`${localStorage.getItem("schoolBanner")}`}
            alt=''
            width='100%'
            height='100%'
            srcset=''
          />
        )}
      </div>
      <div className='container ' style={{ marginBottom: "5rem" }}>
        <div
          className='row align-items-center justify-content-center
         '>
          <div
            className='col-16 col-md-10 col-lg-5 '
            style={{ marginTop: "6rem" }}>
            {schoolName && (
              <h1 className='mb-4 d-flex align-items-center justify-content-center'>
                {schoolName}
              </h1>
            )}

            <div className='card w-100 shadow-sm'>
              <div
                className='card-body maincont'
                style={{ background: "#F2E9E4" }}>
                <div className='mb-4 d-flex justify-content-center'>
                  <div className='d-flex'>
                    <div className=' me-4'>
                      <button
                        style={{
                          width: "8rem",
                          height: "3.5rem",
                          background: "#22223B",
                          color: "white",
                        }}>
                        Signup
                      </button>
                    </div>

                    <button
                      type='submit'
                      className='btn  float-end mx-5'
                      onClick={handleLoginClick}>
                      <h4> Login</h4>
                    </button>
                  </div>
                </div>
                <form>
                  <div className='mb-3'>
                    <label
                      htmlFor='name'
                      className='form-label font-weight-bold'>
                      Name :
                    </label>
                    <input
                      style={{ height: "46px" }}
                      type='name'
                      id='name'
                      className='form-control'
                      name='name'
                      placeholder='Full Name'
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className='mb-3'>
                    <label
                      htmlFor='email'
                      className='form-label font-weight-bold'>
                      Email :
                    </label>
                    <input
                      style={{ height: "46px" }}
                      type='email'
                      id='email'
                      className='form-control'
                      name='email'
                      placeholder=' Email'
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className='mb-3'>
                    <label htmlFor='email' className='form-label'>
                      Password :
                    </label>
                    <input
                      style={{ height: "46px" }}
                      type='password'
                      id='password'
                      className='form-control'
                      name='password'
                      placeholder=' Password'
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className='form-check'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value=''
                      id='flexCheckDefault'
                      onClick={setAgreement}
                    />
                    <label
                      className='form-check-label'
                      htmlFor='flexCheckDefault'>
                      by continuing, I agree to the{" "}
                      <Link
                        to='/terms_and_conditions'
                        target='_blank'
                        rel='noopener noreferrer'>
                        Terms and Conditions
                      </Link>{" "}
                      .
                    </label>
                  </div>
                  <div className='mt-3'>
                    <ReCAPTCHA
                      sitekey='6Lca8eYjAAAAAF1P8Qw-p7jXtUHTShSaWUXuIOc5' //website
                      ref={recaptchaRef}
                      onChange={onChange}
                      //6LeHueYjAAAAAFW6VimqCaw0eNv0g_x554dXDwlT
                    />
                  </div>

                  <div className='mb-3 d-grid'>
                    <button
                      disabled={!agreeMent || !recapcha}
                      type='submit'
                      className='btn btn-dark mt-4'
                      onClick={handleSubmit}>
                      <ClipLoader loading={loading} color={"white"} size={25} />
                      {!loading && "Continue"}
                    </button>
                  </div>
                </form>
                {/* <span className='float-end'> <Link to={localStorage.getItem('SchoolUrl') ? `/${localStorage.getItem('SchoolUrl')}/password/forgot` : '/:url/password/forgot'}>Forgot Password?</Link></span> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
