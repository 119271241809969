import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { isAutheticated, signout } from "../../Auth";
import Hamburger from "../../Hamburger";
import SideNavbar from "../../SideNavbar";
import upload from "../../AllImage/upload.png";

import "./NewSupportTicket.css";
import { ClipLoader } from "react-spinners";
import swal from "sweetalert";
import axios from "axios";
import toast from "react-hot-toast";
import { useEffect } from "react";

const NewSupportTicket = () => {
  const [hamburgerOpen, setHamburgerOpen] = useState(false);

  const toggleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen);
  };

  // const actualBtn = document.getElementById('actual-btn');

  // const fileChosen = document.getElementById('file-chosen');
  // if (fileChosen) {
  //     actualBtn.addEventListener('change', function () {
  //         fileChosen.textContent = this.files[0].name
  //     })
  // }
  const token = isAutheticated();
  const navigate = useNavigate();
  const [image, setImage] = useState("");
  const [data, setData] = useState({
    subject: "",
    message: "",
    student: "",
    order: "",
  });
  const [students, setStudents] = useState([]);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    function getRequired() {
      axios
        .get(`/api/client/child`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => setStudents(res.data?.data || []));
      axios
        .get(`/api/client/order/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => setOrders(res.data?.data || []));
    }
    getRequired();
  }, []);

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const handleImage = (e) => {
    const files = e.target.files[0];
    // console.log(files)
    setImage(files);
  };

  function handleSubmit(e) {
    e.preventDefault();

    if (data.subject.trim() === "" || data.message === "") {
      swal({
        title: "Warning",
        text: "Please fill all Mandatory  field",
        icon: "error",
        button: "Close",
        dangerMode: true,
      });
      return;
    }
    const formData = new FormData();

    formData.append("subject", data.subject);
    formData.append("message", data.message);
    formData.append("student", data.student);
    formData.append("order", data.order);
    formData.append("image", image);
    setLoading(true);
    axios
      .post(
        `/api/client/supportticket`,
        formData,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        // console.log(res.data)

        navigate(`/ticket/view/${res.data.ticket_id}`);

        // navigate(`/support_ticket/new/confirmation/${res.data.ticket_id}`)
        const msg = res.data.message
          ? res.data.message
          : "Support ticket created!";
        toast.success(msg);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);

        const msg = err.response.data.message
          ? err.response.data.message
          : "Something went wrong!";
        toast.error(msg);
      });
  }

  return (
    <div>
      <>
        <div className='' style={{ minHeight: "100vh" }}>
          <div className='Productmenu  '>
            <div className='submain mt-4'>
              <Link
                to='/dashboard'
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "1.6rem",
                }}>
                Dashboard
              </Link>

              <p className=' mt-3 '>
                {" "}
                <Link
                  to='/select_child'
                  style={{ textDecoration: "none", color: "black" }}>
                  Products
                </Link>
              </p>
              <p>
                <Link
                  to='/order/history'
                  style={{ textDecoration: "none", color: "black" }}>
                  Order History
                </Link>
              </p>
              <p>
                <Link
                  to='/child/add'
                  style={{ textDecoration: "none", color: "black" }}>
                  Children
                </Link>
              </p>

              <p>
                <Link
                  to='/support_system'
                  style={{
                    textDecoration: "none",
                    color: "black",
                    fontSize: "1.1rem",
                    color: "blue",
                  }}>
                  Support
                </Link>
              </p>

              {/* <p><Link to='/wishlist/me' style={{ textDecoration: 'none', color: 'black', }}>Wishlist</Link></p> */}

              <p className=' mt-3 '>
                {" "}
                <Link
                  to='/profile'
                  style={{ textDecoration: "none", color: "black" }}>
                  My Profile
                </Link>
              </p>

              <p onClick={() => signout()}>
                <Link style={{ textDecoration: "none", color: "black" }}>
                  Logout
                </Link>
              </p>
            </div>
          </div>
          {/* ----------------- */}
          <div className='hamburger' onClick={toggleHamburger}>
            <Hamburger isOpen={hamburgerOpen} />
          </div>
          {/* --------------- */}

          <SideNavbar hamburgerOpen={hamburgerOpen} />

          <div className='content'>
            <div className='cont'>
              <div>
                <h1
                  className='pb-3'
                  style={{
                    paddingTop: "5%",
                    // marginBottom: '3%'
                  }}>
                  New Support Ticket
                </h1>
              </div>
              <div className=' mb-4 contWidths'>
                <div className='ms-3 pt-4 mb-1 '>
                  <label htmlFor='name' className='form-label font-weight-bold'>
                    Subject *
                  </label>

                  <input
                    type='text'
                    style={{ height: "44px", width: "85%" }}
                    maxLength={50}
                    id='subject'
                    className='form-control'
                    name='subject'
                    value={data.subject}
                    onChange={handleChange}
                    placeholder='Type subject here'
                    // onChange={handleChange}
                    required
                  />
                  {data.subject ? (
                    <>
                      <small className='charLeft mt-4 fst-italic'>
                        {50 - data.subject.length} characters left
                      </small>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div className='ms-3 pt-2 mb-1'>
                  <label className='form-label'>Order</label>
                  <select
                    rows='7'
                    cols='50'
                    type='text'
                    style={{ width: "85%" }}
                    className='form-control'
                    name='order'
                    value={data.order}
                    onChange={handleChange}>
                    <option value=''>--</option>
                    {orders?.map((e, i) => (
                      <option value={e?._id} key={i}>
                        {e?.order_id}
                      </option>
                    ))}
                  </select>
                </div>
                <div className='ms-3 pt-2 mb-1'>
                  <label className='form-label'>Child</label>
                  <select
                    rows='7'
                    cols='50'
                    type='text'
                    style={{ width: "85%" }}
                    className='form-control'
                    name='student'
                    value={data.student}
                    onChange={handleChange}>
                    <option value=''>--</option>
                    {students?.map((e, i) => (
                      <option value={e?._id} key={i}>
                        {e?.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className='ms-3 pt-2 mb-3'>
                  <label htmlFor='name' className='form-label font-weight-bold'>
                    Message *
                  </label>

                  <textarea
                    rows='7'
                    cols='50'
                    type='text'
                    style={{ width: "85%" }}
                    id='message'
                    maxLength={300}
                    className='form-control'
                    name='message'
                    value={data.message}
                    onChange={handleChange}
                    placeholder='Type your message here...'
                    // onChange={handleChange}
                    required
                  />
                  {data.message ? (
                    <>
                      <small className='charLeft mt-4 fst-italic'>
                        {300 - data.message.length} characters left
                      </small>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div className='ms-3'>
                  <div>
                    <div className='mb-3 mt-4'>
                      <input
                        type='file'
                        id='actual-btn'
                        accept='image/*'
                        onChange={handleImage}
                      />
                      <span className=''>(Optional)</span>
                      {/* <label for="actual-btn" style={{
                                                width: "9rem",
                                                height: '2.3rem',
                                                background: '#9A8C98',
                                                color: '#22223B',

                                                border: 'none',
                                                display: 'inlineBlock',

                                                padding: '0.5rem',

                                                cursor: 'pointer',


                                            }}> <img type='file' src={upload} alt="" srcset="" />
                                            </label> */}
                      {/* <span className='ms-2' id="file-chosen">No file chosen</span> */}
                    </div>
                  </div>
                  <button
                    className='mb-3 mt-2 btn-dark'
                    style={{
                      width: "9rem",
                      height: "2.3rem",
                      // background: 'dark',
                      // color: '#ffff',
                      // fontSize: '1.1rem',
                      // fontWeight: '500',
                      border: "none",
                    }}
                    onClick={handleSubmit}>
                    <ClipLoader loading={loading} color={"white"} size={25} />
                    {!loading && "Submit"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default NewSupportTicket;
