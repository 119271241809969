




import React, { useState } from 'react'
import './MyWishlist.css'
import productImage from '../../AllImage/Rectangle6.png'
import Hamburger from '../../Hamburger'
import SideNavbar from '../../SideNavbar'
import { Link, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import axios from 'axios'
import { isAutheticated, signout } from '../../Auth'
import WishlistItem from './WishlistItem'

const MyWishlist = () => {
    const [hamburgerOpen, setHamburgerOpen] = useState(false);

    const toggleHamburger = () => {
        setHamburgerOpen(!hamburgerOpen)
    }
    const [loading, setLoading] = useState(true);

    const token = isAutheticated();
    const [WishlistItems, setWishlistItems] = useState([]);
    const [success, setSuccess] = useState()

    useEffect(() => {


        const getData = async () => {
            setLoading(true);
            const res = await axios.get(
                `/api/client/wishlist`,

                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            );

            setWishlistItems(res.data.data)
            setLoading(false);
        }

        getData()


    }, [token, success]);


    return (
        <>
            <div className='product-main'></div>


            <div className="mainproducts " style={{ minHeight: '100vh' }}>

                <div className="Productmenu  ">
                    <div className='submain mt-4' >
                        <Link to='/dashboard' style={{ textDecoration: 'none', color: 'black', fontSize: '1.6rem', }}>Dashboard</Link>

                        <p className=' mt-3 '> <Link to='/profile' style={{ textDecoration: 'none', color: 'black', }}>My Profile</Link></p>

                        <p className=' mt-3 '> <Link to='/select_child' style={{ textDecoration: 'none', color: 'black', }}>Products</Link></p>
                        <p><Link to='/order/history' style={{ textDecoration: 'none', color: 'black', }}>Order History</Link></p>
                        <p><Link to='/child/add' style={{ textDecoration: 'none', color: 'black', }}>Children</Link></p>
                        <p><Link to='/support_system' style={{ textDecoration: 'none', color: 'black', }}>Support</Link></p>
                        <p><Link to='/wishlist/me' style={{ textDecoration: 'none', color: 'black', fontSize: '1.1rem', color: 'blue' }}>Wishlist</Link></p>


                        <p onClick={() => signout()}><Link style={{ textDecoration: 'none', color: 'black', }}>Logout</Link></p>

                    </div>

                </div>
                {/* ----------------- */}
                <div className="hamburger" onClick={toggleHamburger}>
                    <Hamburger isOpen={hamburgerOpen} />
                </div>
                {/* --------------- */}

                <SideNavbar hamburgerOpen={hamburgerOpen} />




                <div className='cardContent pb-2 '  >
                    <div className="d-flex justify-content-center pt-4 " style={{
                        fontFamily: 'Roboto',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        fontSize: '48px',
                        lineHeight: '56px'
                    }}>


                        My Wishlist

                    </div>
                    {/* <div className="d-flex justify-content-center mt-0">
                        <strong>{childPersonalData && childPersonalData.gender}  ,  {childPersonalData.grade && childPersonalData.grade?.name} ,    {childPersonalData.house && childPersonalData.house.name}
                        </strong>
                    </div> */}
                    {/* ---------------------------------------------------------------- */}


                    {/* {schoolName && <h5 className="d-flex justify-content-center mt-3 font-italic">[ {schoolName} ]</h5>} */}
                    {WishlistItems && WishlistItems.length > 0 ? (
                        <>
                            {WishlistItems && WishlistItems.map((item, index) => (

                                <>


                                    {item.option === 'bundle' ? <>


                                        {<WishlistItem key={index} item_data={item.product} option={item.option} option_id={item.bundle} bundleQuantity={item.quantity} wishlish_id={item._id} childID={item.child} setSuccess={setSuccess} />}

                                    </>
                                        : <>
                                            <WishlistItem key={index} item_data={item.product} option={item.option} option_id={item.group} quantity={item.quantity} wishlish_id={item._id} childID={item.child} setSuccess={setSuccess} />




                                        </>

                                    }

                                </>
                            ))}
                        </>

                    ) : loading ? (
                        <div className='text-center'>
                            <div className='spinner-grow' role='status'>
                                <span className='visually-hidden'>Loading...</span>
                            </div>
                        </div>
                    ) : (
                        <div style={{ width: '100%', height: '12rem' }} className="w-100">
                            <h2 style={{ marginTop: '5rem' }} className='d-flex justify-content-center '> No product added in Wishlist..</h2>
                        </div>
                    )}


                </div>

            </div>
        </>
    )
}
export default MyWishlist;
