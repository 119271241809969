import React, { useEffect, useState } from "react";
import Hamburger from "../../Hamburger";
import SideNavbar from "../../SideNavbar";
import induFaq1 from "../../AllImage/induFaq1.png";
import induFaq2 from "../../AllImage/induFaq2.png";

import "./SupportSystem.css";
import { isAutheticated, signout } from "../../Auth";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const IndividualFAQ = () => {
  const [hamburgerOpen, setHamburgerOpen] = useState(false);

  const toggleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen);
  };
  const navigate = useNavigate();

  const id = useParams().id;
  const token = isAutheticated();
  const [faqData, setFaqData] = useState([]);
  const geFaqData = async () => {
    axios
      .get(`/api/client/faqs/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data.data);
        setFaqData(res.data.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    geFaqData();
  }, []);
  const handleNewTicket = () => {
    navigate("/support_ticket/new");
  };
  const handleAllTicket = () => {
    navigate("/support_ticket/all");
  };

  return (
    <div>
      <>
        <div className='' style={{ minHeight: "100vh" }}>
          <div className='Productmenu  '>
            <div className='submain mt-4'>
              <Link
                to='/dashboard'
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "1.6rem",
                }}>
                Dashboard
              </Link>

              <p className=' mt-3 '>
                {" "}
                <Link
                  to='/select_child'
                  style={{ textDecoration: "none", color: "black" }}>
                  Products
                </Link>
              </p>
              <p>
                <Link
                  to='/order/history'
                  style={{ textDecoration: "none", color: "black" }}>
                  Order History
                </Link>
              </p>
              <p>
                <Link
                  to='/child/add'
                  style={{ textDecoration: "none", color: "black" }}>
                  Children
                </Link>
              </p>

              <p>
                <Link
                  to='/support_system'
                  style={{
                    textDecoration: "none",
                    color: "black",
                    fontSize: "1.1rem",
                    color: "blue",
                  }}>
                  Support
                </Link>
              </p>

              {/* <p><Link to='/wishlist/me' style={{ textDecoration: 'none', color: 'black', }}>Wishlist</Link></p> */}

              <p className=' mt-3 '>
                {" "}
                <Link
                  to='/profile'
                  style={{ textDecoration: "none", color: "black" }}>
                  My Profile
                </Link>
              </p>

              <p onClick={() => signout()}>
                <Link style={{ textDecoration: "none", color: "black" }}>
                  Logout
                </Link>
              </p>
            </div>
          </div>
          {/* ----------------- */}
          <div className='hamburger' onClick={toggleHamburger}>
            <Hamburger isOpen={hamburgerOpen} />
          </div>
          {/* --------------- */}

          <SideNavbar hamburgerOpen={hamburgerOpen} />

          <div className='contentDivs pb-5'>
            <div className='cont'>
              <div>
                <h1
                  className=''
                  style={{
                    paddingTop: "5%",
                    paddingBottom: "3%",
                  }}>
                  FAQs
                </h1>
              </div>
              {faqData && (
                <>
                  {" "}
                  <div className=''>
                    <h4>{faqData?.question}</h4>
                  </div>
                  <div className=' w-75'>
                    <p>{faqData?.answer}</p>
                  </div>
                  <div className='d-flex  flex-wrap'>
                    {faqData?.images &&
                      faqData?.images.map((img) => (
                        <img
                          className='me-4 mt-2'
                          src={img?.url}
                          width='300'
                          alt=''
                        />
                      ))}
                    {/* {faqData?.images && <img className="me-4 mt-2" src={faqData?.images[1]?.url} width='300' alt='' />} */}
                  </div>
                </>
              )}

              <p className='mt-5 ms-3'>Didn’t find what you are looking for?</p>
              <div className='d-flex flex-wrap'>
                <div className='mt-2 me-3 '>
                  <button
                    onClick={handleNewTicket}
                    className='btn btn-outline-dark'
                    style={{
                      width: "13rem",
                      height: "2.5rem",
                      background: "#ffff",
                      color: "#22223B",
                      fontSize: "1.1rem",
                      fontWeight: "500",
                    }}>
                    New Support Ticket
                  </button>
                </div>
                <div className='mt-2 me-3'>
                  <button
                    onClick={handleAllTicket}
                    className='btn btn-outline-dark'
                    style={{
                      width: "14rem",
                      height: "2.5rem",
                      background: "#ffff",
                      color: "#22223B",
                      fontSize: "1.1rem",
                      fontWeight: "500",
                    }}>
                    View All Support Ticket
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default IndividualFAQ;
