










import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { isAutheticated, signout } from '../../Auth'
import Hamburger from '../../Hamburger'
import SideNavbar from '../../SideNavbar'
import upload from '../../AllImage/upload.png'


import './AfterSubmitNewSupport.css'
import axios from 'axios'

const AfterSubmitNewSupport = () => {

    const [hamburgerOpen, setHamburgerOpen] = useState(false);

    const toggleHamburger = () => {
        setHamburgerOpen(!hamburgerOpen)
    }
    const id = useParams()?.id
    const token = isAutheticated()
    // console.log(token)
    const [ticketData, setTicketData] = useState()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const getTicketConfirmationData = async () => {
        axios
            .get(`/api/client/supportticket/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                console.log(res.data)
                setTicketData(res.data.data)
            })

    }

    useEffect(() => {
        getTicketConfirmationData()
    }, [])
    //change time formate
    function formatAMPM(date) {
        var hours = new Date(date).getHours();
        var minutes = new Date(date).getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    }
    return (
        <div>

            <>
                <div className="" style={{ minHeight: '100vh' }}>
                    <div className="Productmenu  ">
                        <div className='submain mt-4' >
                            <Link to='/dashboard' style={{ textDecoration: 'none', color: 'black', fontSize: '1.6rem' }}>Dashboard</Link>


                            <p className=' mt-3 '> <Link to='/select_child' style={{ textDecoration: 'none', color: 'black', }}>Products</Link></p>
                            <p><Link to='/order/history' style={{ textDecoration: 'none', color: 'black', }}>Order History</Link></p>
                            <p><Link to='/child/add' style={{ textDecoration: 'none', color: 'black', }}>Children</Link></p>

                            <p><Link to='/support_system' style={{ textDecoration: 'none', color: 'black', fontSize: '1.1rem', color: 'blue' }}>Support</Link></p>

                            {/* <p><Link to='/wishlist/me' style={{ textDecoration: 'none', color: 'black', }}>Wishlist</Link></p> */}

                            <p className=' mt-3 '> <Link to='/profile' style={{ textDecoration: 'none', color: 'black', }}>My Profile</Link></p>


                            <p onClick={() => signout()}><Link style={{ textDecoration: 'none', color: 'black', }}>Logout</Link></p>

                        </div>

                    </div>
                    {/* ----------------- */}
                    <div className="hamburger" onClick={toggleHamburger}>
                        <Hamburger isOpen={hamburgerOpen} />
                    </div>
                    {/* --------------- */}

                    <SideNavbar hamburgerOpen={hamburgerOpen} />

                    <div className="content">
                        <div className="cont pb-4" >

                            <div>
                                <h4 className='pb-3' style={{
                                    paddingTop: '3%',
                                    // marginBottom: '3%'
                                }}>Your Submission Have Been Recorded. We Will Get <br />
                                    Back To You As Soon As Possible.</h4>
                            </div>
                            {ticketData && <div className=" mb-3 contWidthss "  >
                                <div className=" pt-2 mb-3 pb-2 " style={{ borderBottom: '2px solid black' }}>
                                    <small className="d-flex float-end me-2">{new Date(`${ticketData?.createdAt}`).toDateString()},  <span> {formatAMPM(ticketData?.createdAt)}</span></small>

                                    <h6 className="ms-4">Ticket Number</h6>
                                    <Link
                                        to={`/ticket/view/${id}`} style={{ textDecoration: 'none' }}>
                                        <small className="ms-4 .fs-3 text fst-italic">
                                            {ticketData?.ticket_number}</small>
                                    </Link>

                                    {/* <hr /> */}
                                </div>
                                <div className=" mb-3 pb-2 " style={{ borderBottom: '2px solid black' }}>
                                    <h6 className="ms-4"> Parent Name</h6>
                                    <small className="ms-4">{ticketData?.parent?.name}</small>

                                    {/* <hr /> */}
                                </div>
                                <div className="mb-3 pb-2 " style={{ borderBottom: '2px solid black' }}>
                                    <h6 className="ms-4">Email</h6>
                                    <small className="ms-4">{ticketData?.parent?.email}</small>

                                    {/* <hr /> */}
                                </div>
                                <div className="  mb-3 pb-2" style={{ borderBottom: '2px solid black' }}>
                                    <h6 className="ms-4">Subject</h6>
                                    <small className="ms-4"> {ticketData?.subject}
                                    </small>

                                    {/* <hr /> */}
                                </div>
                                <div className=" mb-3 pb-2" style={{ borderBottom: '2px solid black' }}>
                                    <h6 className="ms-4">Message</h6>
                                    <div className="ms-4">
                                        <small className=""> {ticketData?.message} </small>

                                    </div>

                                    {/* <hr /> */}
                                </div>
                                {ticketData?.image_url && <div className=" mb-3 pb-2" style={{ borderBottom: '2px solid black' }}>
                                    <h6 className="ms-4">Image</h6>

                                    <img className="ms-4 mt-1" src={ticketData?.image_url} alt="" width={70} srcset="" />

                                    {/* <hr /> */}
                                </div>
                                }


                                <div className="  mb-3 ">
                                    <strong className="ms-4">Status</strong>
                                    <span className="ms-4 text-danger" style={{ fontWeight: '700' }}>Pending</span>

                                    {/* <hr /> */}
                                </div>
                                {/* <div className="ms-3 pt-2 mb-3">

                                    <label htmlFor="name" className="form-label font-weight-bold">Message</label>

                                    <textarea rows='7' cols='50' type="text" style={{ width: '85%' }} id="Enroll" className="form-control" name="Enroll" placeholder="Type your message here..."
                                        // onChange={handleChange}
                                        required />

                                </div> */}
                                {/* <div className="ms-3">
                                    <div>              <button className='mb-3' style={{
                                        width: "9rem",
                                        height: '2.3rem',
                                        background: '#9A8C98',
                                        color: '#22223B',
                                        
                                        border: 'none'

                                    }}>
                                        <img src={upload} alt="" srcset="" />

                                    </button>
                                    </div>
                                    <button className='mb-3 btn-dark' style={{
                                        width: "9rem",
                                        height: '2.3rem',
                                       
                                        border: 'none'

                                    }}>
                                        Submit
                                    </button>                                </div> */}


                            </div>


                            }








                            <Link to='/dashboard' className='mb-4' ><small>Go back to Home?</small></Link>


                        </div>











                    </div>




                </div>


            </>
        </div>
    )
}

export default AfterSubmitNewSupport;