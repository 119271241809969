import React, { useEffect } from 'react'
import './Navbar.css'
import mainlogo from '../../AllImage/reinventlogo.png'
import wishlistImg from '../../AllImage/wishlist.png'
import cartImg from '../../AllImage/cart.png'
import { Link } from 'react-router-dom'
import { buildQueries } from '@testing-library/react'
import { useState } from 'react'

const Navbar = () => {
    const [isAuth, setIsAuth] = useState(false)

    useEffect(() => {
        const token = localStorage.getItem('reinventToken')
        if (token) {
            setIsAuth(true)
        }
        else {
            setIsAuth(false)
        }
    }, [localStorage.getItem('reinventToken')])
    return (
        <>
            <div className='main'>
                <div className='mainlogo'>
                    <img src={mainlogo} alt="" />
                </div>
                <div className='menu-item d-flex .float-xxl-end '>

                    {/* {isAuth && <div className='mx-3' >

                        <Link to='/wishlist/me'>
                            <img src={wishlistImg} alt="" />
                        </Link>
                        <br />
                        <Link to='/wishlist/me'>
                            <span className='wishlist '>wishlist</span>
                        </Link>


                    </div>} */}
                    <div className='mx-4 d-flex justify-content-center'>
                        {isAuth && <div>
                            <Link to='/cart'><img style={{ marginTop: '-1%' }} src={cartImg} alt="" /></Link>
                            <br />
                            <span style={{ textDecoration: 'none' }} className='text-white'>cart</span>

                        </div>}
                        {/* <span className=" d-flex justify-content-center" style={{ color: 'white', borderRadius: '100%', marginTop: '-.5rem', fontWeight: "600" }}>15</span> */}


                    </div>



                </div>




            </div>
        </>

    )
}

export default Navbar