import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import swal from "sweetalert";
import ClipLoader from "react-spinners/ClipLoader";
import axios from "axios";
import { loadUser, loginUser } from "../../Actions/User";
import { useDispatch, useSelector } from "react-redux";
const Login = () => {
  const url = useParams().url;
  const dispatch = useDispatch();
  const recaptchaRef = React.createRef();
  const [schoolName, setSchoolName] = useState(null);
  const [schoolBanner, setSchoolBanner] = useState(null);
  const [recapcha, setRecapcha] = useState(false);

  const [data, setData] = useState({
    email: "",
    password: "",
    recaptcha: "",
  });
  const [loading, setLoading] = useState(false);
  const [agreeMent, setagreeMent] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  //set url
  localStorage.setItem("SchoolUrl", url);
  useEffect(() => {
    const getData = async () => {
      //check if already logged in
      const isAuthenticated = localStorage.getItem("reinventToken");
      if (isAuthenticated) {
        navigate("/dashboard");
      }

      const res = await axios.get(
        `/api/client/school/byurl/${url}`,

        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setSchoolBanner(res.data.data.banner.url);
      if (res.data.data?.banner) {
        localStorage.setItem("schoolBanner", res.data.data.banner.url);
      }

      localStorage.setItem("schoolID", res.data.data._id);
      setSchoolName(res.data.data.name);
      if (res?.data?.inactive_message) {
        swal({
          title: "Warning",
          text: res?.data?.inactive_message,
          icon: "error",
          button: "Close",
          dangerMode: true,
        });
      }
    };
    getData();
  }, []);

  //  if (recaptchaRef.current.getValue() === "")
  // return setError((prev) => ({
  //     ...prev,
  //     errormessage: "*Verify ReCaptcha",
  // }));
  const handleSubmit = (e) => {
    e.preventDefault();

    if (data.email.trim() === "" || data.password === "") {
      swal({
        title: "Warning",
        text: "Fill all mandatory fields",
        icon: "error",
        button: "Close",
        dangerMode: true,
      });
      return;
    }

    const formData = new FormData();
    formData.append("school_id", localStorage.getItem("schoolID"));
    formData.append("email", data.email);
    formData.append("password", data.password);
    formData.append("recaptcha", recaptchaRef.current.getValue());

    //     dispatch(loginUser(formData))
    // }

    // const { loading, user, error, message, isAuthenticated } = useSelector((state) => state.Login)
    // console.log(user, isAuthenticated, user.token)

    // useEffect(() => {

    //     if (error) {

    //         swal({
    //             title: 'success',
    //             text: error,
    //             icon: 'success',
    //             button: 'ok',
    //         })
    //         dispatch({ type: "clearErrors" });
    //     }

    //     if (message) {

    //         // const msg = res.data.message ? res.data.message : 'Login successfully!';
    //         swal({
    //             title: 'warning',
    //             text: message,
    //             icon: 'error',
    //             button: 'ok',
    //         })
    //         dispatch({ type: "clearMessage" });
    //         navigate('/dashboard')
    //     }

    // }, [dispatch, error, message]);
    setLoading(true);
    axios
      .post(`/api/auth/parent/login`, formData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        setLoading(false);
        localStorage.setItem("reinventToken", res.data.token);
        // const token = res.data.token
        // dispatch(loadUser(token));
        const msg = res.data.message ? res.data.message : "Login successfully!";
        swal({
          title: "Success",
          text: msg,
          icon: "success",
          button: "ok",
        });

        if (res.data.token) return window.location.replace(`/dashboard`);

        // navigate('/dashboard')
      })
      .catch((err) => {
        setLoading(false);

        const msg = err.response.data.message
          ? err.response.data.message
          : "Something went wrong!";
        swal({
          title: "Warning",
          text: msg,
          icon: "error",
          button: "Retry",
          dangerMode: true,
        });
      });
  };

  function handleSignupClick() {
    // window.location.reload();
    const Surl = localStorage.getItem("SchoolUrl");
    if (Surl) return navigate(`/${Surl}/signup`);
    navigate("/:url/signup");
  }

  const setAgreement = () => {
    agreeMent === false ? setagreeMent(true) : setagreeMent(false);
  };

  function onChange(value) {
    // console.log("Captcha value:", value);
    setRecapcha(true);
  }

  // ReactDOM.render(
  //     ,
  //     document.body
  // );
  return (
    <>
      {/* <div className=" d-flex justify-content-center">

                <div className=" mainlog d-flex justify-content-center mt-5">
                    <h2>Signup</h2>

                </div>
            </div> */}
      <div className=''>
        {/* style={{ backgroundImage: `url(${schoolBanner})`, width: '100%' }} */}
        {/* {schoolBanner && <img src={`${schoolBanner}`} alt="" width='100%' height='100%' srcset="" />} */}
        {localStorage.getItem("schoolBanner") && (
          <img
            src={`${localStorage.getItem("schoolBanner")}`}
            alt=''
            width='100%'
            height='100%'
            srcset=''
          />
        )}
      </div>
      <div className='container ' style={{ marginBottom: "5rem" }}>
        <div className='row align-items-center justify-content-center'>
          <div
            className='col-16 col-md-10 col-lg-5 '
            style={{ marginTop: "6rem" }}>
            {/* {schoolBanner && <img src={`${schoolBanner}`} alt="" width='100%' height='100%' srcset="" />} */}
            {/* {localStorage.getItem('schoolBanner') && <img src={`${localStorage.getItem('schoolBanner')}`} alt="" width='100%' height='100%' srcset="" />} */}

            {schoolName && (
              <h1 className='mb-4 d-flex align-items-center justify-content-center'>
                {schoolName}
              </h1>
            )}

            <div className='card w-100 shadow-sm'>
              <div
                className='card-body maincont'
                style={{ background: "#F2E9E4" }}>
                <div className='mb-4 d-flex justify-content-center'>
                  <div className='d-flex'>
                    <div className=' me-4'>
                      <button
                        style={{
                          width: "8rem",
                          height: "3.5rem",
                          background: "#22223B",
                          color: "white",
                        }}>
                        Login
                      </button>
                    </div>

                    <button
                      type='submit'
                      className='btn  float-end mx-5'
                      onClick={() => handleSignupClick()}>
                      <h4> Signup</h4>
                    </button>
                  </div>
                </div>
                <form>
                  <div className='mb-3'>
                    <label
                      htmlFor='email'
                      className='form-label font-weight-bold'>
                      Email :
                    </label>
                    <input
                      style={{ height: "46px" }}
                      type='email'
                      id='email'
                      className='form-control'
                      name='email'
                      placeholder=' Email'
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className='mb-3'>
                    <label htmlFor='email' className='form-label'>
                      Password :
                    </label>
                    <input
                      style={{ height: "46px" }}
                      type='password'
                      id='password'
                      className='form-control'
                      name='password'
                      placeholder=' Password'
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className='form-check'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value=''
                      id='flexCheckDefault'
                      onClick={setAgreement}
                    />
                    <label
                      className='form-check-label'
                      htmlFor='flexCheckDefault'>
                      by continuing, I agree to the{" "}
                      <Link
                        to='/terms_and_conditions'
                        target='_blank'
                        rel='noopener noreferrer'>
                        Terms and Conditions
                      </Link>
                      .
                    </label>
                  </div>
                  <div className='mt-3'>
                    <ReCAPTCHA
                      // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"//local
                      sitekey='6Lca8eYjAAAAAF1P8Qw-p7jXtUHTShSaWUXuIOc5' //website
                      ref={recaptchaRef}
                      onChange={onChange}
                      //6LeHueYjAAAAAFW6VimqCaw0eNv0g_x554dXDwlT
                    />
                  </div>

                  <div className='mb-3 d-grid'>
                    <button
                      disabled={!agreeMent || !recapcha}
                      type='submit'
                      className='btn btn-dark mt-4'
                      onClick={handleSubmit}>
                      <ClipLoader loading={loading} color={"white"} size={25} />
                      {!loading && "Continue"}
                    </button>
                  </div>
                </form>

                <span className='float-end'>
                  {" "}
                  <Link
                    to={
                      localStorage.getItem("SchoolUrl")
                        ? `/${localStorage.getItem(
                            "SchoolUrl"
                          )}/password/forgot`
                        : "/:url/password/forgot"
                    }>
                    Forgot Password?
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
