import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import swal from "sweetalert";
import { isAutheticated, signout } from "../../Auth";
import Hamburger from "../../Hamburger";
import SideNavbar from "../../SideNavbar";
import ClipLoader from "react-spinners/ClipLoader";

import "./EditProfile.css";
import { Link, Navigate, useNavigate } from "react-router-dom";
const EditProfile = () => {
  const token = isAutheticated();
  const navigate = useNavigate();
  const [email, setEmail] = useState();
  const [data, setData] = useState({
    name: "",
    gender: "",
    mobile_number: "",
    location: "",
    alternate_mobile_number: "",
  });
  const [loading, setLoading] = useState(false);

  const [hamburgerOpen, setHamburgerOpen] = useState(false);

  const toggleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen);
  };
  const handleChange = (e) => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  useEffect(() => {
    const getData = async () => {
      const resp = await axios.get(
        `/api/auth/parent/profile`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setData((prev) => ({
        ...prev,
        ...resp.data.data,
      }));
      setEmail(resp.data.data.email);
    };
    getData();
  }, [token]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      data.name.trim() === "" ||
      data.gender === "" ||
      data.mobile_number === "" ||
      data.location === ""
    ) {
      swal({
        title: "Warning",
        text: "Fill all mandatory fields",
        icon: "error",
        button: "Close",
        dangerMode: true,
      });
      return;
    }

    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("mobile_number", data.mobile_number);

    formData.append("gender", data.gender);
    formData.append("location", data.location);

    formData.append("alternate_mobile_number", data.alternate_mobile_number);

    setLoading(true);
    axios
      .patch(`/api/auth/parent/profile`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const msg = res.data.message
          ? res.data.message
          : "Edited successfully!";
        swal({
          title: "success",
          text: msg,
          icon: "success",
          button: "Ok",
        });
        setLoading(false);
        navigate(-1);
        // navigate('/dashboard', { replace: true })
      })
      .catch((err) => {
        setLoading(false);

        const msg = err.response.data.message
          ? err.response.data.message
          : "Something went wrong!";
        swal({
          title: "Warning",
          text: msg,
          icon: "error",
          button: "Retry",
          dangerMode: true,
        });
      });
  };
  return (
    <>
      <div className='maiprofile' style={{ minHeight: "100vh" }}>
        <div className='profilemenu '>
          <div className='submain mt-4'>
            <Link
              to='/dashboard'
              style={{
                textDecoration: "none",
                color: "black",
                fontSize: "1.6rem",
              }}>
              Dashboard
            </Link>

            <p className=' mt-3 '>
              {" "}
              <Link
                to='/select_child'
                style={{ textDecoration: "none", color: "black" }}>
                Products
              </Link>
            </p>
            <p>
              <Link
                to='/order/history'
                style={{ textDecoration: "none", color: "black" }}>
                Order History
              </Link>
            </p>
            <p>
              <Link
                to='/child/add'
                style={{ textDecoration: "none", color: "black" }}>
                Children
              </Link>
            </p>
            <p>
              <Link
                to='/support_system'
                style={{ textDecoration: "none", color: "black" }}>
                Support
              </Link>
            </p>
            {/* <p><Link to='/wishlist/me' style={{ textDecoration: 'none', color: 'black', }}>Wishlist</Link></p> */}

            <p className=' mt-3 '>
              {" "}
              <Link
                to='/profile'
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "1.1rem",
                  color: "blue",
                }}>
                My Profile
              </Link>
            </p>

            <p onClick={() => signout()}>
              <Link style={{ textDecoration: "none", color: "black" }}>
                Logout
              </Link>
            </p>
          </div>
        </div>
        {/* ----------------- */}
        <div className='hamburger' onClick={toggleHamburger}>
          <Hamburger isOpen={hamburgerOpen} />
        </div>
        {/* --------------- */}

        <SideNavbar hamburgerOpen={hamburgerOpen} />

        <div className='container '>
          <div className='row align-items-center justify-content-center '>
            <div
              className='col-16 col-md-10 col-lg-6 cententDiv '
              style={{ marginTop: "5rem" }}>
              <div className='card w-100 shadow-sm'>
                <div
                  className='card-body p-3 '
                  style={{ borderRadius: "4px solid black" }}>
                  <div className='mb-4 d-flex justify-content-center'>
                    <h3>Edit Profile</h3>
                  </div>
                  <form className='mt-2 mx-3'>
                    <div className='mb-3 row'>
                      <label htmlFor='email' className='form-label col-sm-5'>
                        Name* :
                      </label>
                      <div className='col-sm-7'>
                        <input
                          className='form-control'
                          type='text'
                          value={data.name}
                          name='name'
                          placeholder=' Name'
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className='mb-3 row'>
                      <label htmlFor='email' className='form-label col-sm-5'>
                        Email :
                      </label>
                      <span className='col-sm-7'>{email && email}</span>
                    </div>
                    <div className='mb-3 row'>
                      <label htmlFor='email' className='form-label col-sm-5'>
                        Mobile Number* :
                      </label>
                      <div className='col-sm-7'>
                        <input
                          className='form-control'
                          type='tel'
                          name='mobile_number'
                          value={data.mobile_number}
                          placeholder=' Mobile Number'
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className='mb-3 row'>
                      <label htmlFor='email' className='form-label col-sm-5'>
                        Gender* :
                      </label>
                      <div className='col-sm-7'>
                        <select
                          className='form-control'
                          name='gender'
                          value={data.gender}
                          placeholder='gender'
                          onChange={handleChange}
                          required>
                          <option value=''>--select--</option>
                          <option value='male'>Male</option>
                          <option value='female'>Female</option>
                          <option value='other'>Other</option>
                        </select>
                      </div>
                    </div>
                    <div className='mb-3 row'>
                      <label htmlFor='email' className='form-label col-sm-5'>
                        Location* :
                      </label>
                      <div className='col-sm-7'>
                        <input
                          className='form-control'
                          type='text'
                          value={data.location}
                          name='location'
                          placeholder='location'
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className='mb-3 row'>
                      <label htmlFor='email' className='form-label col-sm-5'>
                        Alternate Mobile Number :
                      </label>
                      <div className='col-sm-7'>
                        <input
                          className='form-control'
                          type='tel'
                          name='alternate_mobile_number'
                          value={data.alternate_mobile_number}
                          placeholder='Alternate Mobile Number'
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>

                    <div className='mb-3 d-grid'>
                      <button
                        type='submit'
                        className='btn btn-dark mt-4'
                        onClick={handleSubmit}>
                        <ClipLoader
                          loading={loading}
                          color={"white"}
                          size={25}
                        />
                        {!loading && "Update"}
                      </button>

                      <button
                        type='submit'
                        onClick={() => navigate("/profile")}
                        className='btn btn-primary mt-2'>
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProfile;
