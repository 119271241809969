import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";




const Protected = ({ Component, user }) => {
    // const { loading, user, isAuthenticated } = useSelector((state) => state.LoadUser)
    // console.log(user)

    const navigate = useNavigate()
    // const dispatch = useDispatch();
    // useEffect(() => {
    //     dispatch(loadUser());
    // }, [dispatch]);

    useEffect(() => {
        const isAuthenticated = localStorage.getItem('reinventToken')

        const Surl = localStorage.getItem('SchoolUrl')

        if (!isAuthenticated) {


            if (Surl) {
                toast.error('Please Login to excess this')

                navigate(`/${Surl}/login`)
            }
            else {
                toast.error('Please Login to excess this')
                navigate('/:url/login')

            }
        }
    }, [])




    return (
        <>


            <Component />



        </>
    );
};

export default Protected;


// import React from "react";
// import { Navigate, Outlet } from "react-router-dom";

// const Protected = ({
//     isAuthenticated,
//     children,
//     user,
//     redirect = localStorage.getItem('SchoolUrl') ? `/${localStorage.getItem('SchoolUrl')}/login` : '/:url/login',
// }) => {

//     if (!isAuthenticated) {
//         return <Navigate to={redirect} />;
//     }



//     return children ? children : <Outlet />;
// };

// export default Protected;
