import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const isAuthenticated = localStorage.getItem("reinventToken");
    const Surl = localStorage.getItem("SchoolUrl");

    if (!isAuthenticated) {
      if (Surl) {
        navigate(`/${Surl}/login`);
      }
    } else {
      navigate("/dashboard");
    }
  }, []);

  return (
    <>
      <div class='d-flex align-items-center justify-content-center vh-100'>
        <div class='text-center'>
          <p class='fs-3'>
            {" "}
            <span class='text-danger'>Please!</span> Get Your School URL From
            Admin..
          </p>
          <p class='lead'>🆃🅷🅰🅽🅺🆈🅾🆄</p>
          {/* <Link to="/:url/login" class="btn btn-dark">Go Home</Link> */}
        </div>
      </div>
    </>
  );
};

export default Home;
