import axios from "axios";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { isAutheticated } from "../../Auth";
import productPlaceholder from "../../AllImage/productPlaceholder.jpg";
import SizeChart from "./elements/SizeChart.jsx";

const Bundle = ({ products, option_id, childId }) => {
  const [loading, setLoading] = useState(false);
  const [sizeChart, setSizeChart] = useState();
  const token = isAutheticated();

  const [productsDataObj, setProductsDataObj] = useState(() => {
    let obj = {};
    products.forEach((e) => {
      obj[e?.product?._id?.toString()] = "";
    });
    return obj;
  });

  const handleSizeChange = (e, product_id) => {
    setProductsDataObj((prev) => {
      let obj = { ...prev };
      obj[product_id] = e.target.value;
      return obj;
    });
  };

  const handleSubmit = () => {
    const emptySizes = Object.values(productsDataObj).filter(
      (e) => e.trim() === ""
    );
    if (emptySizes.length > 0) {
      toast.error("Please select Size for all Products");
      return;
    }
    const productsData = Object.keys(productsDataObj).map((e) => ({
      product: e,
      variant: productsDataObj[e],
    }));
    setLoading(true);

    axios
      .post(
        "/api/client/cart/bundle",
        {
          option_id,
          child: childId,
          productsData,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        toast.success("Added To Cart", {
          style: {
            border: "1px solid #black",
            padding: "16px",
            color: "black",
          },
          iconTheme: {
            primary: "green",
            secondary: "#FFFAEE",
          },
        });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        const msg = err?.response?.data?.message
          ? err.response.data.message
          : "Unable to add to cart";
        toast.error(msg, {
          style: {
            border: "1px solid #black",
            padding: "16px",
            color: "#713200",
          },
          iconTheme: {
            primary: "red",
            secondary: "#FFFAEE",
          },
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className=''>
      {products?.map((e, i) => {
        const size = e?.product?.variants?.find(
          (v) =>
            v?._id?.toString() === productsDataObj[e.product?._id?.toString()]
        )?.size?.size;
        return (
          <div key={i}>
            <div className='d-flex mt-4'>
              <div className='card productCard'>
                <div className='row g-0'>
                  <div className='col-md-3 '>
                    {e?.product?.images && e?.product?.images?.length > 0 ? (
                      <img
                        src={e.product.images[0].url}
                        className=' responsive'
                        alt='...'
                      />
                    ) : (
                      <img
                        src={productPlaceholder}
                        alt='...'
                        className=' responsive'
                      />
                    )}
                  </div>
                  <div className='col-md-8 '>
                    <div className='card-body'>
                      <div className='card-body'>
                        <div className='productName d-flex  '>
                          <h3 className='card-title  ms-1 w-75 d-flex justify-content-Start'>
                            {e?.display_name || e?.product?.name}
                          </h3>
                        </div>

                        <h5 className=' ms-1 mt-1'>
                          price: ₹
                          {productsDataObj[e?.product?._id?.toString()]
                            ? e?.variants_data_object[
                                productsDataObj[e?.product?._id?.toString()]
                              ]
                            : e?.master_price}
                        </h5>

                        <div className=' d-flex flex-wrap mt-3'>
                          <div
                            className='d-flex justify-content-center me-2 mt-3'
                            style={{
                              background: "#F2E9E4",
                              // width: '.1rem',
                              width: "6rem",
                            }}>
                            <span className='d-flex justify-content-center align-items-center fs-6 ms-1'>
                              SIZE {size} :
                            </span>
                            <select
                              className='ms-2 '
                              name='variant'
                              style={{
                                border: "none",
                                background: "#F2E9E4",
                                width: "1rem",
                                height: "2.5rem",
                                margin: "auto",
                              }}
                              onChange={(evn) =>
                                handleSizeChange(
                                  evn,
                                  e.product?._id?.toString()
                                )
                              }
                              value={
                                productsDataObj[e?.product?._id?.toString()]
                              }>
                              <option value='' style={{ display: "none" }}>
                                --
                              </option>
                              {e?.product?.variants?.map((v, i) =>
                                true &&
                                v?.show_in_estore === true &&
                                e?.variants_data_object[v._id] ? (
                                  <option key={i} value={v?._id}>
                                    {v.size.size}
                                  </option>
                                ) : null
                              )}
                            </select>
                          </div>

                          <>
                            <h5 className='mt-4 mx-2'>Qty: {e?.quantity}</h5>
                          </>
                        </div>
                      </div>

                      <div className='descrption  ms-3 mt-4'>
                        {e?.product?.description}
                      </div>
                      {e?.size_chart && (
                        <p
                          className='mt-1 ms-3 fw-bold'
                          style={{ color: "brown", cursor: "pointer" }}
                          onClick={() => setSizeChart(e)}>
                          Check Size Chart
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <div className='text-center mt-3'>
        <button
          className='btn btn-dark'
          onClick={() => handleSubmit()}
          disabled={loading}>
          Add Bundle to Cart
        </button>
      </div>
      <SizeChart product={sizeChart} setProduct={setSizeChart} />
    </div>
  );
};

export default Bundle;
