



import React, { useState } from 'react'
import Hamburger from '../../Hamburger'
import SideNavbar from '../../SideNavbar'
import productImage from '../../AllImage/Rectangle6.png'
import './AllSupportTickets.css'
import { Link, useNavigate } from "react-router-dom";
import { isAutheticated, signout } from '../../Auth'
import axios from 'axios'
import { useEffect } from 'react'

const AllSupportTickets = () => {

    const [hamburgerOpen, setHamburgerOpen] = useState(false);

    const toggleHamburger = () => {
        setHamburgerOpen(!hamburgerOpen)
    }

    const [loading, setLoading] = useState(true);

    const token = isAutheticated();

    const [allTicketData, setAllTicketData] = useState([]);



    useEffect(() => {

        const getAllTicketData = async () => {
            setLoading(true)
            const res = await axios.get(
                `/api/client/supportticket`,

                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            console.log(res.data.data)
            setAllTicketData(res.data.data)
            // setticketAmount(res.data.data.total_amount)
            // setOrdeeId(res.data.data.ticket_id)
            // setticketPlacedDateTime(res.data.data.placed_on)

            // setItems(res.data.data)
            setLoading(false)

        }

        getAllTicketData()




    }, []);

    //change time formate
    function formatAMPM(date) {
        var hours = new Date(date).getHours();
        var minutes = new Date(date).getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    }
    return (
        <div>

            <>
                <div className="" style={{ minHeight: '100vh' }}>
                    <div className="Productmenu  ">
                        <div className='submain mt-4 ms-2' >
                            <Link to='/dashboard' style={{ textDecoration: 'none', color: 'black', fontSize: '1.6rem', }}>Dashboard</Link>
                            <p className=' mt-3 '> <Link to='/select_child' style={{ textDecoration: 'none', color: 'black', }}>Products</Link></p>
                            <p><Link to='/order/history' style={{ textDecoration: 'none', color: 'black', }}>Order History</Link></p>
                            <p><Link to='/child/add' style={{ textDecoration: 'none', color: 'black', }}>Children</Link></p>
                            {/* fontSize: '1.1rem', fontWeight: '600', color: 'blue' */}
                            <p><Link to='/support_system' style={{ textDecoration: 'none', color: 'black', fontSize: '1.1rem', fontWeight: '600', color: 'blue' }}>Support</Link></p>

                            {/* <p><Link to='/wishlist/me' style={{ textDecoration: 'none', color: 'black' }}>Wishlist</Link></p> */}


                            <p className=' mt-3 '> <Link to='/profile' style={{ textDecoration: 'none', color: 'black', }}>My Profile</Link></p>
                            <p onClick={() => signout()}><Link style={{ textDecoration: 'none', color: 'black', }}>Logout</Link></p>

                        </div>

                    </div>
                    {/* ----------------- */}
                    <div className="hamburger" onClick={toggleHamburger}>
                        <Hamburger isOpen={hamburgerOpen} />
                    </div>
                    {/* --------------- */}

                    <SideNavbar hamburgerOpen={hamburgerOpen} />


                    <div className="contentDivs" >
                        <h1 className='d-flex justify-content-center fst-italic' style={{
                            paddingTop: '3%',
                            paddingBottom: '1%'
                        }}>All Support Ticket</h1>





                        {/*  */}
                        <div className="checkOut d-flex flex-wrap " style={{

                            paddingBottom: '4%'
                        }} >


                            {allTicketData && allTicketData.length > 0 ? <>

                                {allTicketData && allTicketData.map((ticketItem, index) =>
                                    <>
                                        <div className="   mt-3 cartBords"  >
                                            {/* style={{ background: '#c7c9ae' }} */}
                                            <div className="row ">
                                                <div className="d-flex ">
                                                    <div className=' col-lg-8  col-md-8  col-sm-8 ms-2  '>
                                                        <h3 className={` btn text-capitalize fs-5 ${ticketItem?.status === 'open' ? 'text-success' : 'text-danger'}`} style={{ fontWeight: '700' }}>{ticketItem?.status}</h3>
                                                        <h6 className=' fst-italic'>Created At: {new Date(`${ticketItem?.createdAt}`).toDateString()} , <span>{formatAMPM(ticketItem?.createdAt)}</span> </h6>
                                                        {/* <h6 className='fst-italic'>ticket Subject :{ticketItem.subject}</h6> */}
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-sm-4    d-flex  justify-content-end align-items-center">
                                                        <div>  <h6 className='  ms-1 me-4 fst-italic'> Ticket No. :  {ticketItem.ticket_number}</h6>
                                                            <Link className=' ms-1  btn btn-primary mb-1 fst-italic' to={`/ticket/view/${ticketItem._id}`}> View Details</Link>


                                                        </div>


                                                    </div>
                                                </div>


                                            </div>






                                        </div>
                                    </>

                                )}
                            </> : loading ? (
                                <div className=' d-flex justify-content-center text-center'>
                                    <div className=' d-flex justify-content-center spinner-grow' role='status'>
                                        <span className='visually-hidden'>Loading...</span>
                                    </div>
                                </div>
                            ) : (
                                <div style={{ width: '100%', height: '14rem' }} className="w-100">
                                    <h2 style={{ marginTop: '4rem' }} className='d-flex justify-content-center ms-3 '>You are  not Create any Support Ticket till now..</h2>
                                    <div className="mt-5 mb-3 d-flex justify-content-center">
                                        <Link
                                            to='/select_child'
                                            class='btn  btn-primary   cart-btn-transform mt-3 mx-5 d-flex justify-content-center'
                                            data-abc='true'
                                        >
                                            continue shopping
                                        </Link>
                                    </div>

                                </div>

                            )}






                        </div>





                    </div>




                </div>


            </>
        </div>
    )
}

export default AllSupportTickets;