import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { isAutheticated, signout } from "../../Auth";
import Hamburger from "../../Hamburger";
import SideNavbar from "../../SideNavbar";
import ClipLoader from "react-spinners/ClipLoader";
import swal from "sweetalert";
import { Link, useNavigate } from "react-router-dom";
import { Country, State, City } from "country-state-city";
import toast from "react-hot-toast";

import "./Address.css";
const Address = () => {
  const navigate = useNavigate();
  const [hamburgerOpen, setHamburgerOpen] = useState(false);

  const toggleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen);
  };
  const token = isAutheticated();
  const [loading, setLoading] = useState(false);

  const [allAddress, setAllAddress] = useState([]);

  const [data, setData] = useState({
    full_name: "",
    mobile_number: "",
    pincode: "",
    flat_house_no_apartment: "",
    area_street_sector_village: "",
    address_line: "",
    landmark: "",
    city: "",
    state: "",
  });
  useEffect(() => {
    const getData = async () => {
      const res = await axios.get(
        `/api/client/address`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log(res.data)
      setAllAddress(res.data.data);
    };
    getData();
  }, [token]);

  // city and state
  const [stateCode, setStateCode] = useState();
  const state = State.getStatesOfCountry("IN");

  useEffect(() => {
    state.map((item) => {
      if (item.name === data.state) {
        setStateCode(item.isoCode);
        // console.log(data.state)
      }
    });
  }, [data.state]);

  const handleChange = (e) => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // if (data.full_name === '' || data.mobile_number === '' || data.pincode === '' || data.flat_house_no_apartment === '' || data.area_street_sector_village === '' || data.city === '' || data.state === ''
    //     || data.address_line) {
    //     swal({
    //         title: 'Warning',
    //         text: 'Fill all mandatory fields',
    //         icon: 'error',
    //         button: 'Close',
    //         dangerMode: true,
    //     })
    //     return
    // }

    const formData = new FormData();
    formData.append("full_name", data.full_name);
    formData.append("mobile_number", data.mobile_number);
    formData.append("pincode", data.pincode);
    formData.append("flat_house_no_apartment", data.flat_house_no_apartment);
    formData.append(
      "area_street_sector_village",
      data.area_street_sector_village
    );

    formData.append("address_line", data.address_line);

    formData.append("landmark", data.landmark);
    formData.append("city", data.city);
    formData.append("state", data.state);

    setLoading(true);
    axios
      .post(`/api/client/address`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res.data)

        const msg = res.data.message
          ? res.data.message
          : " Address Added successfully!";
        swal({
          title: "success",
          text: msg,
          icon: "success",
          button: "Ok",
        });
        setLoading(false);
        navigate("/cart", { replace: true });
      })
      .catch((err) => {
        setLoading(false);

        const msg = err.response.data.message
          ? err.response.data.message
          : "Something went wrong!";
        swal({
          title: "Warning",
          text: msg,
          icon: "error",
          button: "Retry",
          dangerMode: true,
        });
      });
  };
  const handleDefault = (e) => {
    // console.log()
    axios
      .patch(
        `/api/client/address/default/${e.target.id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        // console.log(res.data)
        toast.success("changed Default");
        navigate("/cart", { replace: true });
      });
  };

  return (
    <>
      <div className='' style={{ minHeight: "100vh" }}>
        <div className='Productmenu  '>
          <div className='submain mt-4'>
            <Link
              to='/dashboard'
              style={{
                textDecoration: "none",
                color: "black",
                fontSize: "1.6rem",
              }}>
              Dashboard
            </Link>

            {/* <p className=' mt-3 '> <Link to='/profile' style={{ textDecoration: 'none', color: 'black', }}>My Profile</Link></p> */}

            <p className=' mt-3 '>
              {" "}
              <Link
                to='/select_child'
                style={{ textDecoration: "none", color: "black" }}>
                Products
              </Link>
            </p>
            <p>
              <Link
                to='/order/history'
                style={{ textDecoration: "none", color: "black" }}>
                Order History
              </Link>
            </p>
            <p>
              <Link
                to='/child/add'
                style={{ textDecoration: "none", color: "black" }}>
                Children
              </Link>
            </p>
            <p>
              <Link
                to='/support_system'
                style={{ textDecoration: "none", color: "black" }}>
                Support
              </Link>
            </p>
            {/* <p><Link to='/wishlist/me' style={{ textDecoration: 'none', color: 'black', }}>Wishlist</Link></p> */}

            <p className=' mt-3 '>
              {" "}
              <Link
                to='/profile'
                style={{ textDecoration: "none", color: "black" }}>
                My Profile
              </Link>
            </p>
            <p onClick={() => signout()}>
              <Link style={{ textDecoration: "none", color: "black" }}>
                Logout
              </Link>
            </p>
          </div>
        </div>
        {/* ----------------- */}
        <div className='hamburger' onClick={toggleHamburger}>
          <Hamburger isOpen={hamburgerOpen} />
        </div>
        {/* --------------- */}

        <SideNavbar hamburgerOpen={hamburgerOpen} />

        <div className='contentDivs py-5'>
          <div
            className='card  address '
            style={{ background: "#F2E9E4", border: "1px solid black" }}>
            <div className='card-body d-flex flex-wrap'>
              {/* <h5 className="card-title">Card title</h5> */}
              <div className=' w-75  '>
                {/* <h6 className="card-subtitle mb-2 ">Select your delivery address</h6>
                                <p className="card-text">Address will be viewed here...bla bla bla mana ankjabk j</p> */}
                {/* ------------------ */}
                {allAddress && allAddress.length > 0 ? (
                  allAddress.map((item, index) => (
                    <div key={index} className='form-check d-flex mt-3 mb-2'>
                      <input
                        className='form-check-input me-1'
                        type='radio'
                        name='flexRadioDefault'
                        id={item._id}
                        onClick={handleDefault}
                        defaultChecked={item?.default === true ? true : false}
                      />
                      <label
                        className='form-check-label'
                        htmlFor='flexRadioDefault1'>
                        {/* James H. Green
                                                    3008 Burwell Heights RoadPineland, TX 75968 */}
                        {item.full_name}, {item.flat_house_no_apartment}{" "}
                        {item.area_street_sector_village} {item.address_line}{" "}
                        {item.landmark},{item.city} {item.state}, {item.pincode}
                      </label>
                      <Link
                        className=' ms-2 d-flex '
                        to={`/address/edit/${item._id}`}>
                        <i className='bi bi-pencil-square'></i>
                      </Link>
                    </div>
                  ))
                ) : (
                  <>
                    <div className='form-check mt-3 mb-2'>
                      <label
                        className='form-check-label'
                        htmlFor='flexRadioDefault1'>
                        No Address added till Now ..
                      </label>
                    </div>
                  </>
                )}

                <h4 className=' mt-3 mb-2'>Add New Address</h4>
                <div className='mb-3'>
                  <label htmlFor='name' className='form-label font-weight-bold'>
                    Name : *
                  </label>
                  <input
                    style={{ height: "46px" }}
                    type='name'
                    id='name'
                    className='form-control'
                    name='full_name'
                    value={data.full_name}
                    placeholder='Full Name'
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className='mb-3'>
                  <label htmlFor='email' className='form-label'>
                    Mobile Number *
                  </label>
                  <input
                    style={{ height: "46px" }}
                    type='tel'
                    id='tel'
                    name='mobile_number'
                    value={data.mobile_number}
                    className='form-control'
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className='mb-3'>
                  <label htmlFor='pin code' className='form-label'>
                    Pin Code *
                  </label>
                  <input
                    style={{ height: "46px", width: "8rem" }}
                    type='number'
                    className='form-control'
                    name='pincode'
                    value={data.pincode}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className='mb-3'>
                  <label htmlFor='name' className='form-label font-weight-bold'>
                    Flat, House no., Building, Company, Apartment *
                  </label>
                  <input
                    style={{ height: "46px" }}
                    type='text'
                    id='name'
                    className='form-control'
                    name='flat_house_no_apartment'
                    value={data.flat_house_no_apartment}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className='mb-3'>
                  <label htmlFor='name' className='form-label font-weight-bold'>
                    Area, Street, Sector, Village *
                  </label>
                  <input
                    style={{ height: "46px" }}
                    type='text'
                    className='form-control'
                    name='area_street_sector_village'
                    value={data.area_street_sector_village}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className='mb-3'>
                  <label htmlFor='name' className='form-label font-weight-bold'>
                    Address Line 4 *
                  </label>
                  <input
                    style={{ height: "46px" }}
                    type='text'
                    id='name'
                    className='form-control'
                    name='address_line'
                    placeholder='Address Line 4'
                    value={data.address_line}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className='mb-3'>
                  <label htmlFor='name' className='form-label font-weight-bold'>
                    Landmark
                  </label>
                  <input
                    style={{ height: "46px" }}
                    type='text'
                    id='name'
                    className='form-control'
                    name='landmark'
                    placeholder='Landmark'
                    value={data.landmark}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className='d-flex'>
                  <div className='mb-3 '>
                    <label
                      htmlFor='name'
                      className='form-label font-weight-bold'>
                      State *
                    </label>
                    {/* <input style={{ height: "46px" }} type="text" id="name" className="form-control" name="state"
                                            value={data.state}
                                            onChange={handleChange}
                                            required /> */}

                    <select
                      className='d-flex justify-content-center'
                      name='state'
                      style={{ height: "46px", border: "none", width: "10rem" }}
                      onChange={handleChange}
                      value={data.state}>
                      <option value='1'>--select-----</option>
                      {State.getStatesOfCountry("IN") &&
                        State.getStatesOfCountry("IN").map((item, index) => (
                          <option key={index} value={item?.name}>
                            {item?.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className='mb-3 ms-3'>
                    <label
                      htmlFor='name'
                      className='form-label font-weight-bold'>
                      Town/City *
                    </label>

                    <select
                      className='d-flex justify-content-center'
                      name='city'
                      style={{ height: "46px", border: "none", margin: "auto" }}
                      onChange={handleChange}
                      value={data.city}>
                      <option value='1'>---select---</option>
                      {City.getCitiesOfState("IN", `${stateCode}`) &&
                        City.getCitiesOfState("IN", `${stateCode}`).map(
                          (item, index) => (
                            /* {City.getCountryByCode('IN') && City.getCountryByCode('IN').map((item, index) => */
                            <option key={index} value={item?.name}>
                              {item?.name}
                            </option>
                          )
                        )}
                    </select>
                    {/* <input style={{ height: "46px" }} type="text" id="name" className="form-control" name="city"
                                            value={data.city}
                                            onChange={handleChange}
                                            required /> */}
                  </div>
                </div>

                <div className='mb-3 d-grid'>
                  <button
                    type='submit'
                    className='btn btn-dark mt-4 '
                    onClick={handleSubmit}
                    style={{ height: "46px" }}>
                    <ClipLoader loading={loading} color={"white"} size={25} />
                    {!loading && "Continue With This Address"}
                  </button>
                </div>

                {/* ---------------- */}
              </div>

              <div className=' w-25 d-flex   justify-content-end align-items-start  '>
                {/* <button style={{
                                    width: "10rem",
                                    height: '5%',

                                }}>
                                    
                                    Add Address
                                </button> */}
              </div>
            </div>
          </div>

          {/*  */}
          <div className='checkOut d-flex flex-wrap'>
            {/* checkOut */}

            {/*  */}
          </div>

          {/*  */}
        </div>
      </div>
    </>
  );
};

export default Address;
