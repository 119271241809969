


import React from 'react'
import swal from 'sweetalert';
import validator from 'validator'
import ClipLoader from "react-spinners/ClipLoader";
import axios from 'axios'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';

const ForgotPass = () => {
    const url = useParams().url

    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState('')
    const navigate = useNavigate()




    useEffect(() => {

        const getData = async () => {
            const res = await axios.get(
                `/api/client/school/byurl/${url}`,

                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            localStorage.setItem('schoolID', res.data.data._id)
        }
        getData()


    }, [url]);

    // ----------------------------//
    const [emailError, setEmailError] = useState('')
    const validateEmail = (e) => {
        var emails = e.target.value

        if (validator.isEmail(emails)) {
            // setEmailError(' it is Valid Email :)')
            setEmailError('')

            setEmail(emails)
        } else {
            setEmailError('Enter valid Email!')
        }

    }
    ///
    const handleSubmit = (e) => {
        e.preventDefault()

        if (email.trim() === '') {
            swal({
                title: 'Warning',
                text: 'Fill email field',
                icon: 'error',
                button: 'Close',
                dangerMode: true,
            })
            return
        }


        const formData = new FormData()
        formData.append('school_id', localStorage.getItem('schoolID'))
        formData.append('email', email)

        setLoading(true)
        axios
            .post(`/api/auth/parent/forgotpassword`, formData, {
                headers: {
                    'Access-Control-Allow-Origin': '*',

                },
            })
            .then((res) => {
                // console.log(res.data)


                const msg = res.data.message ? res.data.message : 'Message send Register Email successfully!';
                swal({
                    title: 'success',
                    text: msg,
                    icon: 'success',
                    button: 'Return',
                })
                setLoading(false)
                navigate('/:url/login', { replace: true })
            })
            .catch((err) => {
                setLoading(false)

                const msg = err.response.data.message ? err.response.data.message : 'Something went wrong!';
                swal({
                    title: 'Warning',
                    text: msg,
                    icon: 'error',
                    button: 'Retry',
                    dangerMode: true,
                })
            })
    }
    return (
        <>
            <div className='' >
                {localStorage.getItem('schoolBanner') && <img src={`${localStorage.getItem('schoolBanner')}`} alt="" width='100%' height='100%' srcset="" />}

            </div>
            <div className="container " style={{ marginBottom: '5rem' }}>
                <div className="row align-items-center justify-content-center
         ">
                    <div className="col-16 col-md-10 col-lg-5 " style={{ marginTop: "6rem" }}>

                        <div className="card w-100 shadow-sm">
                            <div className="card-body maincont" style={{ background: '#F2E9E4' }}>
                                <div className="mb-4 d-flex justify-content-center">
                                    <div className="d-flex">
                                        <h3>Password assistance</h3>

                                    </div>




                                </div>
                                <form>


                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label font-weight-bold">Email :</label>
                                        <input style={{ height: "46px" }} type="email" id="email" className="form-control" name="email" placeholder=" Email"
                                            onChange={(e) => validateEmail(e)}
                                            required />
                                        <br />
                                        <span style={{
                                            fontWeight: 'bold',
                                            color: 'red',
                                        }}>
                                            {emailError}
                                        </span>
                                    </div>

                                    <label class="form-check-label" for="flexCheckDefault">
                                        An email with steps to set your new password will be sent to your registered email.
                                    </label>


                                    <div className="mb-3 d-grid">
                                        <button disabled={!email} type="submit" className="btn btn-dark mt-4" onClick={handleSubmit} >
                                            <ClipLoader loading={loading} color={'white'} size={25} />
                                            {!loading && "Continue"}


                                        </button>



                                    </div>
                                </form>
                                {/* <Link to={navigate(-1)} ><small>Go back to Login</small></Link> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgotPass