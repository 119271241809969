import React, { useState } from "react";
import Hamburger from "../../Hamburger";
import SideNavbar from "../../SideNavbar";
import productImage from "../../AllImage/Rectangle6.png";
import "./OrderConfirmation.css";
import { Link, useParams } from "react-router-dom";
import { isAutheticated, signout } from "../../Auth";
import { useEffect } from "react";
import axios from "axios";
import productPlaceholder from "../../AllImage/productPlaceholder.jpg";

const OrderConfirmation = () => {
  const [hamburgerOpen, setHamburgerOpen] = useState(false);

  const toggleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen);
  };
  const [loading, setLoading] = useState(true);

  const order_id = useParams().id;
  const token = isAutheticated();
  const [orderId, setOrdeeId] = useState();
  const [orderAmount, setOrderAmount] = useState();
  const [orderPlacedDateTime, setOrderPlacedDateTime] = useState();

  const [items, setItems] = useState([]);

  useEffect(() => {
    const getOrderConfirmationdData = async () => {
      const res = await axios.get(
        `/api/client/order/success/${order_id}`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log(res.data.data)
      setOrderAmount(res.data.data.total_amount);
      setOrdeeId(res.data.data.order_id);
      setOrderPlacedDateTime(res.data.data.placed_on);

      setItems(res.data.data.items);
    };

    getOrderConfirmationdData();
  }, [order_id]);

  //change time formate
  function formatAMPM(date) {
    var hours = new Date(date).getHours();
    var minutes = new Date(date).getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  return (
    <div>
      <>
        <div className=' ' style={{ minHeight: "100vh" }}>
          <div className='Productmenu  '>
            <div className='submain mt-4'>
              <Link
                to='/dashboard'
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "1.8rem",
                }}>
                Dashboard
              </Link>
              <p className=' mt-3 '>
                {" "}
                <Link
                  to='/select_child'
                  style={{ textDecoration: "none", color: "black" }}>
                  Products
                </Link>
              </p>
              <p>
                <Link
                  to='/order/history'
                  style={{ textDecoration: "none", color: "black" }}>
                  Order History
                </Link>
              </p>
              <p>
                <Link
                  to='/child/add'
                  style={{ textDecoration: "none", color: "black" }}>
                  Children
                </Link>
              </p>
              <p>
                <Link
                  to='/support_system'
                  style={{ textDecoration: "none", color: "black" }}>
                  Support
                </Link>
              </p>
              {/* <p><Link to='/wishlist/me' style={{ textDecoration: 'none', color: 'black', }}>Wishlist</Link></p> */}

              <p className=' mt-3 '>
                {" "}
                <Link
                  to='/profile'
                  style={{ textDecoration: "none", color: "black" }}>
                  My Profile
                </Link>
              </p>
              <p onClick={() => signout()}>
                <Link style={{ textDecoration: "none", color: "black" }}>
                  Logout
                </Link>
              </p>
            </div>
          </div>
          {/* ----------------- */}
          <div className='hamburger' onClick={toggleHamburger}>
            <Hamburger isOpen={hamburgerOpen} />
          </div>
          {/* --------------- */}

          <SideNavbar hamburgerOpen={hamburgerOpen} />

          <div className='contentDivs'>
            <h1
              className='d-flex justify-content-center'
              style={{
                paddingTop: "4%",
                // marginBottom: '2%'
              }}>
              Congratulations{" "}
            </h1>

            <h3 className='d-flex justify-content-center'>
              Your order is successful
            </h3>

            {/*  */}
            <div
              className='checkOutt d-flex flex-wrap'
              style={{
                paddingBottom: "4%",
              }}>
              <div className='   mt-4 cartBordss'>
                {/* <h3 className=' ms-3 mt-3'>, your order has been placed succesfully</h3> */}
                <h5 className='ms-3 mt-1 fst-italic'> Order ID : {orderId}</h5>
                <h5 className='ms-3 fst-italic'>
                  Order Value : ₹{orderAmount}
                </h5>

                <h5 className=' ms-3 mb-3 fst-italic'>
                  Ordered On:{" "}
                  {new Date(`${orderPlacedDateTime}`).toDateString()} ,{" "}
                  <span>{formatAMPM(orderPlacedDateTime)}</span>{" "}
                </h5>
                {/* -------------------- */}
                {items &&
                  items.map((orderItem, index) => (
                    <div
                      class='card productOrder mt-2 mb-3 p-2  '
                      style={{
                        borderTop: "1px solid black",
                        borderBottom: "1px solid black",
                      }}
                      key={index}>
                      <div class='row g-0 '>
                        <div class='col-md-3 '>
                          {/* {orderItem.product?.images?.length > 0 && (
                            <img
                              src={orderItem.product?.images[0]?.url}
                              class=' responsive p-1'
                              alt='...'
                              width={100}
                            />
                          )} */}
                          <img
                            src={
                              orderItem.product?.images?.length > 0
                                ? orderItem.product?.images[0]?.url
                                : productPlaceholder
                            }
                            alt={orderItem?.product?.name}
                            className='responsive p-1'
                            width={100}
                          />
                        </div>

                        <div class='col-md-9 '>
                          <div class='card-body '>
                            <div className='productName d-flex  '>
                              <h5 class='d-flex justify-content-Start'>
                                {orderItem.product.name}
                              </h5>
                            </div>
                            <p className='m-0 p-0'>
                              Enrollment No. :{" "}
                              {orderItem?.enrollment_number || "-"}
                            </p>
                            <p className='m-0 p-0'>
                              Size: {orderItem.variant?.size?.size}
                            </p>
                            <p className='m-0 p-0'>
                              Quantity: {orderItem.quantity}
                            </p>

                            <p className='m-0 p-0'>Price: ₹{orderItem.price}</p>

                            {orderItem?.igst && (
                              <p className='m-0 p-0'>
                                <span>IGST:</span> {orderItem.igst}%
                              </p>
                            )}
                            {orderItem?.cgst && (
                              <small className='me-3'>
                                CGST: {orderItem.cgst}%
                              </small>
                            )}
                            {orderItem?.sgst && (
                              <small className=''>
                                SGST: {orderItem.sgst}%
                              </small>
                            )}
                            <hr />
                            <h5 className='mt-2'>
                              {" "}
                              Total Price: ₹{orderItem.amount}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default OrderConfirmation;
