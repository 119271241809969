import { Link, useNavigate } from "react-router-dom";


export const isAutheticated = () => {
    if (typeof window == "undefined") {
        return true;
    }
    if (localStorage.getItem("reinventToken")) {

        return (localStorage.getItem("reinventToken"));
        // console.log(localStorage.getItem("reinventToken"))
    } else {
        return false;
    }
};

export const signout = () => {


    localStorage.removeItem("reinventToken");
    const Surl = localStorage.getItem('SchoolUrl')

    if (Surl) return window.location.replace(`/${Surl}/login`)
    window.location.replace(`/:url/login`)


};
