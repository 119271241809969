import React, { useState } from "react";
import Hamburger from "../../Hamburger";
import SideNavbar from "../../SideNavbar";
import "./Cart.css";
import axios from "axios";
import { isAutheticated, signout } from "../../Auth";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
// import CartItems from "./CartItems";
import CartItemsV2 from "./CartItemsV2";
import productRemove from "../../AllImage/remove.png";
import toast from "react-hot-toast";

const Cart = () => {
  const navigate = useNavigate();

  const token = isAutheticated();
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const [address, setAddress] = useState({ full_name: "" });
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);

  const [cartItems, setCartItems] = useState([]);

  const [Totalcart_price, setTotalCart_price] = useState();
  const [TotalTaxPrice, setTotalTaxPrice] = useState();
  const [TotalAmount, setTotalAmount] = useState();

  const toggleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen);
  };
  // const [data, setData] = useState({
  //     price: item_data?.master_price,
  //     variant: '',
  //     product: item_data?._id,
  //     quantity: option === 'group' ? 1 : item_data?.quantity || 1,
  //     option_id,
  // })
  // function setVariant(id, price) {
  //     setData((prev) => ({ ...prev, variant: id, price }))
  // }
  useEffect(() => {
    const getAddress = async () => {
      const res = await axios.get(`/api/client/address`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      //   console.log(res.data.data);
      if (res.data.data) {
        res.data.data.map((item) => {
          if (item.default === true) {
            setAddress(item);
          }
        });
      }
    };
    getAddress();

    const getData = async () => {
      setLoading(true);
      try {
        const res = await axios.get(`/api/client/cart/v2`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCartItems(res.data.cart_items);
        setTotalCart_price(res.data.cart_price);
        setTotalTaxPrice(res.data?.tax_amount);
        setTotalAmount(res.data?.total_amount);
        // setChildData(res.data.data)
        // setGroup(res.data)
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    };
    getData();
  }, [token, success]);
  const handleChangeAddress = () => {
    navigate("/address/new");
  };
  // console.log(address)
  function deleteSingleItem(id) {
    // e.preventDefault();
    axios
      .delete(`/api/client/cart/item/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        toast.success(res?.data?.message || "Item Deleted");
        setSuccess((prev) => !prev);
      })
      .catch((err) => {
        const msg = err.response.data.message
          ? err.response.data.message
          : "Failed to delete";
        toast.error(msg);
      });
  }
  return (
    <>
      <div className='' style={{ minHeight: "100vh" }}>
        <div className='Productmenu  '>
          <div className='submain mt-4'>
            <Link
              to='/dashboard'
              style={{
                textDecoration: "none",
                color: "black",
                fontSize: "1.6rem",
              }}>
              Dashboard
            </Link>

            {/* <p className=' mt-3 '> <Link to='/profile' style={{ textDecoration: 'none', color: 'black', }}>My Profile</Link></p> */}

            <p className=' mt-3 '>
              {" "}
              <Link
                to='/select_child'
                style={{ textDecoration: "none", color: "black" }}>
                Products
              </Link>
            </p>
            <p>
              <Link
                to='/order/history'
                style={{ textDecoration: "none", color: "black" }}>
                Order History
              </Link>
            </p>
            <p>
              <Link
                to='/child/add'
                style={{ textDecoration: "none", color: "black" }}>
                Children
              </Link>
            </p>
            <p>
              <Link
                to='/support_system'
                style={{ textDecoration: "none", color: "black" }}>
                Support
              </Link>
            </p>
            {/* <p><Link to='/wishlist/me' style={{ textDecoration: 'none', color: 'black', }}>Wishlist</Link></p> */}

            <p className=' mt-3 '>
              {" "}
              <Link
                to='/profile'
                style={{ textDecoration: "none", color: "black" }}>
                My Profile
              </Link>
            </p>
            <p onClick={() => signout()}>
              <Link style={{ textDecoration: "none", color: "black" }}>
                Logout
              </Link>
            </p>
          </div>
        </div>
        {/* ----------------- */}
        <div className='hamburger' onClick={toggleHamburger}>
          <Hamburger isOpen={hamburgerOpen} />
        </div>
        {/* --------------- */}

        <SideNavbar hamburgerOpen={hamburgerOpen} />

        <div className='contentDivss pb-5'>
          <h1
            className='d-flex justify-content-center'
            style={{
              paddingTop: "3%",
              paddingBottom: "3%",
            }}>
            Cart
          </h1>
          {/* <div class="card mb-3 bg-danger" style={{ maxWidth: '70%' }}>
                        <div class="row g-0" >

                            <div class="col-md-10">
                                <div class="card-body">
                                    <h5 class="card-title">Card title</h5>
                                    <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                </div>
                            </div>
                        </div>
                    </div> */}

          {cartItems && cartItems.length > 0 ? (
            <>
              <div class='card  address' style={{ background: "#F2E9E4" }}>
                {address?.full_name !== "" ? (
                  <div class='card-body d-flex flex-wrap  '>
                    {/* <h5 class="card-title">Card title</h5> */}

                    {/* <div className="v"> */}

                    <div className=' w-75  '>
                      {/* Deliver to: */}
                      <h6 class='card-subtitle mb-2 '>
                        {address && address.full_name},{" "}
                        {address && address.mobile_number}
                      </h6>

                      <p class='card-text'>
                        {address && address.flat_house_no_apartment}{" "}
                        {address && address.area_street_sector_village}{" "}
                        {address && address.address_line}
                        {address && address.landmark}, {address && address.city}{" "}
                        {address && address.state}, {address && address.pincode}
                      </p>
                    </div>

                    <div className=' w-25 d-flex   justify-content-center align-items-center  '>
                      <button
                        onClick={handleChangeAddress}
                        style={{
                          width: "12rem",
                          height: "83%",
                        }}>
                        Change Address
                      </button>
                    </div>
                  </div>
                ) : (
                  <div class='card-body d-flex flex-wrap  '>
                    <div className='w-75'>
                      {" "}
                      <p>No Address added till Now ..</p>
                    </div>
                    <div className=' w-25 d-flex   justify-content-center align-items-center  '>
                      <button
                        onClick={handleChangeAddress}
                        className=''
                        style={{
                          width: "12rem",
                          height: "100%",
                        }}>
                        Add Address
                      </button>
                    </div>
                  </div>
                )}
              </div>

              {/*  */}
              <div className='checkOut d-flex flex-wrap'>
                <div className='   mt-4 cartBord'>
                  <h3 className='ms-4'>Items</h3>
                  {/* -------------------- */}
                  {cartItems &&
                    cartItems.map(
                      (cartItem, index) => (
                        <div className='d-flex flex-wrap' key={index}>
                          <div className=' d-flex   justify-content-center align-items-center ms-2  '>
                            <img
                              class='myDIV '
                              style={{ maxHeight: "40px" }}
                              onClick={() => deleteSingleItem(cartItem._id)}
                              src={productRemove}
                              alt=''
                              width='50'
                              srcset=''
                            />
                          </div>
                          {/* <div class="hide ms-1">Delete</div> */}
                          {/* <CartItems
                              cartItems={cartItem}
                              setSuccess={setSuccess}
                            /> */}
                          <CartItemsV2
                            item={cartItem}
                            setSuccess={setSuccess}
                          />
                        </div>
                      )
                      // cartItems && cartItems.product.map((product, index) =>
                    )}
                </div>
                {/* checkOut */}

                <div
                  class='card priceCard'
                  style={{
                    width: "30rem",
                    maxHeight: "21rem",
                    background: "#C9ADA7",
                  }}>
                  <div class='card-body'>
                    <h4 class='card-title'>Price Details</h4>
                    <div className='row '>
                      <div className='col-lg-6 col-md-6 col-6 mt-3'>
                        <span className='ms-4'> Total Cart Price :</span>
                      </div>
                      <div className='col-lg-6 col-md-6 col-6 mt-3 '>
                        <span className='ms-2'>
                          {" "}
                          ₹{Totalcart_price && Totalcart_price}
                        </span>
                      </div>
                    </div>
                    <div className='row '>
                      <div className='col-lg-6 col-md-6 col-6 mt-3'>
                        <span className='ms-4'> Total Tax Amount :</span>
                      </div>
                      <div className='col-lg-6 col-md-6 col-6 mt-3 '>
                        <span className='ms-2'>
                          {" "}
                          ₹{TotalTaxPrice && TotalTaxPrice}
                        </span>
                      </div>
                    </div>
                    <hr />
                    <div className='row '>
                      <div className='col-lg-6 col-md-6 col-6 mt-1 mb-2'>
                        <strong className='ms-4'>Total Amount :</strong>
                      </div>
                      <div className='col-lg-6 col-md-6 col-6 mt-1 mb-2'>
                        <strong className='ms-2'>
                          {" "}
                          ₹{TotalAmount && TotalAmount}
                        </strong>
                      </div>
                    </div>

                    <div className='mb-2 d-grid'>
                      <Link
                        to='/cart/checkout'
                        class='btn btn-dark cart-btn-transform m-3'
                        data-abc='true'>
                        Check Out
                      </Link>
                      <Link
                        to='/select_child'
                        class='btn btn-primary mx-3 mt-1'
                        data-abc='true'>
                        Continue Shopping
                      </Link>
                    </div>
                  </div>
                </div>

                {/*  */}
              </div>

              {/* cart p */}
            </>
          ) : loading ? (
            <div className='text-center'>
              <div className='spinner-grow' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </div>
            </div>
          ) : (
            <>
              {/* <div style={{ height: '15rem' }}><h1 className='d-flex justify-content-center align-items-center '>No ITem Added till Now..</h1>
                        
                        </div> */}
              <div class='container-fluid  mt-100'>
                <div class='row'>
                  <div class='col-md-12'>
                    <div class='card'>
                      {/* <div class="card-header">
                                                <h5>Cart</h5>
                                            </div> */}
                      <div class='card-body cart'>
                        <div class='col-sm-12 empty-cart-cls text-center'>
                          <img
                            src='https://i.imgur.com/dCdflKN.png'
                            width='130'
                            height='130'
                            class='img-fluid mb-4 mr-3'
                            alt=''
                          />
                          <h3>
                            <strong>Your Cart is Empty</strong>
                          </h3>
                          <h4>Add something to make me happy :)</h4>
                          <Link
                            to='/select_child'
                            class='btn btn-primary cart-btn-transform m-3'
                            data-abc='true'>
                            continue shopping
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {/*  */}
        </div>
      </div>
    </>
  );
};

export default Cart;
