import React, { useState } from "react";
import Hamburger from "../../Hamburger";
import SideNavbar from "../../SideNavbar";
import productImage from "../../AllImage/Rectangle6.png";
import "./OrderDetails.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { isAutheticated, signout } from "../../Auth";
import axios from "axios";
import { useEffect } from "react";
import productPlaceholder from "../../AllImage/productPlaceholder.jpg";

const OrderDetails = () => {
  const order_Id = useParams().id;
  // console.log(order_Id)

  const [hamburgerOpen, setHamburgerOpen] = useState(false);

  const toggleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen);
  };

  const [loading, setLoading] = useState(true);

  const token = isAutheticated();
  const [orderId, setOrdeeId] = useState();
  const [orderAmount, setOrderAmount] = useState();
  const [orderPlacedDateTime, setOrderPlacedDateTime] = useState();
  const [PaymentStatus, setPaymentStatus] = useState();
  const [OrderStatus, setOrderStatus] = useState();
  const [shipping, setShipping] = useState("");

  const [items, setItems] = useState([]);

  useEffect(() => {
    const getOrderData = async () => {
      setLoading(true);
      const res = await axios.get(
        `/api/client/order/${order_Id}`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log(res.data.data);
      setOrderAmount(res.data.data.total_amount);
      setShipping(res.data?.data?.shipping_charge);
      setOrdeeId(res.data.data.order_id);
      setOrderPlacedDateTime(
        res.data.data?.placed_on || res?.data?.data?.createdAt
      );
      setPaymentStatus(res.data.data.payment);
      setOrderStatus(res.data.data.status);
      setItems(res.data.data.items);
      setLoading(false);
    };

    getOrderData();
  }, []);

  //change time formate
  function formatAMPM(date) {
    var hours = new Date(date).getHours();
    var minutes = new Date(date).getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }
  return (
    <div>
      <>
        <div className='' style={{ minHeight: "100vh" }}>
          <div className='Productmenu  '>
            <div className='submain mt-4'>
              <Link
                to='/dashboard'
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "1.8rem",
                }}>
                Dashboard
              </Link>
              <p className=' mt-3 '>
                {" "}
                <Link
                  to='/select_child'
                  style={{ textDecoration: "none", color: "black" }}>
                  Products
                </Link>
              </p>
              <p>
                <Link
                  to='/order/history'
                  style={{ textDecoration: "none", color: "black" }}>
                  Order History
                </Link>
              </p>
              <p>
                <Link
                  to='/child/add'
                  style={{ textDecoration: "none", color: "black" }}>
                  Children
                </Link>
              </p>

              <p>
                <Link
                  to='/support_system'
                  style={{ textDecoration: "none", color: "black" }}>
                  Support
                </Link>
              </p>
              {/* <p><Link to='/wishlist/me' style={{ textDecoration: 'none', color: 'black', }}>Wishlist</Link></p> */}

              <p className=' mt-3 '>
                {" "}
                <Link
                  to='/profile'
                  style={{ textDecoration: "none", color: "black" }}>
                  My Profile
                </Link>
              </p>
              <p onClick={() => signout()}>
                <Link style={{ textDecoration: "none", color: "black" }}>
                  Logout
                </Link>
              </p>
            </div>
          </div>
          {/* ----------------- */}
          <div className='hamburger' onClick={toggleHamburger}>
            <Hamburger isOpen={hamburgerOpen} />
          </div>
          {/* --------------- */}

          <SideNavbar hamburgerOpen={hamburgerOpen} />

          <div className='contentDivs'>
            <h1
              className='d-flex justify-content-center'
              style={{
                paddingTop: "3%",
                // marginBottom: '2%'
              }}>
              Order Details
            </h1>

            {/*  */}
            <div
              className='checkOut pb-5 d-flex flex-wrap'
              style={
                {
                  // paddingBottom: '4%'
                }
              }>
              {items && items.length > 0 ? (
                <>
                  <div className='   mt-5 cartBords'>
                    <h3 className=' ms-3 mt-3 text-capitalize'>
                      {PaymentStatus === "success"
                        ? OrderStatus
                        : "Payment Failled !!"}
                    </h3>
                    <h6 className='ms-3 mt-1 fst-italic'>
                      Order ID : {orderId}
                    </h6>
                    <h6 className='ms-3 fst-italic'>
                      Shipping Charge : ₹{shipping}
                    </h6>
                    <h6 className='ms-3 fst-italic'>
                      Order Value : ₹{orderAmount}
                    </h6>
                    <h6 className=' ms-3 mb-3 fst-italic'>
                      Ordered On:{" "}
                      {new Date(`${orderPlacedDateTime}`).toDateString()} ,{" "}
                      <span>{formatAMPM(orderPlacedDateTime)}</span>{" "}
                    </h6>
                    {/* -------------------- */}
                    {items &&
                      items.map((productItem, index) => (
                        <>
                          <div
                            class='card productOrder mt-2 mb-3 p-2 '
                            style={{ borderTop: "1px solid black" }}
                            key={index}>
                            <div class='row g-0 '>
                              <div class='col-md-3 '>
                                {/* {productItem.product.images && (
                                    <img
                                      src={productItem.product.images[0].url}
                                      class=' responsive p-1'
                                      alt='...'
                                      width={100}
                                    />
                                  )} */}
                                <img
                                  src={
                                    productItem.product?.images?.length > 0
                                      ? productItem.product?.images[0]?.url
                                      : productPlaceholder
                                  }
                                  alt={productItem?.product?.name}
                                  className='responsive p-1'
                                  width={100}
                                />
                              </div>

                              <div class='col-md-9 '>
                                <div class='card-body '>
                                  <div className='productName d-flex  '>
                                    <h5 class='d-flex justify-content-Start'>
                                      {productItem.product.name}
                                    </h5>
                                  </div>
                                  {/* <span>Product description and school name will be here</span> */}
                                  <p className='m-0 p-0'>
                                    Enrollment No.:{" "}
                                    {productItem?.enrollment_number || "-"}
                                  </p>
                                  <p className='m-0 p-0'>
                                    Size: {productItem.variant?.size?.size}
                                  </p>
                                  <p className='m-0 p-0'>
                                    Quantity: {productItem.quantity}
                                  </p>

                                  <p className='m-0 p-0'>
                                    Price: ₹{productItem.price}
                                  </p>
                                  {productItem?.igst && (
                                    <p>
                                      <span>IGST:</span> {productItem.igst}%
                                    </p>
                                  )}
                                  {productItem?.cgst && (
                                    <small className='me-3'>
                                      CGST: {productItem.cgst}%
                                    </small>
                                  )}
                                  {productItem?.sgst && (
                                    <small className=''>
                                      SGST: {productItem.sgst}%
                                    </small>
                                  )}

                                  <hr />
                                  <h5 className=''>
                                    {" "}
                                    Total Price: ₹{productItem.amount}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                  </div>
                </>
              ) : (
                <>
                  <div
                    className=' d-flex justify-content-center text-center'
                    style={{ marginLeft: "10rem" }}>
                    <div
                      className=' d-flex justify-content-center spinner-grow'
                      role='status'>
                      <span className='visually-hidden'>Loading...</span>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default OrderDetails;
