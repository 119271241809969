import React from 'react'
import { Link } from 'react-router-dom'
import { signout } from './Auth'

const SideNavbar = ({ hamburgerOpen }) => {
    return (
        <><div className='ppp ' style={{ display: `${hamburgerOpen ? 'inline-block' : 'none'}`, }} >
            <div >
                <Link className='d-flex justify-content-center pt-3 ' to='/dashboard' style={{ textDecoration: 'none', color: 'black', fontSize: '1.6rem', color: 'blue' }}>Dashboard</Link>

                <p className='d-flex justify-content-center mt-3 '> <Link to='/select_child' style={{ textDecoration: 'none', color: 'black', }}>Products</Link></p>
                <p className="d-flex justify-content-center"><Link to='/order/history' style={{ textDecoration: 'none', color: 'black', }}>Order History</Link></p>
                <p className="d-flex justify-content-center"><Link to='/child/add' style={{ textDecoration: 'none', color: 'black', }}>Children</Link></p>




                <p className="d-flex justify-content-center"><Link to='/support_system' style={{ textDecoration: 'none', color: 'black', }}>Support</Link></p>
                <p className='d-flex justify-content-center mt-3 '> <Link to='/profile' style={{ textDecoration: 'none', color: 'black', }}>My Profile</Link></p>
                {/* <p className="d-flex justify-content-center"><Link to='/wishlist/me' style={{ textDecoration: 'none', color: 'black', }}>Wishlist</Link></p> */}

                <p className="d-flex justify-content-center" onClick={() => signout()}><Link style={{ textDecoration: 'none', color: 'black', }}>logOut</Link></p>

            </div>
        </div>

            <style jsx>{`
.ppp{
 display: none;
}
 @media (max-width: 767px){
.ppp {
background-color: rgb(241, 241, 231);
    height: auto;
    width: 100vw;
    margin-top:0px;
   }
}

               
                
                
            `}</style>




        </>
    )
}

export default SideNavbar