import React, { useState } from "react";
import "./Product.css";
import productImage from "../../AllImage/Rectangle6.png";
import Hamburger from "../../Hamburger";
import SideNavbar from "../../SideNavbar";
import { Link, useParams } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import { isAutheticated, signout } from "../../Auth";
import ProductItem from "./ProductItem";
import Bundle from "./Bundle.js";
const Product = () => {
  const [hamburgerOpen, setHamburgerOpen] = useState(false);

  const toggleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen);
  };
  const [loading, setLoading] = useState(true);

  const child_id = useParams().id;
  const token = isAutheticated();
  const [childPersonalData, setChildPersonalData] = useState([]);
  const [group, setGroup] = useState([]);
  const [childData, setChildData] = useState([]);
  const [schoolName, setSchoolName] = useState();
  const [WishlistItems, setWishlistItems] = useState([]);
  const [success, setSuccess] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    const getChildData = async () => {
      const res = await axios.get(
        `/api/client/child/populated/${child_id}`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log(res.data.data)
      setChildPersonalData(res.data.data);
      setSuggestions(res?.data?.suggestion || []);
    };

    const getData = async () => {
      setLoading(true);
      const res = await axios.get(
        `/api/client/school/products/v2/${child_id}`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // console.log(res.data)
      setSchoolName(res.data.school_name);
      setChildData(res.data.data);
      setGroup(res.data);
      setLoading(false);
    };
    getChildData();
    getData();
  }, [child_id]);

  // useEffect(() => {
  //   const getWishlistItems = async () => {
  //     const res = await axios.get(
  //       `/api/client/wishlist/productsids`,

  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     // console.log(res.data.data)
  //     setWishlistItems(res.data.data);
  //   };

  //   getWishlistItems();
  // }, [success]);
  const [allCartItems, setAllCartItems] = useState([]);
  const [succ, setSucc] = useState(false);

  // async function getcartItems() {
  //   const res = await axios.get(`/api/client/cart/childcartitems/${child_id}`, {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   });
  //   // console.log(res.data.data)
  //   setAllCartItems([...res.data?.data]);
  // }
  // useEffect(() => {
  //   getcartItems();
  // }, [success]);

  return (
    <>
      <div className='product'>
        {localStorage.getItem("schoolBanner") && (
          <img
            src={`${localStorage.getItem("schoolBanner")}`}
            alt=''
            width='100%'
            height='100%'
            srcset=''
          />
        )}
      </div>

      <div className='mainproducts ' style={{ minHeight: "100vh" }}>
        <div className='Productmenu  '>
          <div className='submain mt-4 ms-2'>
            <Link
              to='/dashboard'
              style={{
                textDecoration: "none",
                color: "black",
                fontSize: "1.7rem",
              }}>
              Dashboard
            </Link>
            <p className=' mt-3 '>
              {" "}
              <Link
                to='/select_child'
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "1.1rem",
                  fontWeight: "600",
                  color: "blue",
                }}>
                Products
              </Link>
            </p>

            {/* <p className=' mt-3 '> <Link to='/profile' style={{ textDecoration: 'none', color: 'black', }}>My Profile</Link></p> */}

            <p>
              <Link
                to='/order/history'
                style={{ textDecoration: "none", color: "black" }}>
                Order History
              </Link>
            </p>
            <p>
              <Link
                to='/child/add'
                style={{ textDecoration: "none", color: "black" }}>
                Children
              </Link>
            </p>
            <p>
              <Link
                to='/support_system'
                style={{ textDecoration: "none", color: "black" }}>
                Support
              </Link>
            </p>
            {/* <p><Link to='/wishlist/me' style={{ textDecoration: 'none', color: 'black', }}>Wishlist</Link></p> */}

            <p className=' mt-3 '>
              {" "}
              <Link
                to='/profile'
                style={{ textDecoration: "none", color: "black" }}>
                My Profile
              </Link>
            </p>
            <p onClick={() => signout()}>
              <Link style={{ textDecoration: "none", color: "black" }}>
                Logout
              </Link>
            </p>
          </div>
        </div>
        {/* ----------------- */}
        <div className='hamburger' onClick={toggleHamburger}>
          <Hamburger isOpen={hamburgerOpen} />
        </div>
        {/* --------------- */}

        <SideNavbar hamburgerOpen={hamburgerOpen} />

        <div className='cardContentss pb-5'>
          <div
            className='d-flex justify-content-center pt-3 '
            style={{
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "48px",
              lineHeight: "56px",
            }}>
            {childPersonalData && childPersonalData.name}
          </div>
          <div className='d-flex justify-content-center mt-0'>
            <strong>
              {childPersonalData && childPersonalData.gender} ,{" "}
              {childPersonalData.grade && childPersonalData.grade?.name} ,{" "}
              {childPersonalData.house && childPersonalData.house.name}
            </strong>
          </div>
          {/* ---------------------------------------------------------------- */}

          {schoolName && (
            <h5 className='d-flex justify-content-center mt-3 font-italic'>
              [ {schoolName} ]
            </h5>
          )}

          {/*Suggestions starts*/}
          {suggestions?.length > 0 && (
            <div>
              <div style={{ maxWidth: "500px" }} className='mx-auto'>
                <p className='form-label fw-bold text-center'>Suggestions</p>
                <table className='table table-bordered table-sm table-success text-center'>
                  <thead>
                    <tr>
                      <th>Uniform</th>
                      <th>Size</th>
                    </tr>
                  </thead>
                  <tbody>
                    {suggestions?.map((u, idx) => (
                      <tr key={idx}>
                        <td>{u?.uniform}</td>
                        <td>{u?.size}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {/*Suggestions ends*/}

          {childData && childData.length > 0 ? (
            <>
              {childData &&
                childData.map((item, index) => (
                  <div key={index}>
                    <h2 className='d-flex justify-content-center mt-3'>
                      {item.name}
                    </h2>

                    {group.option === "bundle" ? (
                      <>
                        <Bundle
                          products={item.products}
                          option_id={item._id}
                          childId={childPersonalData._id}
                        />
                        {/* {item.products.map((item2, index) => (
                          <ProductItem
                            key={index}
                            item_data={item2}
                            option={group.option}
                            option_id={item._id}
                            bundleQuantity={item2.quantity}
                            WishlistItems={WishlistItems}
                            setSuccess={setSuccess}
                            allCartItems={allCartItems}
                            getcartItems={getcartItems}
                          />
                        ))} */}
                      </>
                    ) : (
                      <>
                        {item.products.map((item2, index) => (
                          <ProductItem
                            key={index}
                            item_data={item2}
                            option={group.option}
                            option_id={item._id}
                            WishlistItems={WishlistItems}
                            childId={childPersonalData._id}
                            setSuccess={setSuccess}
                            allCartItems={allCartItems}
                            // getcartItems={getcartItems}
                          />
                        ))}
                      </>
                    )}
                  </div>
                ))}
            </>
          ) : loading ? (
            <div className='text-center'>
              <div className='spinner-grow' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </div>
            </div>
          ) : (
            <div style={{ width: "100%", height: "8rem" }} className='w-100'>
              <h2
                style={{ marginTop: "5rem" }}
                className='d-flex justify-content-center '>
                {" "}
                No product for this child..
              </h2>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default Product;
