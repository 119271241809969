
import React, { useState } from 'react'
import Hamburger from '../../Hamburger'
import SideNavbar from '../../SideNavbar'

import './Child.css'
import axios from 'axios'
import { isAutheticated, signout } from '../../Auth'
import ClipLoader from "react-spinners/ClipLoader";

import { useEffect } from 'react'
import swal from 'sweetalert'
import { Link, useNavigate, useParams } from 'react-router-dom'

const EditChild = () => {
    const child = useParams()
    const navigate = useNavigate();
    const [hamburgerOpen, setHamburgerOpen] = useState(false);
    const token = isAutheticated();
    const [loading, setLoading] = useState(false)
    const [totalChild, setTotalChild] = useState([])

    const [totalGrades, setTotalGrades] = useState([])
    const [totalHouse, setTotalHouse] = useState([])
    const [schoolName, setSchoolName] = useState()

    const [data, setData] = useState({
        name: '',
        gender: '',
        house: '',
        grade: ''

    })

    const toggleHamburger = () => {
        setHamburgerOpen(!hamburgerOpen)
    }
    useEffect(() => {
        const getSingleChild = async () => {
            const resp = await axios.get(`/api/client/child/${child.id}`,

                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            console.log(resp.data.data)
            setData((prev) => ({
                ...prev,
                name: resp.data.data.name,
                gender: resp.data.data.gender,
                grade: resp.data.data.grade,

                house: resp.data.data.house,

            }))

        }

        const getData = async () => {
            const resp = await axios.get(`/api/client/school/gradesandhouses`,

                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            // console.log(resp.data)
            setSchoolName(resp.data.school_name)
            setTotalGrades(resp.data.grades)
            setTotalHouse(resp.data.houses)
        }


        const getChild = async () => {
            const resp = await axios.get(`/api/client/child`,

                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            );

            setTotalChild(resp.data.data)
        }
        getSingleChild()
        getData()
        getChild()


    }, [token, child.id]);

    const handleChange = (e) => {

        setData((prev) => ({ ...prev, [e.target.name]: e.target.value }))
    }



    const handleSubmit = (e) => {
        e.preventDefault()

        if (data.name.trim() === '' || data.grade === '' || data.gender === '') {
            swal({
                title: 'Warning',
                text: 'Fill all mandatory fields',
                icon: 'error',
                button: 'Close',
                dangerMode: true,
            })
            return
        }


        const formData = new FormData()
        formData.append('grade', data.grade)
        formData.append('gender', data.gender)
        formData.append('name', data.name)
        formData.append('house', data.house)

        setLoading(true)
        axios
            .patch(`/api/client/child/${child.id}`, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((res) => {
                // console.log(res.data)

                const msg = res.data.message ? res.data.message : 'Edited successfully!';
                swal({
                    title: 'success',
                    text: msg,
                    icon: 'success',
                    button: 'Ok',
                })
                setLoading(false)
                navigate('/dashboard', { replace: true })
            })
            .catch((err) => {
                setLoading(false)

                const msg = err.response.data.message ? err.response.data.message : 'Something went wrong!';
                swal({
                    title: 'Warning',
                    text: msg,
                    icon: 'error',
                    button: 'Retry',
                    dangerMode: true,
                })
            })
    }






    return (
        <div>

            <>
                <div className="" style={{ minHeight: '100vh' }}>
                    <div className="Productmenu  ">
                        <div className='submain mt-4' >
                            <Link to='/dashboard' style={{ textDecoration: 'none', color: 'black', fontSize: '1.5rem', }}>Dashboard</Link>
                            <p className=' mt-3 '> <Link to='/select_child' style={{ textDecoration: 'none', color: 'black', }}>Products</Link></p>
                            <p><Link to='/order/history' style={{ textDecoration: 'none', color: 'black', }}>Order History</Link></p>
                            <p><Link to='/child/add' style={{ textDecoration: 'none', color: 'black', }}>Children</Link></p>
                            {/* <p>Support</p>
                            <p><Link to='/wishlist/me' style={{ textDecoration: 'none', color: 'black', }}>Wishlist</Link></p> */}
                            <p><Link to='/support_system' style={{ textDecoration: 'none', color: 'black', }}>Support</Link></p>


                            <p className=' mt-3 '> <Link to='/profile' style={{ textDecoration: 'none', color: 'black', }}>My Profile</Link></p>
                            <p onClick={() => signout()}><Link style={{ textDecoration: 'none', color: 'black', }}>logOut</Link></p>

                        </div>

                    </div>
                    {/* ----------------- */}
                    <div className="hamburger" onClick={toggleHamburger}>
                        <Hamburger isOpen={hamburgerOpen} />
                    </div>
                    {/* --------------- */}

                    <SideNavbar hamburgerOpen={hamburgerOpen} />


                    <div className="contentDivs">
                        <h1 className='d-flex justify-content-center' style={{
                            paddingTop: '4%',
                            // marginBottom: '2%'
                        }}>Edit Child</h1>
                        {/* <div className="d-flex justify-content-center">
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting
                                industry. Lorem Ipsum has been
                            </p>



                        </div> */}





                        {/*  */}






                        <div className=" productOrder  mt-4 mainBords">
                            {schoolName && <h2 className='d-flex justify-content-center mt-3'>{schoolName}</h2>}
                            {/* <div className="ms-2">

                                <h6 className=' ms-3 mt-3'>All Children</h6>


                                <ol className=' ms-3 mb-3'>
                                    {totalChild && totalChild.map((item, index) =>




                                        <li key={index}> {item.name} </li>

                                    )}
                                </ol>



                            </div> */}

                            {/* -------------------- */}
                            <div className="  mt-2 mb-3 p-2 " >
                                <div className="mb-1 ms-2">
                                    <h6 htmlFor="name" className="form-label font-weight-bold">Child Name :</h6>
                                    <input style={{ height: "46px", width: '80%' }} type="name" id="name" className="form-control" name="name" value={data.name} placeholder="Add child name here"
                                        onChange={handleChange}
                                        required />
                                </div>
                            </div>
                            <div className=" mb-3 p-2 d-flex" >
                                <div className="mb-1 ms-2">
                                    <h6 htmlFor="name" className="ms-1">Grades :</h6>


                                    <select className='ms-2' name='grade' style={{ width: '5rem', height: '2.5rem', margin: 'auto', }}
                                        onChange={handleChange}
                                        value={data.grade}

                                    >
                                        <option value="1">--select--</option>
                                        {totalGrades && totalGrades.map((item, index) =>
                                            <option key={index} value={item?._id} >{item?.name}</option>

                                        )}

                                    </select>

                                </div>


                                <div className="mb-1 ms-4 flex-wrap">
                                    <h6 htmlFor="name" className="ms-1">Gender :</h6>


                                    <select className='' name='gender' style={{ height: '2.5rem' }}
                                        onChange={handleChange}
                                        value={data.gender}>

                                        <option value="1">--select--</option>

                                        <option value='boy' >Boy</option>
                                        <option value='girl'> Girl</option>

                                    </select>

                                </div>



                            </div>

                            <div className="mb-1 ms-4">
                                <h6 htmlFor="name" className="ms-1">House :</h6>


                                <select className='' name='house' style={{ width: '15rem', height: '2.5rem' }}
                                    onChange={handleChange}
                                    value={data.house}>

                                    <option value="1">--select--</option>
                                    {totalHouse && totalHouse.map((item, index) =>
                                        <option key={index} value={item?._id} >{item?.name}</option>

                                    )}
                                    {/* <option value='boy' >Boy</option>
                                        <option value='girl'> Girl</option> */}

                                </select>

                            </div>

                            {/*  */}
                            <div className="mb-3 d-grid">
                                <button type="submit" className="btn btn-dark mt-4 mx-5" onClick={handleSubmit} >

                                    <ClipLoader loading={loading} color={'white'} size={25} />
                                    {!loading && "Update"}


                                </button>



                            </div>

                        </div>

















                    </div>




                </div>


            </>
        </div>
    )
}

export default EditChild;