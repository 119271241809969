import React, { useEffect, useState } from "react";

import gift from "../../AllImage/gift.png";
import Group from "../../AllImage/Group.png";

import shoppingCart from "../../AllImage/shopping-cart.png";

import "./Dashboard.css";
import Hamburger from "../../Hamburger";
import SideNavbar from "../../SideNavbar";
import { Link, useNavigate } from "react-router-dom";
import { isAutheticated, signout } from "../../Auth";
import axios from "axios";
import { PulseLoader } from "react-spinners";
import toast from "react-hot-toast";
import swal from "sweetalert";
const Dashboard = () => {
  const navigate = useNavigate();
  const [hamburgerOpen, setHamburgerOpen] = useState(false);

  const toggleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen);
  };

  const token = isAutheticated();
  const [loading, setLoading] = useState(false);
  const [totalChild, setTotalChild] = useState([]);
  const [schoolUpdates, setSchoolUpdates] = useState({});
  const [totalGrades, setTotalGrades] = useState([]);
  const [schoolName, setSchoolName] = useState();
  const [items, setItems] = useState([]);
  const [refresh, setRefresh] = useState();

  useEffect(() => {
    const getChild = async () => {
      const resp = await axios.get(
        `/api/client/child`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTotalChild(resp.data.data);
    };

    getChild();
  }, [token, refresh]);
  useEffect(() => {
    // get order Details
    const getOrderData = async () => {
      setLoading(true);
      const res = await axios.get(
        `/api/client/order/`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log(res.data.data)

      setItems(
        res?.data?.data?.filter((e) => e.payment == "success")?.slice(0, 5)
      );
      setLoading(false);
    };
    (async () => {
      try {
        const response = await axios.get("/api/client/school/updates", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setSchoolUpdates(response?.data?.data);
      } catch (error) {
        console.log(error);
        swal({
          title: "Warning",
          text: error?.response?.data?.message || "Something went wrong!",
          icon: "error",
          button: "Close",
          dangerMode: true,
        });
      }
    })();

    getOrderData();
  }, [token]);

  //change time formate
  function formatAMPM(date) {
    var hours = new Date(date).getHours();
    var minutes = new Date(date).getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  const AddChildHandler = () => {
    navigate("/child/add");
  };
  function UnlinkChild(ids) {
    if (ids === "") {
      swal({
        title: "Warning",
        text: "Please Enter Child Id ",
        icon: "error",
        button: "Close",
        dangerMode: true,
      });
      return;
    }
    // setLoading(true)
    swal({
      title: "Are you sure?",
      icon: "error",
      buttons: {
        Yes: { text: "Yes", value: true },
        Cancel: { text: "Cancel", value: "cancel" },
      },
    }).then((value) => {
      if (value === true) {
        axios
          .delete(
            `/api/client/child/unlink_child/${ids}`,

            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            setRefresh((prev) => !prev);
            const msg = res.data.message
              ? res.data.message
              : "Unlink Child successfully!";
            toast.success(msg);
            // setLoading(false)
          })
          .catch((err) => {
            // setLoading(false)

            const msg = err.response.data.message
              ? err.response.data.message
              : "Something went wrong!";
            toast.error(msg);
          });
      }
    });
  }
  return (
    <>
      <div className='v'>
        <div className=''>
          {localStorage.getItem("schoolBanner") && (
            <img
              src={`${localStorage.getItem("schoolBanner")}`}
              alt=''
              width='100%'
              height='100%'
              srcset=''
            />
          )}
        </div>
        <div className=' ' style={{ minHeight: "100vh" }}>
          <div className='menu '>
            <div className='submain mt-4'>
              <Link
                to='/dashboard'
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "1.6rem",
                  color: "blue",
                }}>
                Dashboard
              </Link>

              <p className=' mt-3 '>
                {" "}
                <Link
                  to='/select_child'
                  style={{ textDecoration: "none", color: "black" }}>
                  Products
                </Link>
              </p>
              <p>
                <Link
                  to='/order/history'
                  style={{ textDecoration: "none", color: "black" }}>
                  Order History
                </Link>
              </p>
              <p>
                <Link
                  to='/child/add'
                  style={{ textDecoration: "none", color: "black" }}>
                  Children
                </Link>
              </p>

              <p>
                <Link
                  to='/support_system'
                  style={{ textDecoration: "none", color: "black" }}>
                  Support
                </Link>
              </p>

              {/* <p><Link to='/wishlist/me' style={{ textDecoration: 'none', color: 'black', }}>Wishlist</Link></p> */}

              <p className=' mt-3 '>
                {" "}
                <Link
                  to='/profile'
                  style={{ textDecoration: "none", color: "black" }}>
                  My Profile
                </Link>
              </p>
              <p onClick={() => signout()}>
                <Link style={{ textDecoration: "none", color: "black" }}>
                  Logout
                </Link>
              </p>
            </div>
          </div>
          {/* ----------------- */}
          <div className='hamburger' onClick={toggleHamburger}>
            <Hamburger isOpen={hamburgerOpen} />
          </div>
          {/* --------------- */}

          <SideNavbar hamburgerOpen={hamburgerOpen} />

          <div className='dashDiv'>
            <div className='py-4 dashboardMenu'>
              <div className='container'>
                <h2>Dashboard</h2>

                <div className='row hidden-md-up'>
                  <div className='col-lg-6 mt-4'>
                    <div className='card' style={{}}>
                      <div
                        className='card-block d-flex'
                        style={{
                          background: "#F2E9E4",
                          boxShadow: "2px 1px 7px rgba(0, 0, 0, 0.25)",
                          borderRadius: "4px",
                          height: "35vh",
                        }}>
                        <div className='1 ms-3 mb-1 w-50 overflow-auto'>
                          <div className='d-flex'>
                            <h4 className='card-title ms-2  '>Children</h4>
                            {totalChild && (
                              <h4 className='card-title ms-2  d-flex justify-content-end '>
                                {loading ? (
                                  <PulseLoader size={6} color='black' />
                                ) : (
                                  totalChild.length
                                )}
                              </h4>
                            )}
                          </div>

                          {totalChild && loading ? (
                            <PulseLoader size={6} color='black' />
                          ) : totalChild.length > 0 ? (
                            totalChild.map((item, index) => (
                              <div className='d-flex '>
                                <li
                                  className='d-flex justify-content-start ms-3'
                                  key={index}>
                                  {item.name}
                                </li>
                                <Link
                                  className=' ms-2 d-flex '
                                  //  to={`/child/edit/${item._id}
                                  //  `
                                  // }

                                  style={{ textDecoration: "none" }}>
                                  <span
                                    class='material-symbols-outlined'
                                    onClick={() => UnlinkChild(item._id)}>
                                    close
                                  </span>
                                  {/* <i class='bi bi-pencil-square'></i> */}
                                </Link>
                              </div>
                            ))
                          ) : (
                            <div className='ms-2'>No children till Now..</div>
                          )}
                        </div>

                        <div className='2 w-50 '>
                          <div className=' d-flex  justify-content-end image'>
                            <img
                              className='align-items-start me-3 mt-2'
                              src={Group}
                              alt=''
                            />
                          </div>
                          <br />
                          <div className=' d-flex float-end  flex-wrap me-2 mt-3'>
                            <button
                              className='d-flex  justify-content-center align-items-center '
                              onClick={AddChildHandler}
                              style={{
                                width: "8rem",
                                height: "2.5rem",
                                background: "#9A8C98",
                                color: "white",
                                border: "none",
                                // marginTop: '80%',
                                // marginLeft: '8%',
                              }}>
                              Add Child +
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-6  mt-4 '>
                    <div className='card'>
                      <div
                        className='card-block d-flex'
                        style={{
                          background: "#C9ADA7",
                          boxShadow: "2px 1px 7px rgba(0, 0, 0, 0.25)",
                          borderRadius: "4px",
                          height: "35vh",
                        }}>
                        <div className=' ms-2 mb-1 w-50 overflow-auto '>
                          <div className='d-flex'>
                            <h4 className='card-title ms-1  '>Orders</h4>
                            {items && (
                              <h4 className='card-title ms-2  d-flex justify-content-end '>
                                {loading ? (
                                  <PulseLoader size={6} color='black' />
                                ) : items ? (
                                  items.length
                                ) : (
                                  0
                                )}
                              </h4>
                            )}
                          </div>

                          {items && loading ? (
                            <PulseLoader size={6} color='black' />
                          ) : items.length > 0 ? (
                            items.map((item, index) => (
                              <div className='d-flex ms-2' key={index}>
                                <span className=' fst-italic'>
                                  {" "}
                                  <Link
                                    className=''
                                    style={{
                                      textDecoration: "none",
                                      color: "black",
                                    }}
                                    to={`/order/details/${item._id}`}>
                                    <strong>{item.order_id}</strong>
                                  </Link>
                                  ,{" "}
                                  <small>
                                    {new Date(
                                      `${item?.placed_on || item?.createdAt}`
                                    ).toDateString()}
                                    ,
                                    {formatAMPM(
                                      item?.placed_on || item?.createdAt
                                    )}{" "}
                                  </small>
                                  {/* */}
                                </span>

                                <span></span>
                              </div>
                            ))
                          ) : (
                            <div className='ms-1'>No Order till Now..</div>
                          )}

                          {/* <h4 className="card-title ms-2">Orders</h4>
                                                <ol>
                                                    <li>Name of Order 1.....</li>
                                                    <li> Name of Order 2.....</li>



                                                </ol> */}
                        </div>
                        <div className='2 w-50 '>
                          <div className=' d-flex  justify-content-end image'>
                            <img
                              className='align-items-start me-3 mt-2'
                              src={gift}
                              alt=''
                            />
                          </div>
                          <br />
                          <div className=' d-flex justify-content-end    flex-wrap me-2 mt-3'>
                            <button
                              className='d-flex  justify-content-center align-items-center '
                              style={{
                                width: "8rem",
                                height: "2.5rem",
                                background: "#9A8C98",
                                color: "white",
                                border: "none",
                                bottom: "0",
                              }}>
                              {" "}
                              <Link
                                to='/select_child'
                                style={{
                                  textDecoration: "none",
                                  color: "white",
                                }}>
                                New Order +
                              </Link>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-6 mt-4'>
                    <div className='card'>
                      <div
                        className='card-block d-flex'
                        style={{
                          background: "#9A8C98",
                          minHeight: "35vh",
                          boxShadow: "2px 1px 7px rgba(0, 0, 0, 0.25)",
                          borderRadius: "4px",
                        }}>
                        <div className='1 ms-3 mb-5 flex-fill'>
                          <h4 className='card-title ms-2 mt-2'>
                            Delivery Status
                          </h4>
                          <p className='mt-1 ms-2'>
                            {schoolUpdates?.delivery_status}
                          </p>
                        </div>

                        <div
                          style={{
                            height: "70px",
                            width: "70px",
                            margin: "10px 10px 0 0",
                          }}>
                          <img
                            style={{
                              height: "60px",
                              margin: "5px",
                            }}
                            src={shoppingCart}
                            alt=''
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-6 mt-4'>
                    <div className='card'>
                      <div
                        className='card-block d-flex'
                        style={{
                          background: "#F2E9E4",
                          boxShadow: "2px 1px 7px rgba(0, 0, 0, 0.25)",
                          borderRadius: "4px",
                          minHeight: "35vh",
                        }}>
                        <div className='1 ms-3 mb-5'>
                          <h4 className='card-title ms-2 mt-2'>Announcement</h4>
                          <p className='mt-1 ms-2'>
                            {schoolUpdates?.announcement}
                          </p>
                        </div>

                        <div
                          style={{
                            height: "70px",
                            width: "70px",
                            margin: "10px 10px 0 0",
                          }}>
                          <img
                            style={{
                              height: "60px",
                              margin: "5px",
                            }}
                            src={shoppingCart}
                            alt=''
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
