import React from "react";

const SizeChart = ({ product, setProduct }) => {
  return (
    <>
      <div
        style={{
          minHeight: "100vh",
          width: "100vw",
          background: "rgba(136, 136, 136, 0.5)",
          position: "fixed",
          top: "0px",
          right: product ? "0vw" : "-100vw",
          zIndex: "999998",
          transition: "all 0.1s ease-out",
        }}
        onClick={() => setProduct()}></div>
      <div
        style={{
          minHeight: "100vh",
          width: window.innerWidth > 800 ? "40vw" : "100vw",
          background: "white",
          position: "fixed",
          top: "0px",
          right: product ? "0vw" : "-100vw",
          zIndex: "999999",
          transition: "all 0.5s ease-out",
        }}>
        <div className='p-3 fw-bold position-relative'>
          <div>Product : {product?.product?.name}</div>
          <div
            className='position-absolute'
            style={{ zIndex: "999999", top: "10px", right: "0px" }}
            onClick={() => setProduct()}>
            <svg
              width='30px'
              height='30px'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'>
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M19.207 6.207a1 1 0 0 0-1.414-1.414L12 10.586 6.207 4.793a1 1 0 0 0-1.414 1.414L10.586 12l-5.793 5.793a1 1 0 1 0 1.414 1.414L12 13.414l5.793 5.793a1 1 0 0 0 1.414-1.414L13.414 12l5.793-5.793z'
                fill='#000000'
              />
            </svg>
          </div>
        </div>
        <hr className='p-0 m-0' />
        <div
          className='ps-3 py-2 fw-bold text-center'
          style={{ borderBottom: "0.5px solid black", width: "300px" }}>
          Size Chart
        </div>
        <hr className='p-0 m-0' />
        <div className='m-3'>
          <img
            src={product?.size_chart}
            alt='Size Chart'
            style={{
              maxWidth: "300px",
              border: "thin solid black",
              borderRadius: "5px",
            }}
          />
        </div>
      </div>
    </>
  );
};

export default SizeChart;
