import React, { useState } from "react";
import Hamburger from "../../Hamburger";
import SideNavbar from "../../SideNavbar";
import "./Checkout.css";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { isAutheticated, signout } from "../../Auth.js";
import axios from "axios";
import toast from "react-hot-toast";
import mainlogo from "../../AllImage/reinventlogo.png";
import productPlaceholder from "../../AllImage/productPlaceholder.jpg";

const Checkout = () => {
  const token = isAutheticated();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [screenLoading, setScreenLoading] = useState(false);
  // const [success, setSuccess] = useState(false);
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const [address, setAddress] = useState([]);
  // const [defaultAddress, setDefaultAddress] = useState({});
  // const [addresssId, setAddressId] = useState("");
  const [checkoutData, setCheckoutData] = useState({});

  useEffect(() => {
    function getAll() {
      setScreenLoading(true);
      Promise.all([
        // axios.get("/api/client/address", {
        //   headers: {
        //     Authorization: `Bearer ${token}`,
        //   },
        // }),
        axios.get("/api/client/checkout/v2", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }),
      ])
        .then((res) => {
          setCheckoutData(res[0].data?.data || {});

          // if (res[0].data?.data?.length > 0) {
          //   if (!res[1].data?.data?.address) {
          //     const defaultA = res[0].data.data.filter((e) => e.default);
          //     if (defaultA) {
          //       setDefaultAddress(defaultA[0]);
          //       setAddressId(defaultA[0]?._id);
          //     }
          //   } else {
          //     const defaultA = res[0].data.data.filter(
          //       (e) => e._id === res[1].data?.data?.address
          //     );
          //     if (defaultA) {
          //       setDefaultAddress(defaultA[0]);
          //       setAddressId(defaultA[0]?._id);
          //     }
          //   }
          // }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message || "Something went wrong!");
          navigate("/cart");
        })
        .finally(() => setScreenLoading(false));
    }
    getAll();
    const getAddress = async () => {
      const res = await axios.get(
        `/api/client/address`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //   console.log(res.data.data);
      if (res.data.data) {
        res.data.data.forEach((item) => {
          if (item.default === true) {
            setAddress(item);
          }
        });
      }
    };
    getAddress();
  }, []);

  const toggleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen);
  };

  // const handleChange = (e) => {
  //   setAddressId((prev) => e.target.id);
  // };

  // const handleChangeAddress = () => {
  //   if (addresssId === "") {
  //     return toast.error("Please select Address");
  //   }
  //   setLoading(true);
  //   axios
  //     .post(
  //       `/api/client/checkout/setaddress/${checkoutData._id}`,
  //       { address: addresssId },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       setLoading(false);
  //       toast.success("Address selected successfully!");
  //       setSuccess((prev) => !prev);
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //       toast.error("Something went wrong!");
  //     });
  // };
  const handleChangeAddress = () => {
    navigate("/address/new");
  };
  const handleCheckOut = () => {
    if (!address) {
      return toast.error("please select address to continue!");
    }
    setLoading(true);
    axios
      .get(`/api/client/checkout/proceedtopayment`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setLoading(false);
        const data = res.data.data;

        const options = {
          key: res.data?.api_key_id,
          amount: data.total_amount,
          currency: "INR",
          name: "Reinvent Uniforms",
          description: "Buy products",
          image: mainlogo,
          order_id: data.razorpay_order_id,
          handler: function (response) {
            onPayment(response);
          },
          prefill: {
            name: data.name,
            email: data.email,
          },
          theme: {
            color: "#3399cc",
          },
        };
        var rzp1 = new window.Razorpay(options);
        rzp1.open();
        rzp1.on("payment.failed", function (response) {
          //   alert(response.error.code);
          //   alert(response.error.description);
          //   alert(response.error.source);
          //   alert(response.error.step);
          //   alert(response.error.reason);
          //   alert(response.error.metadata.order_id);
          //   alert(response.error.metadata.payment_id);
          onPayment(
            { razorpay_order_id: response.error.metadata.order_id },
            false
          );
        });
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message || "Something went wrong!");
      });
  };

  function onPayment(response, success = true) {
    axios
      .post(`/api/client/payment/onpayment`, { ...response, success })
      .then((res) => {
        // console.log(response)
        // console.log(res)
        navigate(`/order/confirmation/${res.data.order_id}`);
      })
      .catch((err) => toast.error("Payment failed!"));
  }

  return (
    <>
      <div className=''>
        <div className='Productmenu  '>
          <div className='submain mt-4'>
            <Link
              to='/dashboard'
              style={{ textDecoration: "none", color: "black" }}>
              Dashboard
            </Link>

            <p className=' mt-3 '>
              {" "}
              <Link
                to='/select_child'
                style={{ textDecoration: "none", color: "black" }}>
                Products
              </Link>
            </p>
            <p>
              <Link
                to='/order/history'
                style={{ textDecoration: "none", color: "black" }}>
                Order History
              </Link>
            </p>
            <p>
              <Link
                to='/child/add'
                style={{ textDecoration: "none", color: "black" }}>
                Children
              </Link>
            </p>

            <p>
              <Link
                to='/support_system'
                style={{ textDecoration: "none", color: "black" }}>
                Support
              </Link>
            </p>

            {/* <p><Link to='/wishlist/me' style={{ textDecoration: 'none', color: 'black', }}>Wishlist</Link></p> */}

            <p className=' mt-3 '>
              {" "}
              <Link
                to='/profile'
                style={{ textDecoration: "none", color: "black" }}>
                My Profile
              </Link>
            </p>
            <p onClick={() => signout()}>
              <Link style={{ textDecoration: "none", color: "black" }}>
                Logout
              </Link>
            </p>
          </div>
        </div>
        {/* ----------------- */}
        <div className='hamburger' onClick={toggleHamburger}>
          <Hamburger isOpen={hamburgerOpen} />
        </div>
        {/* --------------- */}

        <SideNavbar hamburgerOpen={hamburgerOpen} />

        <div className='contentDivs'>
          <h1
            className='d-flex justify-content-center'
            style={{
              paddingTop: "3%",
              paddingBottom: "3%",
            }}>
            Checkout
          </h1>

          {screenLoading ? (
            <div className='text-center' style={{ minHeight: "500px" }}>
              <div className='spinner-grow' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </div>
            </div>
          ) : (
            <>
              <div
                className='card'
                style={{ background: "#F2E9E4", margin: "1rem" }}>
                {/* <div className='card-body'> */}
                {address !== "" ? (
                  <div class='card-body d-flex flex-wrap  '>
                    {/* <h5 class="card-title">Card title</h5> */}

                    {/* <div className="v"> */}

                    <div className=' w-75  '>
                      {/* Deliver to: */}
                      <h6 class='card-subtitle mb-2 '>
                        {address && address.full_name},{" "}
                        {address && address.mobile_number}
                      </h6>

                      <p class='card-text'>
                        {address && address.flat_house_no_apartment}{" "}
                        {address && address.area_street_sector_village}{" "}
                        {address && address.address_line}
                        {address && address.landmark}, {address && address.city}{" "}
                        {address && address.state}, {address && address.pincode}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div class='card-body d-flex flex-wrap  '>
                    <div className='w-75'>
                      {" "}
                      <p>No Address added till Now ..</p>
                    </div>
                    <div className=' w-25 d-flex   justify-content-center align-items-center  '>
                      <button
                        onClick={handleChangeAddress}
                        className=''
                        style={{
                          width: "12rem",
                          height: "100%",
                        }}>
                        Add Address
                      </button>
                    </div>
                  </div>
                )}

                {/* <div className='text-center mt-2'>
                    <button
                      className='btn btn-dark'
                      style={{
                        width: "12rem",
                      }}
                      onClick={handleChangeAddress}
                      disabled={loading || addresses.length < 2}>
                      Change Address
                    </button>
                  </div> */}
                {/* </div> */}
              </div>

              <div className='row container pb-5'>
                <div className='col-xl-8 mt-4'>
                  <div className='card mx-auto'>
                    <div className='card-body'>
                      <h3 className='mt-3 text-center'>Items</h3>
                      <div className='mt-1'>
                        {checkoutData?.items?.length > 0 &&
                          checkoutData.items.map((e, i) => (
                            <div key={i} className='my-2'>
                              <div className='row'>
                                <div className='col-md-4'>
                                  <img
                                    src={
                                      e.product?.images?.length > 0
                                        ? e.product?.images[0]?.url
                                        : productPlaceholder
                                    }
                                    alt={e.product.name}
                                    className='checkout-img'
                                  />
                                </div>
                                <div
                                  className='col-md-8 checkout-item-info'
                                  style={{ fontSize: "14px" }}>
                                  <h5 className='m-0 p-0'>{e.product_name}</h5>
                                  <p className='m-0 p-0'>
                                    <span>Enrollment No.</span>:{" "}
                                    {e?.enrollment_number || "-"}
                                  </p>
                                  <p>
                                    <span>Size</span>: {e.variant.size?.size}
                                  </p>
                                  <p>
                                    <span>Price:</span> Rs. {e.price}
                                  </p>
                                  {e?.igst && (
                                    <p>
                                      <span>IGST:</span> {e.igst}%
                                    </p>
                                  )}
                                  {e?.cgst && (
                                    <p>
                                      <span>CGST:</span> {e.cgst}%
                                    </p>
                                  )}
                                  {e?.sgst && (
                                    <p>
                                      <span>SGST:</span> {e.sgst}%
                                    </p>
                                  )}
                                  <p>
                                    <span>Quantity:</span> {e.quantity}
                                  </p>
                                  <p>
                                    <span>Total:</span> Rs. {e.amount}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                      <div></div>
                    </div>
                  </div>
                </div>

                <div className='col-xl-4 mt-4'>
                  <div
                    className='card priceCard'
                    style={{
                      background: "#C9ADA7",
                    }}>
                    <div className='card-body'>
                      <h4 className='card-title'>Checkout Details</h4>
                      <div className='d-flex justify-content-between mb-1'>
                        <label className='form-label ms-3'>
                          <strong>Gross Amount :</strong>
                        </label>
                        <strong> Rs {checkoutData?.total_gross_amount}</strong>
                      </div>
                      <div className='d-flex justify-content-between mb-1'>
                        <label className='form-label ms-3'>
                          <strong>Shipping Charge :</strong>
                        </label>
                        <strong>
                          {" "}
                          Rs {checkoutData?.shipping_charge_without_tax}
                        </strong>
                      </div>
                      <div className='d-flex justify-content-between mb-1'>
                        <label className='form-label ms-3'>
                          <strong>Tax Amount :</strong>
                        </label>
                        <strong> Rs {checkoutData?.tax_amount}</strong>
                      </div>
                      <hr style={{ margin: "0" }} />
                      <div className='mb-3 d-flex justify-content-between'>
                        <label className='form-label ms-3'>
                          <strong>Total Amount :</strong>
                        </label>
                        <strong> Rs {checkoutData?.total_amount}</strong>
                      </div>
                      {checkoutData?.remarks && (
                        <div
                          className='my-2 mx-1 alert alert-primary'
                          style={{
                            display: "block",
                            whiteSpace: "pre-wrap",
                            overflowWrap: "break-word",
                            fontSize: 12,
                            padding: "3px 7px",
                          }}
                          role='alert'>
                          {checkoutData?.remarks}
                        </div>
                      )}
                      <div className='mb-3 d-grid'>
                        <button
                          className='btn btn-dark mt-4'
                          onClick={handleCheckOut}
                          disabled={loading}>
                          Pay and Place Order
                        </button>
                        <Link
                          to='/cart'
                          class='btn btn-success mt-3 '
                          data-abc='true'>
                          Edit Cart
                        </Link>
                        <Link
                          to='/select_child'
                          class='btn btn-primary mt-3 '
                          data-abc='true'>
                          Continue Shopping
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Checkout;
