import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";

const TemsAndConditions = () => {
  const [termsofUseData, setTermsofUseData] = useState();
  useEffect(() => {
    const getData = async () => {
      const res = await axios.get(`/api/client/config/termsofuse`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setTermsofUseData(res.data.data);
    };
    getData();
  }, []);
  return (
    <>
      <div className='container'>
        <div className='p-2' style={{ maxWidth: "877px", margin: "auto" }}>
          <div class='col-xs-12 text-center'>
            <h1 class='title mt-4'>Terms and Conditions</h1>
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: termsofUseData,
            }}
          />
          {/* <p>{termsofUseData && termsofUseData} </p> */}
          {/* <h4>
            You are therefore advised to read carefully these terms and
            conditions every time you use the reinvent website.
          </h4>
          <ol>
            <li>
              We have made every effort to display the colour of our products
              that appear on the site as accurately as possible. However, as the
              actual colours you see will depend on your monitor, we cannot
              guarantee that your monitor’s display of a colour will be
              accurate.
            </li>
            <li>
              The User certifies that he/she is at least 18 (eighteen) years of
              age or has the consent of a parent or legal guardian.
            </li>
            <li>
              Reinvent Apparels Pvt Ltd will not be responsible for any damage
              suffered by users from the use of the services on
              reinventuniforms.com This without limitation includes loss of
              revenue or data resulting from delays, non-deliveries, missed
              deliveries, or service interruptions as may occur because of any
              act/omission of the vendor. This disclaimer of liability also
              applies to any damages or injury caused by any failure of
              performance, negligence, defect, deletion, error, omission,
              interruption, delay in operation or transmission, computer virus,
              communication line failure, theft or destruction or unauthorized
              access to, alteration of, or use of record, whether for breach of
              contract, tortious behaviour, or under any other cause of action.
            </li>
            <li>
              The prices and availability of products are subject to change
              without prior notice at the sole discretion of Reinvent Apparels
              Pvt Ltd. or the Seller.
            </li>
            <li>
              Request for cancellations of orders once placed on reinvent shall
              not be entertained.
            </li>
            <li>
              Reinvent reserves the right to refuse or cancel any order placed
              for a product that is listed at an incorrect price or for any
              other reason. This shall be regardless of whether the order has
              been confirmed and/or payment has been received. The payment shall
              be refunded and the User shall be informed of the same.
            </li>
            <li>
              If a non-delivery or late delivery occurs due to a mistake by the
              User (i.e. wrong or incomplete name or address or recipient not
              available) any extra cost spent by Reinvent Apparels Pvt Ltd for
              redelivery shall be claimed from the User placing the order.
            </li>
            <li>
              The User agrees to give accurate, authentic and true information.
              Reinvent reserves the right to confirm and validate the
              information and other details provided by the User at any point in
              time. If any such User details are found not to be true wholly or
              partly, Reinvent Apparels Pvt Ltd. has the right in its sole
              discretion to reject the registration and debar the User from
              using the services of reinvent without prior intimation
              whatsoever.
            </li>
            <li>
              Reinvent Apparels Pvt Ltd. will not be liable for any type or kind
              of credit card fraud. The liability to use a card fraudulently
              will be on the user and the onus to ‘prove otherwise’ shall be
              exclusively on the user. The User must exclusively use his/her own
              card on the site.
            </li>
            <li>
              This agreement shall be construed in accordance with the
              applicable laws of India. The Courts at Bangalore, name of the
              place shall have exclusive jurisdiction in any proceedings arising
              out of this agreement.
            </li>
          </ol>
          <h3>REFUND POLICY</h3>
          <p>
            Reinvent will not accept return or refund requests online once the
            order has been placed on https://buyonline.reinventuniforms.com. All
            requests for returns or refunds should be sent directly by email to
            support@reinvent.co.in. Our support team will contact you to
            understand the reason for the return or refund request and process
            the same as per our agreement with the schools or by our staff at
            school premises on Uniform Day. In case of defective items or wrong
            size items, the same will be exchanged at the respective school on
            Uniform Day.
          </p> */}
        </div>
      </div>
    </>
  );
};

export default TemsAndConditions;
