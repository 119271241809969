import axios from "axios";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { isAutheticated } from "../../Auth";
import productPlaceholder from "../../AllImage/productPlaceholder.jpg";
import SizeChart from "./elements/SizeChart.jsx";

function ProductItem({
  item_data,
  option,
  option_id,
  bundleQuantity,
  childId,
}) {
  const [sizeChart, setSizeChart] = useState();
  const [data, setData] = useState({
    price: item_data?.master_price,
    variant: "",
    product: item_data?.product?._id,
    quantity: Number(
      option === "bundle" ? bundleQuantity : item_data?.quantity || 1
    ),
    option_id,
    size: "",
  });
  const [loading, setLoading] = useState(false);

  const token = isAutheticated();

  function handleSubmit(e) {
    e.preventDefault();
    if (data.variant === "") {
      toast.error("Please select Size");
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append("option_id", data.option_id);
    formData.append("product", data.product);
    formData.append("variant", data.variant);
    formData.append("quantity", data.quantity);
    formData.append("child", childId);

    axios
      .post("/api/client/cart", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setData((prev) => ({
          ...prev,
          quantity: 1,
          size: "",
          variant: "",
        }));

        toast.success("Added To Cart", {
          style: {
            border: "1px solid #black",
            padding: "16px",
            color: "black",
          },

          iconTheme: {
            primary: "green",
            secondary: "#FFFAEE",
          },
        });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        const msg = err?.response?.data?.message
          ? err.response.data.message
          : "Unable to add to cart";
        toast.error(msg, {
          style: {
            border: "1px solid #black",
            padding: "16px",
            color: "#713200",
          },

          iconTheme: {
            primary: "red",
            secondary: "#FFFAEE",
          },
        });
      })
      .finally(() => setLoading(false));
  }

  function handleChange(e) {
    if (e.target.name === "variant") {
      if (e.target.value === "")
        return setData((prev) => ({ ...prev, variant: "", size: "" }));
      const price = item_data?.variants_data_object[e.target.value];
      item_data.product.variants.map((item) => {
        if (item._id === e.target.value) {
          //   const iprice = item.price;
          return setData((prev) => ({
            ...prev,
            size: item.size.size,
            variant: item._id,
            price: price,
          }));
        }
      });
    }

    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  }

  return (
    <div>
      <div className=' d-flex  mt-4'>
        <div className='card productCard'>
          <div className='row g-0'>
            <div className='col-md-3 '>
              {item_data?.product?.images &&
              item_data?.product?.images?.length > 0 ? (
                <img
                  src={item_data.product.images[0].url}
                  className=' responsive'
                  alt='...'
                />
              ) : (
                <img
                  src={productPlaceholder}
                  alt='...'
                  className=' responsive'
                />
              )}
            </div>
            <div className='col-md-8 '>
              <div className='card-body'>
                <div className='card-body'>
                  <div className='productName d-flex  '>
                    {item_data && (
                      <h3 className='card-title  ms-1 w-75 d-flex justify-content-Start'>
                        {item_data?.display_name || item_data?.product?.name}
                      </h3>
                    )}
                  </div>

                  {item_data && (
                    <h5 className=' ms-1 mt-1'>price: ₹{data.price}</h5>
                  )}

                  <div className=' d-flex flex-wrap mt-3'>
                    <div
                      className='d-flex justify-content-center me-2 mt-3'
                      style={{
                        background: "#F2E9E4",
                        // width: '.1rem',
                        width: "6rem",
                      }}>
                      <span className='d-flex justify-content-center align-items-center fs-6 ms-1'>
                        SIZE {data.size} :
                      </span>
                      <select
                        className='ms-2 '
                        name='variant'
                        style={{
                          border: "none",
                          background: "#F2E9E4",
                          width: "1rem",
                          height: "2.5rem",
                          margin: "auto",
                        }}
                        onChange={handleChange}
                        value={data.variant}>
                        <option value='' style={{ display: "none" }}>
                          --
                        </option>

                        {item_data?.product?.variants?.map((e, i) =>
                          true &&
                          e?.show_in_estore === true &&
                          item_data?.variants_data_object &&
                          item_data?.variants_data_object[e._id] ? (
                            <option
                              key={i}
                              value={e?._id}
                              //   onChange={() => setVariant(e._id, e.price)}
                            >
                              {e.size.size}
                            </option>
                          ) : null
                        )}
                      </select>
                    </div>
                    {option && option === "bundle" ? (
                      <>
                        <h5 className='mt-4 mx-2'>Qty: {bundleQuantity}</h5>
                        {/* <div
                          className='d-flex justify-content-center mt-3 me-3 '
                          style={{
                            background: "#F2E9E4",
                            // width: '.1rem',
                            width: "6rem",
                          }}>
                          <span className='d-flex justify-content-center align-items-center fs-6 ms-1'>
                            QTY {data.quantity} :
                          </span>
                          <select
                            className='me-2 '
                            name='quantity'
                            style={{
                              border: "none",
                              background: "#F2E9E4",
                              width: "1rem",
                              height: "2.5rem",
                              margin: "auto",
                            }}
                            onChange={handleChange}
                            value={data.quantity}>
                            <option value='' style={{ display: "none" }}>
                              --
                            </option>
                            {Count.length > 0 ? (
                              Count.map((_, i) => (
                                <option key={i} value={i + 1} selected>
                                  {i + 1}
                                </option>
                              ))
                            ) : (
                              <option value='' style={{}}>
                                --
                              </option>
                            )}

                          </select>
                        </div> */}
                      </>
                    ) : (
                      <div
                        className='d-flex justify-content-center mt-3 me-3 '
                        style={{
                          background: "#F2E9E4",
                          // width: '.1rem',
                          width: "6rem",
                        }}>
                        <span className='d-flex justify-content-center align-items-center fs-6 ms-1'>
                          QTY {data.quantity} :
                        </span>
                        <select
                          className='me-2 '
                          name='quantity'
                          style={{
                            border: "none",
                            background: "#F2E9E4",
                            width: "1rem",
                            height: "2.5rem",
                            margin: "auto",
                          }}
                          onChange={handleChange}>
                          <option value='1'>1</option>
                          <option value='2'>2</option>
                          <option value='3'>3</option>
                          <option value='4'>4</option>
                          <option value='5'>5</option>
                          <option value='6'>6</option>
                          <option value='7'>7</option>
                          <option value='8'>8</option>
                          <option value='9'>9</option>
                          <option value='10'>10</option>
                          <option value='11'>11</option>
                          <option value='12'>12</option>
                          <option value='13'>13</option>
                          <option value='14'>14</option>
                          <option value='15'>15</option>
                          <option value='16'>16</option>
                          <option value='17'>17</option>
                          <option value='18'>18</option>
                          <option value='19'>19</option>
                          <option value='20'>20</option>
                        </select>
                      </div>
                    )}

                    <div className='mt-3 '>
                      <button
                        onClick={handleSubmit}
                        // disabled={option === "bundle" && Count.length < 1}
                        disabled={loading}
                        style={{
                          width: "120%",
                          height: "2.5rem",
                          background: "#22223B",
                          color: "white",
                        }}>
                        Add To Cart
                      </button>
                    </div>
                  </div>

                  {/* {allData.length > 0 && (
                    <>
                      <div className='table-responsive table-shoot mt-3 tableWidth'>
                        <table className='table table-centered table-nowrap mb-0'>
                          <thead className='thead-light'>
                            <tr>
                              <th>Size</th>
                              <th>Quantity</th>
                              <th>Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {allData.map((cartitm, index) => (
                              <>
                                <tr>
                                  <td>{cartitm?.variant?.size}</td>

                                  <td>{cartitm?.quantity}</td>

                                  <td>
                                    <div className=' d-flex   justify-content-start align-items-center '>
                                      <button
                                        className='btn btn-info'
                                        onClick={() =>
                                          deleteSingleItem(cartitm?._id)
                                        }>
                                        {" "}
                                        Remove from Cart
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}  */}

                  {/* <h5 className='mt-4 ms-3'>Size:</h5> */}
                  {/* <div>
                                        {item_data.variants && item_data.variants.map((v) => (
                                            <button
                                                key={v._id}
                                                className="btn btn mt-1 me-2"
                                                disabled={v._id === data.variant}
                                                onClick={() => setVariant(v._id, v.price)}
                                                style={{
                                                    border: `${v._id === data.variant ? '2px solid #0080FF' : '1px solid #000000'}`, width: "4rem", hight: '4rem'
                                                    , fontWeight: "500",
                                                    fontSize: "2rem",
                                                    color: `${v._id === data.variant ? ' #0080FF' : ''}`

                                                }}
                                            >
                                                {v.size}
                                            </button>
                                        ))}
                                    </div> */}
                </div>
                {/* <div className="d-flex flex-wrap">

                                    <div className="mt-3 ms-3">
                                        <button onClick={handleSubmit} style={{
                                            width: "12rem",
                                            height: '2.5rem',
                                            background: '#22223B',
                                            color: 'white'

                                        }}
                                        >Add To Cart</button>
                                    </div>

                                    {option && option === 'bundle' ?

                                        <>                                    <h4 className='mt-3 ms-4'>Qty: {data.quantity}</h4>
                                        </> :
                                        <div className="mt-3 ms-3 d-flex  "
                                            style={{
                                                width: "7rem",
                                                height: '2.5rem',
                                                alignItems: 'center',
                                                margin: 'auto',

                                                background: '#22223B',
                                                color: 'white',
                                                border: '1px solid black'

                                            }}
                                        >

                                            <div className="input-group d-flex justify-content-center ">
                                                <div className="input-group-prepend">
                                                    <button className="btn btn-outline-dark" type="button"
                                                        // onClick={decNum}
                                                        onClick={() => handleQuantity(false)}
                                                        style={{

                                                            height: '2.5rem',
                                                            alignItems: 'center',
                                                            border: "none",
                                                            color: 'white'

                                                        }}>-</button>
                                                </div>
                                                <input type="text" className="form-control "
                                                    //  value={num} onChange={handleChange}
                                                    value={data.quantity}
                                                    //  disabled={true}
                                                    style={{

                                                        height: '2.5rem',
                                                        justifyContent: 'center',

                                                        border: "none",

                                                        border: '1px solid black'

                                                    }} />
                                                <div className="input-group-prepend">
                                                    <button className="btn btn-outline-dark " style={{

                                                        height: '2.5rem',
                                                        alignItems: 'center',
                                                        border: "none",
                                                        color: 'white'

                                                    }} type="button" onClick={() => handleQuantity(true)}>+</button>
                                                </div>
                                            </div>

                                        </div>
                                    }
                                </div> */}
                {item_data && (
                  <div className='descrption  ms-3 mt-4'>
                    {item_data?.product?.description}
                  </div>
                )}
                {item_data?.size_chart && (
                  <p
                    className='mt-1 ms-3 fw-bold'
                    style={{ color: "brown", cursor: "pointer" }}
                    onClick={() => setSizeChart(item_data)}>
                    Check Size Chart
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <button className="btn" onClick={handleSubmit} disabled={loading}>
                Add to Cart
            </button> */}
      <SizeChart product={sizeChart} setProduct={setSizeChart} />
    </div>
  );
}

export default ProductItem;
