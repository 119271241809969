import { configureStore } from '@reduxjs/toolkit'
import { schoolReducer } from './Reducers/schoolReducer';
import { LoadUser, LoginReducer } from './Reducers/User';

export const store = configureStore({
    reducer: {
        Login: LoginReducer,
        school: schoolReducer,
        LoadUser: LoadUser
    },
})

