import React from "react";
import { useState } from "react";
import productPlaceholder from "../../AllImage/productPlaceholder.jpg";

const CartItemsV2 = ({ item }) => {
  const [data, setData] = useState({
    price: item?.price,
    variant: item?.variant?.size?.size,
    quantity: item?.quantity,
    enrollment_number: item?.enrollment_number,
  });

  return (
    <>
      <div class='card productCarts mt-3 mb-3'>
        <div class='row g-0  '>
          <div class='col-md-3 '>
            <img
              src={
                item?.product?.images?.length > 0
                  ? item?.product?.images[0]?.url
                  : productPlaceholder
              }
              class=' responsive p-1'
              alt='...'
              width={100}
            />
          </div>

          <div class='col-md-8 '>
            <div class='card-body '>
              <div className='productName d-flex  '>
                <h5 class='w-75 d-flex justify-content-Start m-0 p-0'>
                  {item?.display_name || item?.product?.name}
                </h5>
              </div>
              <p className='m-0 p-0'>{item.product.description}</p>
              <p className='m-0 p-0'>{`Enrollment No. ${
                data?.enrollment_number || "-"
              }`}</p>
              <div className=' d-flex flex-wrap'>
                <div
                  className='d-flex justify-content-center me-2 mt-1'
                  style={{
                    background: "#F2E9E4",
                    width: "6rem",
                  }}>
                  <span className='d-flex justify-content-evenly align-items-center fs-6 ms-0'>
                    SIZE: <span className='ms-2 fw-bold'>{data.variant}</span>
                  </span>
                </div>
                <>
                  <h5 className='mt-2 ms-4'>Qty: {data.quantity}</h5>
                </>
              </div>
              <h5 className='mt-3'>₹{data.price}</h5>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CartItemsV2;
