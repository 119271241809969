import React, { useState } from "react";
import Hamburger from "../../Hamburger";
import SideNavbar from "../../SideNavbar";

import "./Child.css";
import axios from "axios";
import { isAutheticated, signout } from "../../Auth";
import ClipLoader from "react-spinners/ClipLoader";

import { useEffect } from "react";
import swal from "sweetalert";
import { Link, Navigate, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const Child = () => {
  const navigate = useNavigate();

  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const token = isAutheticated();
  const [loading, setLoading] = useState(false);
  const [totalChild, setTotalChild] = useState([]);
  const [childID, setchildID] = useState();
  const [refe, setRefe] = useState();
  const [childData, setChildData] = useState({});

  const [totalGrades, setTotalGrades] = useState([]);
  const [totalHouse, setTotalHouse] = useState([]);
  const [schoolName, setSchoolName] = useState();

  const [data, setData] = useState({
    Enroll: "",
    // name: '',
    // gender: '',
    // house: '',
    // grade: ''
  });

  const toggleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen);
  };
  useEffect(() => {
    const getData = async () => {
      const resp = await axios.get(
        `/api/client/school/gradesandhouses`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log(resp.data.school_name)
      setSchoolName(resp.data.school_name);
      setTotalGrades(resp.data.grades);
      setTotalHouse(resp.data.houses);
      // localStorage.setItem('schoolID', res.data.data._id)
    };

    const getChild = async () => {
      const resp = await axios.get(
        `/api/client/child`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(resp.data.data);
      setTotalChild(resp.data.data);
    };
    getData();
    getChild();
  }, [token, refe]);

  const handleChange = (e) => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSearch = (e) => {
    e.preventDefault();

    if (data.Enroll.trim() === "") {
      swal({
        title: "Warning",
        text: "Please Enter Enrollment Number",
        icon: "error",
        button: "Close",
        dangerMode: true,
      });
      return;
    }
    setLoading(true);
    axios
      .get(`/api/client/child/by_enrollment_no/${data.Enroll}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        setChildData(res.data.data);
        setchildID(res.data.data._id);
        // const msg = res.data.message ? res.data.message : 'Get Data successfully!';
        // swal({
        //     title: 'success',
        //     text: msg,
        //     icon: 'success',
        //     button: 'Ok',
        // })
        setLoading(false);

        // navigate('/dashboard', { replace: true })
        // navigate(-1)
      })
      .catch((err) => {
        setLoading(false);

        const msg = err.response.data.message
          ? err.response.data.message
          : "Something went wrong!";
        swal({
          title: "Warning",
          text: msg,
          icon: "error",
          button: "Retry",
          dangerMode: true,
        });
        setChildData(null);
      });
  };
  const AddChild = (e) => {
    // console.log(id)
    // e.preventDefault()

    if (childID === "") {
      swal({
        title: "Warning",
        text: "Please Enter Child Id ",
        icon: "error",
        button: "Close",
        dangerMode: true,
      });
      return;
    }
    setLoading(true);
    axios
      .post(
        `/api/client/child/link_child`,
        { child_id: childID },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setRefe((prev) => !prev);

        const msg = res.data.message
          ? res.data.message
          : "Add Child successfully!";
        // swal({
        //     title: 'success',
        //     text: msg,
        //     icon: 'success',
        //     button: 'Ok',
        // })
        setLoading(false);
        toast.success(msg);
        setChildData(null);
      })
      .catch((err) => {
        setLoading(false);

        const msg = err.response.data.message
          ? err.response.data.message
          : "Something went wrong!";
        swal({
          title: "Warning",
          text: msg,
          icon: "error",
          button: "Retry",
          dangerMode: true,
        });
      });
  };
  function UnlinkChild(ids) {
    // e.preventDefault()

    console.log(ids);
    if (ids === "") {
      swal({
        title: "Warning",
        text: "Please Enter Child Id ",
        icon: "error",
        button: "Close",
        dangerMode: true,
      });
      return;
    }
    //  setLoading(true)
    axios
      .delete(
        `/api/client/child/unlink_child/${ids}`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        // console.log('removed.....')
        setRefe((prev) => !prev);

        const msg = res.data.message
          ? res.data.message
          : "Unlink Child successfully!";
        toast.success(msg);
        // setLoading(false)
      })
      .catch((err) => {
        // setLoading(false)
        console.log(err.response.data.message);
        const msg = err.response.data.message
          ? err.response.data.message
          : "Something went wrong!";
        toast.error(msg);
      });
  }

  // console.log(childID ? childID : '')

  return (
    <div>
      <>
        <div className='' style={{ minHeight: "100vh" }}>
          <div className='Productmenu  '>
            <div className='submain mt-4'>
              <Link
                to='/dashboard'
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "1.5rem",
                }}>
                Dashboard
              </Link>
              <p className=' mt-3 '>
                {" "}
                <Link
                  to='/select_child'
                  style={{ textDecoration: "none", color: "black" }}>
                  Products
                </Link>
              </p>
              <p>
                <Link
                  to='/order/history'
                  style={{ textDecoration: "none", color: "black" }}>
                  Order History
                </Link>
              </p>
              <p>
                <Link
                  to='/child/add'
                  style={{
                    textDecoration: "none",
                    color: "black",
                    fontSize: "1.1rem",
                    fontWeight: "600",
                    color: "blue",
                  }}>
                  Children
                </Link>
              </p>
              <p>
                <Link
                  to='/support_system'
                  style={{ textDecoration: "none", color: "black" }}>
                  Support
                </Link>
              </p>
              {/* <p><Link to='/wishlist/me' style={{ textDecoration: 'none', color: 'black', }}>Wishlist</Link></p> */}

              <p className=' mt-3 '>
                {" "}
                <Link
                  to='/profile'
                  style={{ textDecoration: "none", color: "black" }}>
                  My Profile
                </Link>
              </p>
              <p onClick={() => signout()}>
                <Link style={{ textDecoration: "none", color: "black" }}>
                  logOut
                </Link>
              </p>
            </div>
          </div>
          {/* ----------------- */}
          <div className='hamburger' onClick={toggleHamburger}>
            <Hamburger isOpen={hamburgerOpen} />
          </div>
          {/* --------------- */}

          <SideNavbar hamburgerOpen={hamburgerOpen} />

          <div className='contentDivss'>
            <h1
              className='d-flex justify-content-center pt-5'
              style={{
                marginBottom: "2%",
              }}>
              Add Child
            </h1>

            <div className='d-flex flex-wrap mb-4'>
              <div className=' productOrder  mt-4 mainBords'>
                {schoolName && (
                  <h2 className='d-flex justify-content-center mt-3'>
                    {schoolName}
                  </h2>
                )}

                {/* -------------------- */}
                <div class='  mt-4 mb-3 '>
                  <div className='mb-3 mx-5 '>
                    <h6 htmlFor='name' className='form-label font-weight-bold'>
                      Enrollment Number / Admission Number :
                    </h6>
                    <div className='d-flex mt-1 '>
                      <input
                        style={{ height: "46px", width: "70%" }}
                        type='text'
                        maxLength={20}
                        id='Enroll'
                        value={data.Enroll}
                        className='form-control'
                        name='Enroll'
                        placeholder='The maximum number of characters should be 20'
                        onChange={handleChange}
                        required
                      />
                      <button
                        disabled={data.Enroll.length < 5}
                        style={{ height: "46px", width: "25%" }}
                        className='btn btn-dark  ms-3'
                        onClick={handleSearch}>
                        <ClipLoader
                          loading={loading}
                          color={"white"}
                          size={25}
                        />
                        {!loading && "Search"}
                      </button>
                    </div>

                    {data.Enroll ? (
                      <>
                        <small className='charLeft mt-4 fst-italic'>
                          {20 - data.Enroll.length} characters left
                        </small>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>

                {/* <div className="mb-1 ms-4">
                                    <h6 htmlFor="name" className="ms-1">House :</h6>


                                    <select className='' name='house' style={{ width: '15rem', height: '2.5rem' }}
                                        onChange={handleChange}>
                                        <option value="1">--select--</option>
                                        {totalHouse && totalHouse.map((item, index) =>
                                            <option key={index} value={item?._id} >{item?.name}</option>

                                        )}
                                        
                                    </select>

                                </div> */}

                {/* <div className="mb-3 d-grid">
                                    <button disabled={data.Enroll.length < 3} type="submit" className="btn btn-dark mt-4 mx-5" onClick={handleSubmit} >

                                        <ClipLoader loading={loading} color={'white'} size={25} />
                                        {!loading && "Search"}


                                    </button>



                                </div> */}

                {childData?.name && (
                  <div className='container'>
                    <hr className='mt-4' />
                    <h4 className='text-center'>Student Data</h4>

                    <div className='mb-3 ms-5  d-flex flex-wrap'>
                      <small
                        htmlFor='name'
                        className='form-label font-weight-bold'>
                        Enrollment Number :
                      </small>
                      <small
                        htmlFor='name'
                        className='form-label font-weight-bold ms-3'>
                        {childData?.enrollment_number}
                      </small>
                    </div>
                    <div className='mb-3 ms-5  d-flex flex-wrap'>
                      <small
                        htmlFor='name'
                        className='form-label font-weight-bold'>
                        Child Name :
                      </small>
                      <h6
                        htmlFor='name'
                        className='form-label font-weight-bold ms-5'>
                        {childData?.name}
                      </h6>
                    </div>
                    <div className='mb-3 ms-5  d-flex flex-wrap'>
                      <small
                        htmlFor='name'
                        className='form-label font-weight-bold'>
                        Child Grade :
                      </small>
                      <h6
                        htmlFor='name'
                        className='form-label font-weight-bold ms-5'>
                        {childData?.grade?.name}
                      </h6>
                    </div>
                    <div className='mb-3 ms-5  d-flex flex-wrap'>
                      <small
                        htmlFor='name'
                        className='form-label font-weight-bold'>
                        Child Section :
                      </small>
                      <h6
                        htmlFor='name'
                        className='form-label font-weight-bold ms-5'>
                        {childData?.section?.name}
                      </h6>
                    </div>
                    <div className='mb-3 ms-5  d-flex flex-wrap'>
                      <small
                        htmlFor='name'
                        className='form-label font-weight-bold'>
                        Child Gender :
                      </small>
                      <h6
                        htmlFor='name'
                        className='form-label font-weight-bold ms-5'>
                        {childData?.gender}
                      </h6>
                    </div>
                    <div className='mb-3 ms-5  d-flex flex-wrap'>
                      <small
                        htmlFor='name'
                        className='form-label font-weight-bold'>
                        Child House :
                      </small>
                      <h6
                        htmlFor='name'
                        className='form-label font-weight-bold ms-5'>
                        {childData?.house?.name}
                      </h6>
                    </div>

                    <div className='mb-3 d-grid'>
                      <button
                        className='btn btn-dark mt-4 mx-5'
                        onClick={() => AddChild()}>
                        <ClipLoader
                          loading={loading}
                          color={"white"}
                          size={25}
                        />
                        {!loading && "Add"}
                      </button>
                    </div>
                  </div>
                )}
              </div>
              {/* <div class=" mb-3 p-2 d-flex" >
                                <div className="mb-1 ms-2">
                                    <h6 htmlFor="name" className="ms-1">Grades :</h6>


                                    <select className='ms-2' name='grade' style={{ width: '5rem', height: '2.5rem', margin: 'auto', }}
                                        onChange={handleChange}

                                    >
                                        <option value="1">--select--</option>
                                        {totalGrades && totalGrades.map((item, index) =>
                                            <option key={index} value={item?._id} >{item?.name}</option>

                                        )}

                                    </select>

                                </div>


                                <div className="mb-1 ms-4 flex-wrap">
                                    <h6 htmlFor="name" className="ms-1">Gender :</h6>


                                    <select className='' name='gender' style={{ height: '2.5rem' }}
                                        onChange={handleChange}>
                                        <option value="1">--select--</option>

                                        <option value='boy' >Boy</option>
                                        <option value='girl'> Girl</option>

                                    </select>

                                </div>



                            </div> */}
              <div class='card  mt-4  allchilddiv'>
                <div class='card-header  head'>
                  <h5 className='text-center'>All Children</h5>
                </div>
                <div class='card-body text overflow-auto'>
                  <ol>
                    {totalChild && totalChild.length > 0 ? (
                      totalChild.map((item, index) => (
                        <li
                          className='d-flex mb-1'
                          key={index}
                          value={index + 1}>
                          <div className='d-flex flex-column'>
                            <span className='p-0 m-0'>{item.name}</span>
                            <span
                              className='p-0 m-0'
                              style={{
                                fontStyle: "italic",
                                fontSize: "12px",
                              }}>{`${item?.grade?.name} - ${item?.house?.name}`}</span>
                          </div>
                          <Link
                            className=' ms-2 d-flex '
                            //  to={`/child/edit/${item._id}`}
                            style={{ textDecoration: "none" }}>
                            {/* <i class='bi bi-pencil-square'></i> */}
                            {/* {console.log(item._id)} */}
                            <span
                              onClick={() => UnlinkChild(item._id)}
                              class='material-symbols-outlined'>
                              close
                            </span>
                            {/* <img src="https://icons8.com/icon/4XN42ErT9YNV/clear-symbol" alt="" srcset="" /> */}
                          </Link>
                        </li>
                      ))
                    ) : (
                      <div className='ms-2'>No children till Now..</div>
                    )}
                  </ol>
                  {/* <h5 class="card-title">Special title treatment</h5>
                                    <p class="card-text">With supporting text below as a natural lead-in to additional content.</p> */}
                  {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default Child;
