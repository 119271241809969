import React, { useState, useEffect } from "react";
import axios from "axios";
// import { API } from 'src/API'
import Hamburger from "../../Hamburger";
import SideNavbar from "../../SideNavbar";
import { Link, useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { isAutheticated, signout } from "../../Auth";
import "./ViewTicket.css";

const ViewTicket = () => {
  const [hamburgerOpen, setHamburgerOpen] = useState(false);

  const toggleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen);
  };
  const id = useParams()?.id;
  const token = isAutheticated();
  // console.log(token)
  const [ticketData, setTicketData] = useState({});
  const [reply, setReply] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const getMeasurementData = async () => {
    axios
      .get(`/api/client/supportticket/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setTicketData(res.data.data);
      })
      .catch((err) => {
        onCancel();
      });
  };

  useEffect(() => {
    getMeasurementData();
  }, []);

  const handleCloseTicket = () => {
    swal({
      title: `Close Support Ticket ${ticketData.ticket_number} ?`,
      icon: "error",
      buttons: {
        Yes: { text: "Yes", value: true },
        Cancel: { text: "Cancel", value: "cancel" },
      },
    }).then((value) => {
      if (value === true) {
        setLoading(true);
        axios
          .delete(
            `/api/client/supportticket/${id}`,

            {
              headers: {
                "Access-Control-Allow-Origin": "*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            onCancel();
          })
          .catch((err) => {
            swal({
              title: "Warning",
              text: "Something went wrong!",
              icon: "error",
              button: "Retry",
              dangerMode: true,
            });
          })
          .finally(() => setLoading(false));
      }
    });
  };

  const handleReplySubmit = () => {
    if (reply.trim() === "") return;
    setLoading(true);
    axios
      .post(
        `/api/client/supportticket/reply/${id}`,
        { reply },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setTicketData(res.data.data);
        setReply("");
      })
      .catch((err) => {
        swal({
          title: "Warning",
          text: "Something went wrong!",
          icon: "error",
          button: "Retry",
          dangerMode: true,
        });
      })
      .finally(() => setLoading(false));
  };

  const onCancel = () => {
    navigate(-1);
  };
  var input = document.getElementById("myInput");
  if (input) {
    input.addEventListener("keypress", function (event) {
      if (event.key === "Enter") {
        event.preventDefault();
        document.getElementById("myBtn").click();
      }
    });
  }

  return (
    <div className='' style={{ minHeight: "100vh" }}>
      <div className='Productmenu  '>
        <div className='submain mt-4 ms-2'>
          <Link
            to='/dashboard'
            style={{
              textDecoration: "none",
              color: "black",
              fontSize: "1.6rem",
            }}>
            Dashboard
          </Link>

          <p className=' mt-3 '>
            {" "}
            <Link
              to='/select_child'
              style={{ textDecoration: "none", color: "black" }}>
              Products
            </Link>
          </p>
          <p>
            <Link
              to='/order/history'
              style={{ textDecoration: "none", color: "black" }}>
              Order History
            </Link>
          </p>
          <p>
            <Link
              to='/child/add'
              style={{ textDecoration: "none", color: "black" }}>
              Children
            </Link>
          </p>

          <p>
            <Link
              to='/support_system'
              style={{
                textDecoration: "none",
                color: "black",
                fontSize: "1.1rem",
                color: "blue",
              }}>
              Support
            </Link>
          </p>

          {/* <p><Link to='/wishlist/me' style={{ textDecoration: 'none', color: 'black', }}>Wishlist</Link></p> */}
          <p className=' mt-3 '>
            {" "}
            <Link
              to='/profile'
              style={{ textDecoration: "none", color: "black" }}>
              My Profile
            </Link>
          </p>

          <p onClick={() => signout()}>
            <Link style={{ textDecoration: "none", color: "black" }}>
              Logout
            </Link>
          </p>
        </div>
      </div>
      {/* ----------------- */}
      <div className='hamburger' onClick={toggleHamburger}>
        <Hamburger isOpen={hamburgerOpen} />
      </div>
      {/* --------------- */}

      <SideNavbar hamburgerOpen={hamburgerOpen} />
      <div className=' main-content mainbox'>
        <div className='  py-3 page-content'>
          <div className='container-fluid'>
            {/* <!-- start page title --> */}
            <div className='row'>
              <div className='col-12'>
                <div className='page-title-box d-flex align-items-center justify-content-between'>
                  <h3 className='mb-3'>Support Ticket Details</h3>

                  <div>
                    <button
                      onClick={handleCloseTicket}
                      disabled={ticketData?.status === "closed" || loading}
                      type='button'
                      className='mb-2 ml-2 btn btn-info btn-cancel text-white ms-3'>
                      Close Ticket
                    </button>
                    <button
                      onClick={onCancel}
                      type='button'
                      className='mb-2 ml-2 btn btn-warning btn-cancel waves-effect waves-light ms-3'>
                      Back
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- end page title --> */}

            <div className='row'>
              <div className='col-lg-12'>
                <div className='card'>
                  <div className='card-body'>
                    <div className='row ml-0 mr-0  mb-10'></div>
                    <div className='table-responsive table-shoot'>
                      <table className='table table-centered table-nowrap mb-0'>
                        <thead className='thead-light'>
                          <tr>
                            <th>Ticket Number</th>
                            <td>{ticketData?.ticket_number}</td>
                          </tr>
                          <tr>
                            <th>Created On</th>
                            <td>
                              {new Date(ticketData?.createdAt).toLocaleString(
                                "en-IN",
                                {
                                  weekday: "short",
                                  month: "short",
                                  day: "numeric",
                                  year: "numeric",
                                  hour: "numeric",
                                  minute: "numeric",
                                  hour12: true,
                                }
                              )}
                            </td>
                          </tr>
                          <tr>
                            <th>Parent Name</th>
                            <td>{ticketData?.parent?.name}</td>
                          </tr>
                          <tr>
                            <th>Parent Email</th>
                            <td>{ticketData?.parent?.email}</td>
                          </tr>
                          <tr>
                            <th>Child</th>
                            <td>{ticketData?.student?.name || "-"}</td>
                          </tr>
                          <tr>
                            <th>Order ID</th>
                            <td>{ticketData?.order?.order_id || "-"}</td>
                          </tr>
                          <tr>
                            <th>Subject</th>
                            <td>{ticketData?.subject}</td>
                          </tr>
                          <tr>
                            <th>Status</th>
                            <td>
                              {ticketData?.status === "open" ? (
                                <span className='badge rounded-pill bg-success '>
                                  {ticketData?.status}
                                </span>
                              ) : (
                                <span className='badge rounded-pill bg-danger'>
                                  {ticketData?.status}
                                </span>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <th>Image</th>
                            <td>
                              {ticketData?.image_url ? (
                                <img
                                  src={ticketData?.image_url}
                                  alt={ticketData?.subject}
                                  height='70px'
                                />
                              ) : (
                                <span className='fw-bold'>
                                  No image uploaded!
                                </span>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <th>Message</th>
                            <td>{ticketData?.message}</td>
                          </tr>
                        </thead>
                        <tbody></tbody>
                      </table>
                      <div className='mx-2'>
                        <label className='fw-bold'>Replies :</label>
                        <div
                          data-bs-spy='scroll'
                          data-bs-offset='-1'
                          tabindex='0'
                          className='w-100 overflow-auto '
                          style={{
                            minHeight: "70px",
                            backgroundColor: "#F2E9E4",
                            borderRadius: "8px",
                            maxHeight: "40rem",
                            display: "flex",
                            flex: "1",
                            flexDirection: "column",
                          }}>
                          {ticketData?.replies?.length > 0 ? (
                            ticketData?.replies?.map((m, i) =>
                              m.from === "parent" ? (
                                <div
                                  key={i}
                                  className='mx-2 my-1 d-flex justify-content-end align-items-center'>
                                  <div
                                    className='bg-white p-2 my-1'
                                    style={{
                                      maxWidth: "500px",
                                      borderRadius: "8px",
                                    }}>
                                    <div> {m.message}</div>
                                    <div
                                      className='text-end'
                                      style={{ fontSize: "10px" }}>
                                      {new Date(m?.repliedAt).toLocaleString(
                                        "en-IN",
                                        {
                                          weekday: "short",
                                          month: "short",
                                          day: "numeric",
                                          year: "numeric",
                                          hour: "numeric",
                                          minute: "numeric",
                                          hour12: true,
                                        }
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    className='bg-info text-center text-white my-1 ms-1'
                                    style={{
                                      height: "50px",
                                      width: "50px",
                                      borderRadius: "25px",
                                      paddingTop: "13px",
                                    }}>
                                    You
                                  </div>
                                </div>
                              ) : (
                                <div
                                  key={i}
                                  className='mx-2 my-1 d-flex justify-content-start align-items-center'>
                                  <div
                                    className='bg-success text-center text-white my-1 me-1'
                                    style={{
                                      height: "50px",
                                      width: "50px",
                                      borderRadius: "25px",
                                      paddingTop: "15px",
                                      fontSize: "10px",
                                    }}>
                                    Reinvent
                                  </div>
                                  <div
                                    className='bg-white p-2 my-1'
                                    style={{
                                      maxWidth: "500px",
                                      borderRadius: "8px",
                                    }}>
                                    <div className='  d-flex flex-wrap'>
                                      {" "}
                                      {m.message}
                                    </div>
                                    <div
                                      className='text-end'
                                      style={{ fontSize: "10px" }}>
                                      {new Date(m?.repliedAt).toLocaleString(
                                        "en-IN",
                                        {
                                          weekday: "short",
                                          month: "short",
                                          day: "numeric",
                                          year: "numeric",
                                          hour: "numeric",
                                          minute: "numeric",
                                          hour12: true,
                                        }
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )
                            )
                          ) : (
                            <div className='d-flex justify-content-center align-items-center'>
                              <div
                                className='bg-white p-2 mt-3 text-center fw-bold'
                                style={{
                                  maxWidth: "500px",
                                  width: "300px",
                                  borderRadius: "8px",
                                }}>
                                No Replies
                              </div>
                            </div>
                          )}
                        </div>
                        {ticketData.status === "open" && (
                          <div
                            className='row my-2'
                            style={{ maxWidth: "100%" }}>
                            <div className='col-10'>
                              <input
                                type='text'
                                className='form-control'
                                value={reply}
                                id='myInput'
                                onChange={(e) => setReply(e.target.value)}
                                placeholder='Your Reply...'
                              />
                            </div>
                            <div className='col-2 text-center d-grid'>
                              <button
                                id='myBtn'
                                className='btn btn-primary text-white'
                                onClick={handleReplySubmit}
                                disabled={loading}>
                                Reply
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* <!-- end table-responsive --> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- container-fluid --> */}
        </div>
      </div>
    </div>
  );
};

export default ViewTicket;
